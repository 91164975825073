/**
 * Module dependencies.
 */

import { Button } from 'src/components/core';
import { ReactElement } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { ifProp } from 'styled-tools';
import { indexes } from 'src/core/search-config';
import { units } from '@untile/react-components';
import DownloadSearchCard from 'src/components/search/search-results/cards/download-card';
import ModelRangeSearchCard from 'src/components/search/search-results/cards/model-range-card';
import NewsSearchCard from 'src/components/search/search-results/cards/news-card';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  hasMore: boolean;
  hits: any[];
  index: string;
  refineNext: () => void;
};

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li<{ isModelRange: boolean }>`
  &:not(:last-child) {
    margin-bottom: ${ifProp('isModelRange', units(1), units(2))};
  }
`;

/**
 * `LoadMoreWrapper` styled component.
 */

const LoadMoreWrapper = styled.div`
  padding-top: ${units(3.5)};
`;

/**
 * `InfiniteHits` component.
 */

const InfiniteHits = (props: Props): ReactElement => {
  const { hasMore, hits, index, refineNext } = props;
  const { translate } = useTranslate();

  return (
    <>
      <ol>
        {hits.map(hit => (
          <ListItem
            isModelRange={index === indexes.modelRanges}
            key={hit.objectID}
          >
            {index === indexes.modelRanges && <ModelRangeSearchCard {...hit} />}

            {index === indexes.news && <NewsSearchCard {...hit} />}

            {index === indexes.downloads && <DownloadSearchCard {...hit} />}
          </ListItem>
        ))}
      </ol>

      {hasMore && (
        <LoadMoreWrapper>
          <Button
            colorTheme={'tertiary'}
            onClick={refineNext}
            size={'small'}
          >
            {translate('common:actions.loadMore')}
          </Button>
        </LoadMoreWrapper>
      )}
    </>
  );
};

/**
 * Export `InfiniteHits` component.
 */

export default connectInfiniteHits(InfiniteHits);
