/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { Type } from '../typography';
import { color, units } from '@untile/react-components';
import { icons } from '../icons';
import { theme } from 'styled-tools';
import { useRouter } from 'next/router';
import RouterLink from '../links/router-link';
import Svg from '../svg';
import styled from 'styled-components';

/**
 * Export `NavigationProps` interface.
 */

export interface NavigationProps {
  className?: string;
  href?: string;
  label: string;
  onClick?: () => void;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs<NavigationProps>(({ href }) => {
  const element = (href && RouterLink) || 'button';

  return {
    as: element,
    type: element === 'button' ? 'button' : null
  };
})`
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  color: ${color('grey800')};
  cursor: pointer;
  display: flex;
  outline: none;
  padding: 0;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`;

/**
 * `StyledSvg` styled component.
 */

const StyledSvg = styled(Svg)`
  color: ${color('black')};
  transition: color ${theme('animations.defaultTransition')};
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Small)`
  letter-spacing: 1.2px;
  margin-left: ${units(2)};
  text-transform: uppercase;
`;

/**
 * `Navigation` component.
 */

const Navigation = (props: NavigationProps): ReactElement => {
  const { className, href, label, onClick } = props;
  const { locale } = useRouter();

  return (
    <Wrapper
      className={className}
      {...(href ? { href, locale } : { onClick })}
    >
      <StyledSvg
        icon={icons.arrowLeftLarge}
        size={units(6)}
      />

      <Label>{label}</Label>
    </Wrapper>
  );
};

/**
 * Export `Navigation` component.
 */

export default Navigation;
