/**
 * Module dependencies.
 */

import { ElementType, FC, ReactElement, ReactNode, forwardRef } from 'react';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, prop, theme } from 'styled-tools';
import { isExternalUrl } from '@untile/react-components/dist/utils';
import Loading from '../loading';
import RouterLink from '../links/router-link';
import Svg from '../svg';
import styled, { css } from 'styled-components';

/**
 * Export `IconButtonProps` interface.
 */

export interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  as?: ElementType;
  className?: string;
  disabled?: boolean;
  href?: string;
  icon: string | ReactNode;
  iconSize: any;
  isLoading?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  type?: string;
}

/**
 * `Button` styled component.
 */

const Button = styled.button.attrs<IconButtonProps>(({ as, href, type }) => {
  const isExternal = isExternalUrl(href);
  const element =
    as ||
    (href && !isExternal && RouterLink) ||
    (href && isExternal && 'a') ||
    'button';

  return {
    as: element,
    type: type || (element === 'button' ? 'button' : null)
  };
})<{ size: string }>`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  color: ${color('grey800')};
  cursor: pointer;
  height: ${prop('size')};
  outline: none;
  padding: 0;
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;
  width: ${prop('size')};

  &::before {
    background-color: ${color.transparentize('grey400', 0.1)};
    border-radius: 50%;
    content: '';
    height: ${prop('size')};
    left: 50%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center;
    transition: ${theme('animations.defaultTransition')};
    transition-property: background-color, transform;
    width: ${prop('size')};
  }

  &:hover {
    color: ${color('grey600')};

    &::before {
      transform: translate(-50%, -50%) scale(1.35);
    }
  }

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      opacity: 0.6;
      pointer-events: none;
    `
  )}
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading).attrs({
  relative: true,
  size: 16
})`
  margin: ${units(0.5)};
`;

/**
 * `IconButton` component.
 */

const IconButton: FC<IconButtonProps> = forwardRef<any, IconButtonProps>(
  (props: IconButtonProps, ref: any): ReactElement => {
    const { disabled, icon, iconSize, isLoading, ...rest } = props;

    return (
      <Button
        {...rest}
        disabled={disabled || isLoading}
        ref={ref}
        size={iconSize}
      >
        {isLoading ? (
          <StyledLoading active />
        ) : (
          <Svg
            icon={icon}
            size={iconSize}
          />
        )}
      </Button>
    );
  }
);

/**
 * `IconButton` display name.
 */

IconButton.displayName = 'IconButton';

/**
 * Export `IconButton` component.
 */

export default IconButton;
