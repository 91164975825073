/**
 * Module dependencies.
 */

/**
 * `SvgFilter` component.
 */

const SvgFilter: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M13.985 17.886h-4.58a1.397 1.397 0 0 1-.985-.438 1.431 1.431 0 0 1 .043-2.01c.27-.261.63-.403 1.003-.395h4.58a5.321 5.321 0 0 1 1.86-2.912A5.212 5.212 0 0 1 19.145 11c1.174 0 2.314.398 3.239 1.131a5.323 5.323 0 0 1 1.86 2.912h14.382c.372 0 .73.15.993.417a1.43 1.43 0 0 1 0 2.01c-.264.267-.62.416-.993.416H24.183a5.322 5.322 0 0 1-1.86 2.912 5.212 5.212 0 0 1-3.24 1.131 5.212 5.212 0 0 1-3.238-1.13 5.322 5.322 0 0 1-1.86-2.913Zm6.928-3.343a2.535 2.535 0 0 0-1.768-.767c-.666.012-1.3.287-1.768.767a2.596 2.596 0 0 0-.736 1.798 2.624 2.624 0 0 0 .691 1.884 2.565 2.565 0 0 0 1.813.82 2.54 2.54 0 0 0 1.813-.82 2.6 2.6 0 0 0 .69-1.884 2.596 2.596 0 0 0-.735-1.799Zm13.169 15.53h4.515c.372 0 .729.15.992.416a1.429 1.429 0 0 1 0 2.008c-.263.267-.62.416-.992.416H34.02a5.317 5.317 0 0 1-1.859 2.91 5.207 5.207 0 0 1-3.235 1.13 5.207 5.207 0 0 1-3.236-1.13 5.317 5.317 0 0 1-1.859-2.91H9.403c-.372 0-.729-.15-.992-.416a1.43 1.43 0 0 1 0-2.008c.263-.267.62-.416.992-.416h14.49a5.317 5.317 0 0 1 1.859-2.91 5.208 5.208 0 0 1 3.236-1.13c1.173 0 2.312.398 3.235 1.13a5.317 5.317 0 0 1 1.859 2.91Zm-3.69-.808a2.569 2.569 0 0 0-1.465-.396 2.522 2.522 0 0 0-1.788.787 2.584 2.584 0 0 0-.714 1.837c0 .523.155 1.034.444 1.467.29.434.7.77 1.18.965a2.564 2.564 0 0 0 2.835-.612 2.653 2.653 0 0 0 .502-2.89 2.616 2.616 0 0 0-.995-1.158Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgFilter` component.
 */

export default SvgFilter;
