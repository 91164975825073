/**
 * Module dependencies.
 */

import { Fill, useBodyScroll } from '@untile/react-components';
import { Loading } from 'src/components/core';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { theme as amtrolAlfaTheme } from 'src/styles/theme';
import {
  colorsCombinations,
  usePageTransitionConfig
} from 'src/core/content-config/page-transition';

import { ifProp, prop, switchProp, theme } from 'styled-tools';
import { normalizeRouteUrl } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import find from 'lodash/find';
import styled, { css } from 'styled-components';

/**
 * `Animation` type.
 */

type Animation = 'start' | 'end';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  overflow: hidden;
  position: relative;
  transition: opacity ${theme('animations.easeOutQuadTransition')};

  ${ifProp(
    'visible',
    css`
      opacity: 1;
      transition-delay: 0s;
    `,
    css`
      opacity: 0;
      transition-delay: 0.6s;
    `
  )}
`;

/**
 * `AnimatedBackground` styled component.
 */

const AnimatedBackground = styled(Fill)<{
  animation: Animation;
  bgColor: string;
  delay?: number;
}>`
  align-items: center;
  animation: ${theme('animations.easeOutQuadTransition')};
  animation-duration: 0.6s;
  animation-fill-mode: both;
  background-color: ${prop('bgColor')};
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  transform: scaleY(0);
  z-index: ${theme('zIndex.pageTransition')};

  ${switchProp('animation', {
    end: css`
      animation-delay: 0s;
      animation-name: ${theme('keyframes.pageAnimationEnd')};
      pointer-events: none;
      transform-origin: center bottom;
    `,
    start: css`
      animation-delay: ${prop('delay', 0)}ms;
      animation-name: ${theme('keyframes.pageAnimationStart')};
      pointer-events: all;
      transform-origin: center top;
    `
  })}
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading).attrs({
  relative: true,
  size: 48
})`
  z-index: 1000;
`;

/**
 * `PageTransition` component.
 */

const PageTransition = ({ children }: Props): ReactElement => {
  const router = useRouter();
  const [animation, setAnimation] = useState<Animation>();
  const pageTransitionConfig = usePageTransitionConfig();
  const [url, setUrl] = useState<string>();
  const { auxiliarBgColor, bgColor } = useMemo(() => {
    const pageConfig = find(pageTransitionConfig, ['route', url]);

    if (!pageConfig) {
      return {
        auxiliarBgColor: amtrolAlfaTheme.colors.grey100,
        bgColor: amtrolAlfaTheme.colors.grey50
      };
    }

    return {
      auxiliarBgColor: colorsCombinations[pageConfig.bgColor],
      bgColor: pageConfig.bgColor
    };
  }, [pageTransitionConfig, url]);

  useEffect(() => {
    setAnimation('end');
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = (url, { shallow }) => {
      if (shallow) {
        return;
      }

      setUrl(normalizeRouteUrl(url));
      setAnimation('start');
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events, router.pathname]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setAnimation('end');
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.on('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events]);

  useBodyScroll({ off: animation === 'start' });

  return (
    <>
      <AnimatedBackground
        animation={animation}
        bgColor={bgColor}
      >
        <AnimatedBackground
          animation={animation}
          bgColor={auxiliarBgColor}
          delay={250}
        />

        <StyledLoading active={animation === 'start'} />
      </AnimatedBackground>

      <Wrapper visible={animation === 'end'}>{children}</Wrapper>
    </>
  );
};

/**
 * Export `PageTransition` component.
 */

export default PageTransition;
