/**
 * Module dependencies.
 */

import {
  Fill,
  color,
  isExternalUrl,
  media,
  states,
  units,
  useBodyScroll,
  useBreakpoint
} from '@untile/react-components';

import { IconButton, RouterLink, Svg, icons, logos } from 'src/components/core';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { authRoutes, myProductsRoutes } from 'src/core/routes';
import { ifProp, theme } from 'styled-tools';
import { useAuth } from 'src/context/auth/context';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useNavbarLinks } from 'src/core/content-config/navbar';
import { useRouter } from 'next/router';
import Container from 'src/components/core/layout/container';
import HamburgerMenu from './hamburger-menu';
import MyProductsDropdown from './my-products-dropdown';
import MyProductsMobileMenu from './my-products-mobile-menu';
import Search from 'src/components/search';
import Sidebar from './sidebar';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  hasDoubleLogo: boolean;
};

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  align-items: center;
  height: ${theme('dimensions.navbarHeightMobile')}px;
  left: 0;
  padding: ${units(1)} 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${theme('zIndex.navbar')};

  ${media.min('md')`
    height: ${theme('dimensions.navbarHeight')}px;
    padding: ${units(2)} 0;
  `}
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled(Fill)<{ isVisible?: boolean }>`
  background-color: transparent;
  transform: translateY(-100%);
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, transform;
  z-index: 2;

  ${ifProp(
    'isVisible',
    css`
      background-color: ${color('white')};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      transform: translateY(0);
    `
  )}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container).attrs({ fluid: true })`
  padding: 0 ${units(2.5)};
  z-index: 3;

  ${media.min('md')`
    padding: 0 ${units(5)};
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  height: ${units(6)};
  transition: opacity ${theme('animations.defaultTransition')};

  ${media.max('sm')`
    position: relative;
  `}

  &:focus,
  &:hover {
    opacity: 0.7;
    transition-delay: 0s;
  }
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  align-items: center;
  display: flex;

  > * {
    &:not(:last-child) {
      margin-right: ${units(3)};
    }

    ${media.min('ms')`
      &:not(:last-child) {
        margin-right: ${units(4)};
      }
    `}
  }
`;

/**
 * `NavbarLink` styled component.
 */

const NavbarLink = styled.a.attrs(({ href }) => ({
  as: (href && !isExternalUrl(href) && RouterLink) || 'a'
}))<{ visible?: boolean }>`
  color: ${color('textColor')};
  cursor: ${ifProp('visible', 'pointer', 'none')};
  font-size: 16px;
  line-height: 24px;
  opacity: ${ifProp('visible', 1, 0)};
  pointer-events: ${ifProp('visible', 'auto', 'none')};
  text-decoration: none;
  transition: opacity 0.3s ease;

  ${states.action`
    outline: none;
  `}

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)<{ visible?: boolean }>`
  cursor: ${ifProp('visible', 'pointer', 'none')};
  line-height: 0;
  opacity: ${ifProp('visible', 1, 0)};
  pointer-events: ${ifProp('visible', 'auto', 'none')};
  transition: opacity 0.3s ease;
`;

/**
 * `StyledSidebar` styled component.
 */

const StyledSidebar = styled(Sidebar)`
  z-index: 1;
`;

/**
 * `Navbar` component.
 */

const Navbar = ({ hasDoubleLogo }: Props): ReactElement => {
  const { isAuthenticated } = useAuth();
  const { locale, translate } = useTranslate();
  const isMobile = useBreakpoint('sm', 'max');
  const isTablet = useBreakpoint('ms', 'max');
  const isLargeTablet = useBreakpoint('lg', 'max');
  const locomotiveScroll = useLocomotiveScroll();
  const navbarLinks = useNavbarLinks();
  const routeResolver = useLocalizedRoute();
  const router = useRouter();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>();
  const [isSearchOpen, setSearchOpen] = useState<boolean>();
  const [isMyProductsMenuOpen, setIsMyProductsMenuOpen] = useState<boolean>();
  const hideActions = isSearchOpen || isSidebarOpen;
  const { logo, logoSize } = useMemo(() => {
    if (isLargeTablet) {
      return {
        logo: hasDoubleLogo
          ? logos.doubleBrandLogoMobile
          : logos.amtrolAlfaLogoMobile,
        logoSize: hasDoubleLogo ? '134px' : '48px'
      };
    }

    return {
      logo: hasDoubleLogo ? logos.doubleBrandLogo : logos.amtrolAlfaLogo,
      logoSize: hasDoubleLogo ? '509px' : '240px'
    };
  }, [hasDoubleLogo, isLargeTablet]);

  const toggle = useCallback(() => {
    setSidebarOpen(isSidebarOpen => !isSidebarOpen);
  }, []);

  useEffect(() => {
    if (locomotiveScroll?.scroll) {
      locomotiveScroll?.scroll.on('scroll', ({ scroll }) => {
        setIsScrolled(isMobile ? scroll?.y > 15 : scroll?.y > 80);
      });
    }
  }, [isMobile, locomotiveScroll]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isSidebarOpen) {
        setTimeout(() => {
          setSidebarOpen(false);
        }, 400);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isSidebarOpen, router.events]);

  useBodyScroll({ off: isSidebarOpen });

  return (
    <Nav>
      <Underlay isVisible={isScrolled && !isSidebarOpen} />

      <StyledContainer>
        <ContentWrapper>
          <LogoLink
            aria-label={translate('common:actions.goToHomepage')}
            href={routeResolver('home')}
            locale={locale}
          >
            <Svg
              icon={logo}
              size={logoSize}
            />
          </LogoLink>

          <Actions>
            {!isTablet
              ? map(
                  navbarLinks,
                  ({ href, label }) =>
                    href && (
                      <NavbarLink
                        href={href}
                        key={label}
                        visible={!hideActions}
                        {...(href && !isExternalUrl(href)
                          ? { locale }
                          : {
                              rel: 'noopener',
                              target: '_blank'
                            })}
                      >
                        {translate(label)}
                      </NavbarLink>
                    )
                )
              : null}

            {isAuthenticated ? (
              <>
                {isTablet ? (
                  <>
                    <StyledIconButton
                      aria-label={translate('common:labels.myProducts')}
                      icon={icons.products}
                      iconSize={units(3)}
                      id={translate('common:labels.myProducts')}
                      onClick={() => setIsMyProductsMenuOpen(true)}
                      visible={!hideActions}
                    />

                    <MyProductsMobileMenu
                      isOpen={isMyProductsMenuOpen}
                      logo={logo}
                      logoSize={logoSize}
                      onClose={() => setIsMyProductsMenuOpen(false)}
                    />
                  </>
                ) : (
                  <MyProductsDropdown isVisible={!hideActions} />
                )}
              </>
            ) : (
              <StyledIconButton
                aria-label={translate('common:labels.myProducts')}
                href={authRoutes.signIn}
                icon={icons.products}
                iconSize={units(3)}
                visible={!hideActions}
              />
            )}

            <StyledIconButton
              aria-label={translate('common:labels.favorites')}
              href={myProductsRoutes.favourites}
              icon={icons.heart}
              iconSize={units(3)}
              visible={!hideActions}
            />

            <StyledIconButton
              aria-label={translate('common:labels.search')}
              icon={icons.search}
              iconSize={units(3)}
              id={translate('common:labels.search')}
              onClick={() => setSearchOpen(true)}
              visible={!hideActions}
            />

            <HamburgerMenu
              isOpen={isSidebarOpen}
              onClick={toggle}
            />
          </Actions>
        </ContentWrapper>
      </StyledContainer>

      <StyledSidebar isOpen={isSidebarOpen} />

      <Search
        isOpen={isSearchOpen}
        logo={logo}
        logoSize={logoSize}
        onClose={() => setSearchOpen(false)}
      />
    </Nav>
  );
};

/**
 * Export `Navbar` component.
 */

export default Navbar;
