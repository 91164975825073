/**
 * Module dependencies.
 */

import { ComponentType, createElement } from 'react';
import AmtrolAlfaLogo from './amtrol-alfa-logo';
import AmtrolAlfaLogoMobile from './amtrol-alfa-logo-mobile';
import ArrowLeftLarge from './arrow-left-large';
import ArrowLeftSmall from './arrow-left-small';
import ArrowRight from './arrow-right';
import ArrowRightLarge from './arrow-right-large';
import ArrowRightSmall from './arrow-right-small';
import Check from './check';
import ChevronDown from './chevron-down';
import ChevronLeft from './chevron-left';
import ChevronRight from './chevron-right';
import ChevronUp from './chevron-up';
import CircleInformation from './circle-information';
import Close from './close';
import DoubleBrandLogo from './double-brand-logo';
import DoubleBrandLogoMobile from './double-brand-logo-mobile';
import Download from './download';
import ExternalLink from './external-link';
import Eye from './eye';
import EyeOff from './eye-off';
import Filter from './filter';
import Heart from './heart';
import HeartFull from './heart-full';
import Logout from './logout';
import Menu from './menu';
import OptionsVertical from './options-vertical';
import Pause from './pause';
import Play from './play';
import Products from './products';
import Remove from './remove';
import Search from './search';
import SocialIcon from './social-icon';

/**
 * Convert icons to components.
 */

function convertIconsToComponents(list) {
  return Object.keys(list).reduce(
    (result, name) => ({
      ...result,
      [name]: createElement(list[name])
    }),
    {}
  );
}

/**
 * Icons list.
 */

const iconsList = {
  arrowLeftLarge: ArrowLeftLarge,
  arrowLeftSmall: ArrowLeftSmall,
  arrowRight: ArrowRight,
  arrowRightLarge: ArrowRightLarge,
  arrowRightSmall: ArrowRightSmall,
  check: Check,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  circleInformation: CircleInformation,
  close: Close,
  download: Download,
  externalLink: ExternalLink,
  eye: Eye,
  eyeOff: EyeOff,
  filter: Filter,
  heart: Heart,
  heartFull: HeartFull,
  logout: Logout,
  menu: Menu,
  optionsVertical: OptionsVertical,
  pause: Pause,
  play: Play,
  products: Products,
  remove: Remove,
  search: Search,
  socialIcon: SocialIcon
};

/**
 * Export icons types.
 */

export type IconsList = keyof typeof iconsList;
export type Icons = {
  [key in IconsList]: ComponentType<React.SVGProps<SVGSVGElement>>;
};

/**
 * Export `icons`.
 */

export const icons: Icons = convertIconsToComponents(iconsList) as Icons;

/**
 * Export `logosList`.
 */

export const logosList = {
  amtrolAlfaLogo: AmtrolAlfaLogo,
  amtrolAlfaLogoMobile: AmtrolAlfaLogoMobile,
  doubleBrandLogo: DoubleBrandLogo,
  doubleBrandLogoMobile: DoubleBrandLogoMobile
};

/**
 * Export logos types.
 */

export type LogosList = keyof typeof logosList;
export type Logos = {
  [key in LogosList]: ComponentType<React.SVGProps<SVGSVGElement>>;
};

/**
 * Export `logos`.
 */

export const logos: Logos = convertIconsToComponents(logosList) as Logos;
