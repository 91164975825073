/**
 * Export `BytesToSize`.
 */

export function bytesToSize(bytes: number): string {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i: number = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1000)).toString(),
    10
  );

  if (bytes === 0) {
    return '0 Byte';
  }

  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / Math.pow(1000, i)).toFixed(1)}${sizes[i]}`;
}
