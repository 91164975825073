/**
 * Module dependencies.
 */

import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  forwardRef,
  useCallback,
  useRef
} from 'react';

import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `ButtonProps` type.
 */

type ButtonProps = {
  'aria-expanded': boolean;
  'aria-haspopup': boolean;
  onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  role: string;
  tabIndex: number;
};

/**
 * `Alignment` type.
 */

type Alignment = 'center' | 'right';

/**
 * Export `DropdownProps` interface.
 */

export interface DropdownProps {
  alignment?: Alignment;
  children: ReactNode;
  className?: string;
  hasArrow?: boolean;
  isActive?: boolean;
  onClick: () => void;
  renderButton: (props: ButtonProps) => ReactElement;
  setActive: Dispatch<SetStateAction<boolean>>;
  width?: string;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

/**
 * `Menu` styled component.
 */

const Menu = styled.div<
  Pick<DropdownProps, 'width'> & {
    alignment?: Alignment;
    hasArrow?: boolean;
    isActive: boolean;
  }
>`
  background-color: ${color('white')};
  filter: drop-shadow(0 10px ${units(3)} ${color.transparentize('black', 0.1)});
  opacity: 0;
  pointer-events: ${ifProp('isActive', 'auto', 'none')};
  position: absolute;
  top: calc(100% + ${units(2)});
  transition: ${theme('animations.defaultTransition')};
  transition-property: opacity, transform, visibility;
  visibility: hidden;
  width: ${prop('width', '200px')};

  ${ifProp(
    'hasArrow',
    css`
      &::before {
        border-bottom: ${units(2)} solid ${color('white')};
        border-left: ${units(2)} solid transparent;
        border-right: ${units(2)} solid transparent;
        content: '';
        height: 0;
        position: absolute;
        top: -${units(2)};
        width: 0;
      }
    `
  )}

  ${switchProp('alignment', {
    center: css`
      left: 50%;
      transform: translate(-50%, ${units(3)});

      &::before {
        left: 50%;
        transform: translateX(-50%);
      }
    `,
    right: css`
      right: -10%;
      transform: translate(10%, ${units(3)});

      &::before {
        right: 9%;
      }
    `
  })}

  ${ifProp(
    'isActive',
    css`
      opacity: 1;
      visibility: visible;

      ${switchProp('alignment', {
        center: css`
          transform: translate(-50%, ${units(0.5)});
        `,
        right: css`
          transform: translate(10%, ${units(0.5)});
        `
      })}
    `
  )}
`;

/**
 * `Dropdown` component.
 */

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (props, ref): ReactElement => {
    const {
      alignment = 'center',
      children,
      className,
      hasArrow = false,
      isActive,
      onClick,
      renderButton,
      setActive,
      width
    } = props;

    const menuRef = useRef<HTMLDivElement>();
    const handleClick = useCallback(
      event => {
        event.stopPropagation();
        onClick();
        setActive(!isActive);
      },
      [isActive, onClick, setActive]
    );

    return (
      <Wrapper
        className={className}
        ref={ref}
      >
        {renderButton({
          'aria-expanded': isActive,
          'aria-haspopup': true,
          onClick: handleClick,
          role: 'button',
          tabIndex: 0
        })}

        <Menu
          alignment={alignment}
          hasArrow={hasArrow}
          isActive={isActive}
          ref={menuRef}
          role={'menu'}
          tabIndex={-1}
          width={width}
        >
          {children}
        </Menu>
      </Wrapper>
    );
  }
);

/**
 * `Dropdown` display name.
 */

Dropdown.displayName = 'Dropdown';

/**
 * Export `Dropdown` component.
 */

export default Dropdown;
