/**
 * Export `zIndex`.
 */

export const zIndex = {
  cookiesBar: 102,
  cookiesDrawer: 103,
  filters: 105,
  hamburgerMenu: 104,
  modal: 106,
  navbar: 102,
  pageTransition: 107,
  sectionsNavigation: 101,
  sidebar: 104,
  snackbar: 108
};
