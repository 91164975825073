/**
 * Module dependencies.
 */

import { Accordion } from './drawer-accordion';
import { CookiePreferences } from 'src/types/cookies';
import { IconButton, Svg, icons } from 'src/components/core';
import { SimpleToggle } from 'src/components/core/toggle/simple';
import {
  Type,
  color,
  media,
  units,
  useBodyScroll,
  useBreakpoint
} from '@untile/react-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import { logos } from 'src/components/core/icons';
import { useForm } from 'react-hook-form';
import Button from 'src/components/core/buttons/button';
import Transition, {
  TransitionStatus
} from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
  onAcceptTerms: (value: CookiePreferences) => void;
  onClose: () => void;
};

/**
 * Duration.
 */

const duration = 500;

/**
 * `orderedCategories` constant.
 */

const orderedCategories: ReadonlyArray<keyof CookiePreferences> = [
  'functional',
  'performance',
  'advertising'
] as const;

/**
 * `Overlay` styled component.
 */

const Overlay = styled.div<{ transitionState: TransitionStatus }>`
  background-color: ${color.transparentize('grey900', 0.4)};
  inset: 0;
  opacity: 0;
  position: fixed;
  transition: ${theme('animations.defaultTransition')};
  transition-property: opacity, visibility;
  visibility: hidden;
  z-index: ${theme('zIndex.cookiesDrawer')};

  ${ifProp(
    'isOpen',
    css`
      opacity: 1;
      visibility: visible;
    `
  )}

  ${switchProp('transitionState', {
    entered: css`
      opacity: 1;
      visibility: visible;
    `,
    entering: css`
      opacity: 1;
      visibility: visible;
    `,
    exited: css`
      opacity: 0;
      visibility: hidden;
    `,
    exiting: css`
      opacity: 0;
      visibility: hidden;
    `
  })}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ transitionState: TransitionStatus }>`
  background-color: ${color('white')};
  bottom: 0;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform ${theme('animations.defaultTransition')};
  width: 100vw;
  z-index: ${theme('zIndex.cookiesDrawer')};

  ${switchProp('transitionState', {
    entered: css`
      transform: translateX(0);
    `,
    entering: css`
      transform: translateX(0);
    `,
    exited: css`
      transform: translateX(100%);
    `,
    exiting: css`
      transform: translateX(100%);
    `
  })}

  ${media.min('ms')`
    max-width: ${units(64)};
  `}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: top;
  display: flex;
  justify-content: space-between;
  padding: ${units(11)} ${units(8)} ${units(4)};
`;

/**
 * `HeaderMobile` styled component.
 */

const HeaderMobile = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: ${units(3)};
  padding: ${units(1)} ${units(2.5)} ${units(2)};
`;

/**
 * `ScrollArea` styled component.
 */

const ScrollArea = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 ${units(2.5)};

  ${media.min('ms')`
    padding: 0 ${units(8)};
  `}
`;

/**
 * `Buttons` styled component.
 */

const Buttons = styled.div`
  box-shadow: 0 -${units(0.5)} ${units(1)} ${color.transparentize('black', 0.04)};
  display: flex;
  flex-wrap: wrap;
  gap: ${units(2)};
  justify-content: center;
  padding: ${units(3)} ${units(3)} ${units(4)};

  ${media.min('ms')`
    flex-wrap: nowrap;
    justify-content: space-evenly;
  `}
`;

/**
 * Export `CookiesDrawer` component.
 */

export const CookiesDrawer = ({ isOpen, onAcceptTerms, onClose }: Props) => {
  const { translate } = useTranslate();
  const { handleSubmit, register } = useForm<CookiePreferences>();
  const isMobile = useBreakpoint('ms', 'max');

  const onSubmit = (data: CookiePreferences) => {
    onClose();
    onAcceptTerms(data);
  };

  useBodyScroll({ off: isOpen });

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      timeout={duration}
      unmountOnExit
    >
      {state => (
        <Overlay
          onClick={onClose}
          transitionState={state}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper
              onClick={event => event.stopPropagation()}
              transitionState={state}
            >
              {isMobile ? (
                <HeaderMobile>
                  <Svg
                    icon={logos.doubleBrandLogoMobile}
                    size={'134px'}
                  />

                  <IconButton
                    icon={icons.close}
                    iconSize={units(3)}
                    onClick={onClose}
                  />

                  <Type.H4>{translate('common:cookiesDrawer.title')}</Type.H4>
                </HeaderMobile>
              ) : (
                <Header>
                  <Type.H4>{translate('common:cookiesDrawer.title')}</Type.H4>

                  <IconButton
                    icon={icons.close}
                    iconSize={units(2)}
                    onClick={onClose}
                  />
                </Header>
              )}

              <ScrollArea>
                <Accordion
                  header={translate(`common:cookiesDrawer.necessary.header`)}
                  rightContent={
                    <b>{translate('common:labels.alwaysActive')}</b>
                  }
                >
                  {translate(`common:cookiesDrawer.necessary.content`)}
                </Accordion>

                {orderedCategories.map(category => (
                  <Accordion
                    header={translate(
                      `common:cookiesDrawer.${category}.header`
                    )}
                    key={category}
                    rightContent={<SimpleToggle {...register(category)} />}
                  >
                    {translate(`common:cookiesDrawer.${category}.content`)}
                  </Accordion>
                ))}
              </ScrollArea>

              <Buttons>
                <Button
                  colorTheme={'secondary'}
                  onClick={() =>
                    onAcceptTerms({
                      advertising: false,
                      functional: false,
                      performance: false
                    })
                  }
                  size={'medium'}
                >
                  {translate('common:actions.acceptOnlyNecessary')}
                </Button>

                <Button
                  size={'medium'}
                  type={'submit'}
                >
                  {translate('common:actions.savePreferences')}
                </Button>
              </Buttons>
            </Wrapper>
          </form>
        </Overlay>
      )}
    </Transition>
  );
};
