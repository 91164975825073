/**
 * Module dependencies.
 */

import { ReactElement, useEffect, useMemo, useRef } from 'react';
import { color, units } from '@untile/react-components';
import { prop } from 'styled-tools';
import ReactSlider from 'react-slider';
import compact from 'lodash/compact';
import map from 'lodash/map';
import styled from 'styled-components';
import times from 'lodash/times';

/**
 * Export `HorizontalSliderProps` interface.
 */

export interface HorizontalSliderProps {
  className?: string;
  max: number;
  min: number;
  onChange: (value: number) => void;
  value?: number;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'slider    slider slider'
    'leftLabel .      rightLabel';
  grid-template-columns: max-content 1fr max-content;
  position: relative;
`;

/**
 * `StyledReactSlider` styled component.
 */

const StyledReactSlider = styled(ReactSlider)`
  grid-area: slider;
  height: ${units(3)};
  width: 100%;

  &::before,
  &::after {
    background-color: ${color('backgrounds.sage03')};
    content: '';
    height: ${units(3)};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

/**
 * `Track` styled component.
 */

const Track = styled.div`
  background-color: ${color('backgrounds.sage03')};
  border-radius: ${units(0.5)};
  height: ${units(0.5)};
  position: relative;
  top: 10px;
`;

/**
 * `Mark` styled component.
 */

const Mark = styled.span`
  background-color: ${color('backgrounds.sage03')};
  border-radius: 2px;
  height: ${units(2)};
  top: 4px;
  width: 2px;
`;

/**
 * `Thumb` styled component.
 */

const Thumb = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${units(7)};
  justify-content: end;
  outline: 0;
  position: relative;
  width: 2px;

  &::before {
    background-color: ${color('grey800')};
    border: 2px solid ${color('backgrounds.sage01')};
    border-radius: 50%;
    box-shadow: 0 ${units(0.5)} ${units(1)}
      ${color.transparentize('black', 0.16)};
    content: '';
    cursor: pointer;
    height: ${units(2.5)};
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: ${units(2.5)};
  }
`;

/**
 * `LimitLabel` styled component.
 */

const LimitLabel = styled.span<{ gridArea: string }>`
  color: ${color('grey300')};
  grid-area: ${prop('gridArea')};
  padding-top: ${units(1)};
  transform: translateX(calc(-50% + 1px));

  &:last-of-type {
    transform: translateX(calc(50% - 1px));
  }
`;

/**
 * `HorizontalSlider` component.
 */

const HorizontalSlider = (props: HorizontalSliderProps): ReactElement => {
  const { className, max, min, onChange, value } = props;
  const sliderRef = useRef(null);
  const marks = useMemo(() => {
    const difference = max - min;
    const gap =
      (difference < 20 && 1) ||
      (difference < 50 && 2) ||
      (difference < 200 && 10) ||
      50;

    return compact(
      map(times(Math.floor(difference / gap) + 1), value => {
        const mark = Math.ceil(value * gap + min);

        if (mark <= max) {
          return mark;
        }
      })
    );
  }, [max, min]);

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    function handleResize() {
      if (
        sliderRef &&
        sliderRef?.current &&
        sliderRef?.current?.slider.offsetParent !== null
      ) {
        sliderRef?.current?.resize();
      }
    }

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(sliderRef.current.slider);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Wrapper className={className}>
      <StyledReactSlider
        marks={marks}
        max={max}
        min={min}
        onChange={newValue => onChange(newValue)}
        ref={sliderRef}
        renderMark={props => <Mark {...props} />}
        renderThumb={(props, state) => (
          <Thumb {...props}>{state.valueNow}</Thumb>
        )}
        renderTrack={props => <Track {...props} />}
        value={value}
      />

      <LimitLabel gridArea={'leftLabel'}>{min}</LimitLabel>

      <LimitLabel gridArea={'rightLabel'}>{max}</LimitLabel>
    </Wrapper>
  );
};

/**
 * Export `HorizontalSlider` component.
 */

export default HorizontalSlider;
