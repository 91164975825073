/**
 * Module dependencies.
 */

import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';

import { createReactPortal, useBodyScroll } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import styled from 'styled-components';

/**
 * Export `ModalPortalProps` interface.
 */

export interface ModalPortalProps {
  children: ReactNode;
  isOpen: boolean;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ isVisible: boolean }>`
  transition: ${theme('animations.defaultTransition')};
  transition-property: visibility;
  visibility: ${ifProp('isVisible', 'visible', 'hidden')};
`;

/**
 * `ModalPortal` component.
 */

const ModalPortal = ({ children, isOpen }: ModalPortalProps): ReactElement => {
  const [mounted, setMounted] = useState(false);
  const handleGhostClick = useCallback(event => {
    if (event) {
      event.stopPropagation();
    }
  }, []);

  useBodyScroll({ off: isOpen });

  useEffect(() => setMounted(true), []);

  return mounted
    ? createReactPortal(
      <Wrapper
        isVisible={isOpen}
        onClick={handleGhostClick}
      >
        {isOpen ? children : null}
      </Wrapper>,
        'modal-root'
      )
    : null;
};

/**
 * Export `ModalPortal` component.
 */

export default ModalPortal;
