/**
 * Module dependencies.
 */

import { RouteName } from 'i18n-routes';
import { absoluteUrlResolver } from './url-resolver';
import { routes } from 'src/core/routes';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import template from 'lodash/template';

/**
 * Constants.
 */

const defaultLocale = process.env.NEXT_PUBLIC_LOCALIZATION_DEFAULT_LOCALE;

/**
 * `RouteInterpolations` type.
 */

type RouteInterpolations = {
  [key: string]: string;
};

/**
 * Template options.
 */

const templateOptions = {
  interpolate: /:([\s\S]+?)\b/g
};

/**
 * Export `routeTemplate`.
 */

export function routeTemplate(
  route: string,
  interpolations: RouteInterpolations
): string {
  return template(route, templateOptions)(interpolations);
}

/**
 * Export `useLocalizedRoute` hook.
 */

export function useLocalizedRoute() {
  const { locale } = useRouter();
  const handleRouteResolver = useCallback(
    (routeName: RouteName, interpolations?: RouteInterpolations) => {
      const route = routes[locale][routeName];

      if (!interpolations) {
        return route;
      }

      return template(route, templateOptions)(interpolations);
    },
    [locale]
  );

  return handleRouteResolver;
}

/**
 * Export `getRouteByLocale`.
 */

export function getRouteByLocale(routeName: RouteName, locale: string) {
  const route = routes[locale][routeName];

  return locale === defaultLocale ? route : `/${locale}${route}`;
}

/**
 * Export `resolvePageUrl`.
 */

export function resolvePageUrl(routeName: RouteName, locale: string) {
  const path = getRouteByLocale(routeName, locale);

  return absoluteUrlResolver(path);
}

/**
 * Export `normalizeRouteUrl`.
 */

export function normalizeRouteUrl(route: string) {
  if (route.length <= 1) {
    return route;
  }

  return route.slice(0, -1);
}
