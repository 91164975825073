/**
 * Module dependencies.
 */

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ReactElement, ReactNode } from 'react';
import { prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `StepperProps` interface.
 */

export interface StepperProps {
  animationDistance?: string;
  children: ReactNode;
  className?: string;
  lastAction: 'initial' | 'next' | 'previous';
  onEntered?: (isAppearing: boolean) => void;
  step: string;
}

/**
 * Step transition duration;
 */

const stepTransitionDuration = 500;

/**
 * `StyledTransitionGroup` styled component.
 */

const StyledTransitionGroup = styled(TransitionGroup)<
  Pick<StepperProps, 'animationDistance' | 'lastAction'>
>`
  position: relative;

  > .enter {
    opacity: 0;
  }

  > .enter-active {
    opacity: 1;
    transition-duration: ${stepTransitionDuration}ms;
  }

  > .exit {
    opacity: 1;
  }

  > .exit-active {
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition-duration: ${stepTransitionDuration}ms;
  }

  ${switchProp('lastAction', {
    next: css`
      > .enter {
        transform: translateX(${prop('animationDistance')});
      }

      > .enter-active {
        transform: translateX(0);
      }

      > .exit {
        transform: translateX(0);
      }

      > .exit-active {
        transform: translateX(-${prop('animationDistance')});
      }
    `,
    previous: css`
      > .enter {
        transform: translateX(-${prop('animationDistance')});
      }

      > .enter-active {
        transform: translateX(0);
      }

      > .exit {
        transform: translateX(0);
      }

      > .exit-active {
        transform: translateX(${prop('animationDistance')});
      }
    `
  })}
`;

/**
 * `StepWrapper` styled component.
 */

const StepWrapper = styled.div`
  opacity: 1;
  transition-property: opacity, transform;
`;

/**
 * `Stepper` component.
 */

function Stepper(props: StepperProps): ReactElement {
  const {
    animationDistance = '100%',
    children,
    className,
    lastAction,
    onEntered,
    step
  } = props;

  return (
    <StyledTransitionGroup
      animationDistance={animationDistance}
      className={className}
      lastAction={lastAction}
    >
      <CSSTransition
        key={step}
        onEntered={onEntered}
        timeout={stepTransitionDuration}
      >
        <StepWrapper>{children}</StepWrapper>
      </CSSTransition>
    </StyledTransitionGroup>
  );
}

/**
 * Export `Stepper` component.
 */

export default Stepper;
