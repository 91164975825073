/**
 * Module dependencies.
 */

import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMemo } from 'react';

/**
 * Export `MenuOption` type.
 */

export type MenuOption = {
  href?: string | null;
  isEmpty?: boolean;
  isHighlighted?: boolean;
  label?: string;
};

/**
 * Export `MenuCategory` type.
 */

export type MenuCategory = {
  options: MenuOption[];
  title?: string;
};

/**
 * `Categories` type.
 */

type Categories = {
  [key: string]: MenuCategory;
};

/**
 * Export `useCategories`.
 */

export function useCategories(): Categories {
  const routeResolver = useLocalizedRoute();
  const categories: Categories = useMemo(() => {
    return {
      company: {
        options: [
          {
            href: routeResolver('aboutUs'),
            label: 'menu:aboutUs'
          },
          {
            href: routeResolver('innovation'),
            label: 'menu:innovation'
          },
          {
            href: routeResolver('sustainability'),
            label: 'menu:sustainability'
          },
          {
            href: routeResolver('awards'),
            label: 'menu:awards'
          },
          {
            href: routeResolver('ourStory'),
            label: 'menu:ourStory'
          },
          {
            href: routeResolver('humanResources'),
            label: 'menu:humanResources'
          },
          {
            href: routeResolver('supportPrograms'),
            label: 'menu:supportPrograms'
          },
          {
            href: routeResolver('partnerships'),
            label: 'menu:partnerships'
          },
          {
            href: routeResolver('newsExhibitions'),
            label: 'menu:newsExhibitions'
          }
        ],
        title: 'menu:labels.company'
      },
      contacts: {
        options: [
          {
            href: routeResolver('contactUs'),
            isHighlighted: true,
            label: 'menu:contactUs'
          },
          {
            href: 'https://www.worthingtonindustries.eu/company/',
            label: 'menu:worthingtonEurope'
          },
          {
            href: 'https://www.worthingtonindustries.com/Home',
            label: 'menu:worthingtonUsa'
          }
        ],
        title: 'menu:labels.contacts'
      },
      markets: {
        options: [
          {
            href: 'https://www.balloontime.com/',
            isHighlighted: true,
            label: 'Balloon Time®'
          }
        ],
        title: 'menu:labels.markets'
      },
      others: {
        options: [
          {
            isEmpty: true
          },
          {
            href: routeResolver('advisor'),
            label: 'menu:advisor'
          },
          {
            href: routeResolver('downloads'),
            label: 'menu:downloads'
          },
          {
            href: 'https://www.eservice-amtrol-alfa.com/',
            label: 'menu:clientArea.label'
          }
        ]
      }
    };
  }, [routeResolver]);

  return categories;
}

/**
 * Export `useMenu`.
 */

export function useMenu() {
  const { company, contacts, markets, others } = useCategories();
  const menu = useMemo(() => {
    return [
      {
        options: others.options,
        title: markets.title
      },
      company,
      contacts
    ];
  }, [company, contacts, markets.title, others.options]);

  return menu;
}

/**
 * Export `useMenuFooterLinks`.
 */

export function useMenuFooterLinks() {
  const routeResolver = useLocalizedRoute();
  const menuFooterLinks = useMemo(() => {
    return [
      {
        href: routeResolver('termsConditions'),
        label: 'menu:termsOfUse'
      },
      {
        href: routeResolver('privacyPolicy'),
        label: 'menu:privacyPolicy'
      },
      {
        href: routeResolver('cookiesPolicy'),
        label: 'menu:cookiesPolicy'
      },
      {
        href: routeResolver('managementPolicy'),
        label: 'menu:managementPolicy'
      },
      {
        href: 'https://whistleblowersoftware.com/secure/969807ac-c5b2-4bc1-8f8c-f9304588781a',
        label: 'menu:ethicalConductLine'
      }
    ];
  }, [routeResolver]);

  return menuFooterLinks;
}
