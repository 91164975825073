/**
 * Module dependencies.
 */

import { color, media, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `FormGroup` styled component.
 */

export const FormGroup = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units(3)};
  position: relative;
  width: 100%;

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      pointer-events: none;
    `
  )}

  * {
    outline: none;
  }
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled.label<{
  for?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
}>`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: ${units(1)};
  pointer-events: none;
  text-transform: uppercase;
  white-space: nowrap;

  ${ifProp(
    'isRequired',
    css`
      &::after {
        color: ${color('red600')};
        content: '*';
      }
    `
  )}

  ${ifProp(
    'hasError',
    css`
      color: ${color('red600')};
    `
  )}

  ${ifProp(
    'isDisabled',
    css`
      color: ${color('grey200')};
    `
  )}
`;

/**
 * Export `formControlStyle` constant.
 */

export const formControlStyle = css`
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: ${color('grey500')};
  display: block;
  font-size: 14px;
  height: ${units(5)};
  line-height: 24px;
  outline: 0;
  position: relative;
  transition: color ${theme('animations.defaultTransition')};
  width: 100%;

  &::placeholder {
    color: ${color('grey500')} !important;
    opacity: 1;
  }
`;

/**
 * Export `FormControl` styled component.
 */

export const FormControl = styled.input<{
  as?: any;
  hasIcon?: boolean;
  hasUnit?: boolean;
  size?: string;
}>`
  padding: ${units(1)} 0;

  ${formControlStyle};

  ${ifProp(
    ['hasIcon', 'hasUnit'],
    css`
      padding-right: ${units(3)};
    `
  )}

  ${ifProp(
    { as: 'textarea' },
    css`
      height: auto;
      resize: vertical;
    `
  )}
`;

/**
 * Export `HelpText` styled component.
 */

export const HelpText = styled.div<{ hasError?: boolean }>`
  color: ${color('grey500')};
  font-size: 12px;
  line-height: 20px;

  ${ifProp(
    'hasError',
    css`
      color: ${color('red600')};
    `
  )}
`;

/**
 * Export `Wrapper` styled component.
 */

export const Wrapper = styled.div<{
  disabled?: boolean;
  hasError?: boolean;
}>`
  align-items: center;
  appearance: none;
  border: 0;
  border-bottom: 1px solid ${color('grey800')};
  border-radius: 0;
  box-shadow: none;
  display: grid;
  grid-template-columns: 1fr max-content;
  margin: 0 0 ${units(0.5)};
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: border-color, box-shadow;

  ${media.min('ms')`
    margin: 0 0 ${units(1)};
  `}

  &:focus,
  &:hover {
    border-color: ${color('grey800')};
    box-shadow: 0 4px 4px -4px ${color('grey200')};

    ${FormControl} {
      color: ${color('grey800')};
    }
  }

  ${ifProp(
    'disabled',
    css`
      border-color: ${color('grey200')};

      ${FormControl} {
        color: ${color('grey200')};

        &::placeholder {
          color: ${color('grey200')} !important;
          opacity: 1;
        }
      }
    `
  )}

  ${ifProp(
    'hasError',
    css`
      border-color: ${color('red600')};

      ${FormControl} {
        color: ${color('red600')};
      }
    `
  )}
`;
