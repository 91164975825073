/**
 * Module dependencies.
 */

import { FC, ReactElement, ReactNode, forwardRef } from 'react';
import { icons } from '../icons';
import { units } from '@untile/react-components';
import Svg from '../svg';
import styled from 'styled-components';

/**
 * Export `ExternalLinkProps` interface.
 */

export interface ExternalLinkProps {
  children: ReactNode;
  className?: string;
  href: string | null;
}

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled.a`
  align-items: center;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg).attrs({
  icon: icons.externalLink,
  size: units(2)
})`
  margin-left: ${units(1)};
`;

/**
 * `ExternalLink` component.
 */

const ExternalLink: FC<ExternalLinkProps> = forwardRef<any, ExternalLinkProps>(
  (props: ExternalLinkProps, ref: any): ReactElement => {
    const { children, ...rest } = props;

    return (
      <StyledLink
        ref={ref}
        rel={'noopener'}
        target={'_blank'}
        {...rest}
      >
        {children}

        <Icon
          icon={icons.externalLink}
          size={units(2)}
        />
      </StyledLink>
    );
  }
);

/**
 * `ExternalLink` display name.
 */

ExternalLink.displayName = 'ExternalLink';

/**
 * Export `ExternalLink` component.
 */

export default ExternalLink;
