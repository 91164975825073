/**
 * Module dependencies.
 */

import { regexes as untileRegexes } from '@untile/react-components';

/**
 * Export `regexes`.
 */

export const regexes = {
  ...untileRegexes,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  password: /^.{6,}$/
};
