/**
 * Palette.
 */

const palette = {
  black: '#000000',
  blue400: '#3b76c5',
  blue600: '#083f89',
  green600: '#657d34',
  grey25: '#fafafa',
  grey50: '#f0f0f0',
  grey100: '#e0e0e0',
  grey200: '#c2c2c2',
  grey300: '#a3a3a3',
  grey400: '#858585',
  grey500: '#666666',
  grey600: '#4d4d4d',
  grey700: '#333333',
  grey800: '#1a1a1a',
  grey900: '#0a0a0a',
  purple500: '#8b3cca',
  red600: '#943034',
  white: '#ffffff'
};

/**
 * Background pastel colors.
 */

const backgrounds = {
  blue01: '#eafaff',
  blue02: '#c7dbee',
  blue03: '#b6c8da',
  green01: '#f4ffee',
  green02: '#e4efdf',
  green03: '#cad3c5',
  indigo01: '#edf4ff',
  indigo02: '#dfe8f8',
  indigo03: '#cbd4e4',
  orange01: '#fff5ed',
  orange02: '#eed3c1',
  orange03: '#e2c8b6',
  purple01: '#f7f5fe',
  purple02: '#ece8f8',
  purple03: '#e1deea',
  red01: '#fdf1f3',
  red02: '#f9eaed',
  red03: '#ecdfe1',
  sage01: '#edf5f4',
  sage02: '#d0dfdc',
  sage03: '#c2d0cd',
  taupe01: '#faf7ec',
  taupe02: '#dfdbd0',
  taupe03: '#d2cdbf',
  teal01: '#ebfff9',
  teal02: '#e0f5ef',
  teal03: '#d1e7e0',
  yellow01: '#fffde9',
  yellow02: '#f9f5db',
  yellow03: '#eeeacc'
};

/**
 * Export `colors`.
 */

export const colors = {
  ...palette,
  backgrounds,
  error: palette.red600,
  success: palette.green600,
  textColor: palette.grey800
};
