/**
 * Module dependencies.
 */

/**
 * `SvgHeart` component.
 */

const SvgHeart: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M24 9.055c-4.712-4.221-11.957-4.068-16.485.46-4.687 4.686-4.687 12.284 0 16.97l13.657 13.657a4 4 0 0 0 5.656 0l13.657-13.657c4.687-4.686 4.687-12.284 0-16.97C35.958 4.987 28.712 4.834 24 9.055Zm-2.343 3.288a8 8 0 0 0-11.314 11.314L24 37.314l13.657-13.657a8 8 0 0 0-11.314-11.314l-.929.93a2 2 0 0 1-2.828 0l-.93-.93Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgHeart` component.
 */

export default SvgHeart;
