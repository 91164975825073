/**
 * Module dependencies.
 */

import { Checkbox, CheckboxProps, Input, icons } from 'src/components/core';
import { ReactElement, useCallback } from 'react';
import { color, media, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import { regexes } from 'src/core/utils/regexes';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNewsletter } from 'src/api/form/use-newsletter';
import TermsConditionsCheckboxLabel from 'src/components/core/forms/terms-conditions-checkbox-label';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Form` styled component.
 */

const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

/**
 * `StyledInput` styled component.
 */

const StyledInput = styled(Input)`
  ${media.max('md')`
    max-width: ${units(58.5)};
  `}

  > div input {
    background-color: ${color('grey25')};

    &::placeholder {
      color: ${color('grey800')} !important;
    }
  }
`;

/**
 * `StyledCheckbox` styled component.
 */

const StyledCheckbox = styled(Checkbox)<
  CheckboxProps & { isVisible?: boolean }
>`
  margin-bottom: ${units(8)};
  opacity: ${ifProp('isVisible', 1, 0)};
  padding: 0;
  transition: opacity ${theme('animations.defaultTransition')};
`;

/**
 * `Newsletter` component.
 */

const Newsletter = (): ReactElement => {
  const { translate } = useTranslate();
  const { formState, handleSubmit, register, reset, watch } = useForm({
    defaultValues: { email: '' },
    mode: 'onBlur'
  });

  const errors = formState?.errors;
  const isCheckboxVisible = !isEmpty(watch('email'));
  const { isSubmitting, onSubscribeNewsletter } = useNewsletter();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onSubmit = useCallback(
    async data => {
      const recaptchaToken = await executeRecaptcha('newsletter').then(
        token => token
      );

      const formData = { ...data, recaptchaToken };

      onSubscribeNewsletter(formData).then(() => {
        reset();
      });
    },
    [executeRecaptcha, onSubscribeNewsletter, reset]
  );

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledInput
          disabled={isSubmitting}
          error={errors?.email}
          icon={icons.arrowRight}
          iconLabel={translate('common:actions.submit')}
          id={'newsletter-email'}
          name={'email'}
          onClickIcon={handleSubmit(onSubmit)}
          placeholder={translate('forms:placeholders.email')}
          {...register('email', {
            pattern: {
              message: translate('forms:rules.email'),
              value: regexes.email
            },
            required: {
              message: translate('forms:rules.required'),
              value: true
            }
          })}
          type={'email'}
        />

        <StyledCheckbox
          alignment={'flex-start'}
          disabled={isSubmitting}
          id={'newsletter-checkbox'}
          isVisible={isCheckboxVisible}
          label={
            <TermsConditionsCheckboxLabel
              i18nKey={'forms:labels.termsConditions'}
            />
          }
          name={'newsletter-checkbox'}
          {...register('email', {
            required: {
              message: translate('forms:rules.required'),
              value: true
            }
          })}
        />
      </Form>
    </>
  );
};

/**
 * Export `Newsletter` component.
 */

export default Newsletter;
