/**
 * Module dependencies.
 */

/**
 * `SvgSearch` component.
 */

const SvgSearch: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M20 8C13.373 8 8 13.373 8 20s5.373 12 12 12 12-5.373 12-12S26.627 8 20 8ZM4 20c0-8.837 7.163-16 16-16s16 7.163 16 16c0 3.697-1.254 7.102-3.36 9.811l10.774 10.775a2 2 0 1 1-2.828 2.828L29.81 32.64A15.931 15.931 0 0 1 20 36c-8.837 0-16-7.163-16-16Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgSearch` component.
 */

export default SvgSearch;
