/**
 * Module dependencies.
 */

import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { units } from '@untile/react-components/dist/styles';
import Message, { MessageAppearance } from '../message';
import styled from 'styled-components';

/**
 * Export `Options` type.
 */

export type Options = {
  appearance?: MessageAppearance;
  duration?: number;
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  id: number;
  onDismiss: (id: number) => void;
  options?: Options;
};

/**
 * `StyledMessage` styled component.
 */

const StyledMessage = styled(Message)`
  margin-bottom: ${units(1)};
`;

/**
 * `Snackbar` container.
 */

const Snackbar = (props: Props): ReactElement => {
  const { children, id, onDismiss, options } = props;
  const handleClick = useCallback(
    event => {
      event.stopPropagation();

      onDismiss(id);
    },
    [id, onDismiss]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      onDismiss(id);
    }, options?.duration ?? 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [id, onDismiss, options]);

  return (
    <StyledMessage
      appearance={options?.appearance}
      onClose={handleClick}
    >
      {children}
    </StyledMessage>
  );
};

/**
 * Export `Snackbar` container.
 */

export default Snackbar;
