/**
 * Module dependencies.
 */

import { Epilogue } from 'next/font/google';
import { css } from 'styled-components';
import { setFontStyle } from '@untile/react-components';

/**
 * Init Epilogue font.
 */

// eslint-disable-next-line new-cap
const epilogue = Epilogue({ subsets: ['latin'] });

/**
 * Font families.
 */

const fontFamily = {
  sansSerif: `${epilogue.style.fontFamily}, sans-serif`
};

/**
 * Type sizes.
 */

const typeSizes = {
  display1: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 48,
    fontSizeMax: 48,
    fontSizeMin: 40,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 56
  },
  display2: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 24,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 40
  },
  h1: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 56,
    fontSizeMax: 56,
    fontSizeMin: 40,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 64
  },
  h2: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 48,
    fontSizeMax: 48,
    fontSizeMin: 32,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 56
  },
  h3: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 32,
    fontSizeMax: 32,
    fontSizeMin: 24,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 40
  },
  h4: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 24,
    fontSizeMax: 24,
    fontSizeMin: 20,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 32
  },
  h5: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 20,
    fontSizeMax: 20,
    fontSizeMin: 16,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 32
  },
  h6: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 24
  },
  paragraph: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 24
  },
  small: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 20
  },
  subtitle1: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 32,
    fontSizeMax: 32,
    fontSizeMin: 24,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 42
  },
  subtitle2: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 24,
    fontSizeMax: 24,
    fontSizeMin: 20,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 32
  }
};

/**
 * `display1` style.
 */

const display1 = css`
  ${setFontStyle(typeSizes.display1)}
`;

/**
 * `display2` style.
 */

const display2 = css`
  ${setFontStyle(typeSizes.display2)}
`;

/**
 * `h1` style.
 */

const h1 = css`
  ${setFontStyle(typeSizes.h1)}
`;

/**
 * `h2` style.
 */

const h2 = css`
  ${setFontStyle(typeSizes.h2)}
`;

/**
 * `h3` style.
 */

const h3 = css`
  ${setFontStyle(typeSizes.h3)}
`;

/**
 * `h4` style.
 */

const h4 = css`
  ${setFontStyle(typeSizes.h4)}
`;

/**
 * `h5` style.
 */

const h5 = css`
  ${setFontStyle(typeSizes.h5)}
`;

/**
 * `h6` style.
 */

const h6 = css`
  ${setFontStyle(typeSizes.h6)}
`;

/**
 * `paragraph` style.
 */

const paragraph = css`
  ${setFontStyle(typeSizes.paragraph)}
`;

/**
 * `small` style.
 */

const small = css`
  ${setFontStyle(typeSizes.small)}
`;

/**
 * `subtitle1` style.
 */

const subtitle1 = css`
  ${setFontStyle(typeSizes.subtitle1)}
`;

/**
 * `subtitle2` style.
 */

const subtitle2 = css`
  ${setFontStyle(typeSizes.subtitle2)}
`;

/**
 * Export types to be generated.
 */

const styles = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p: paragraph, // eslint-disable-line id-length
  small
};

/**
 * Export `type`.
 */

export const typography = {
  fontFamily,
  otherStyles: {
    display1,
    display2,
    subtitle1,
    subtitle2
  },
  styles
};
