/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

/**
 * Export `ImageProps` type.
 */

export type ImageProps = NextImageProps & {
  className?: string;
};

/**
 * `Image` component.
 */

const Image = (props: ImageProps): ReactElement => (
  <NextImage
    priority
    quality={100}
    {...props}
  />
);

/**
 * Export `Image` component.
 */

export default Image;
