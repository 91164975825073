/**
 * Module dependencies.
 */

import applyCaseMiddleware from 'axios-case-converter';
import axios from 'axios';
import qs from 'qs';

/**
 * Export `Token` type.
 */

export type Token = string | null | undefined;

/**
 * Export `acceptLanguageHeader`.
 */

export function acceptLanguageHeader(locale: string) {
  return {
    'Accept-Language': locale
  };
}

/**
 * Export `authorizationHeader`.
 */

export function authorizationHeader(token: Token) {
  return {
    Authorization: `Bearer ${token}`
  };
}

/**
 * Export `request.
 */

export const request = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
    paramsSerializer: params => qs.stringify(params)
  })
);

/**
 * Export `authRequest.
 */

export const authRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AUTH_API_BASE_URL,
  paramsSerializer: params => qs.stringify(params)
});

/**
 * Export `handleRequestError`.
 */

export function handleRequestError(error: any) {
  return error?.response ?? error;
}
