/**
 * Module dependencies.
 */

import { ReactElement, useEffect, useRef, useState } from 'react';
import { color, media, units } from '@untile/react-components';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  showInMobile?: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  isVisible?: boolean;
  showInMobile?: boolean;
}>`
  opacity: ${ifProp('isVisible', 1, 0)};
  position: fixed;
  right: ${units(2)};
  top: 135vw;
  transition: opacity 750ms ease;
  width: max-content;
  z-index: ${theme('zIndex.sectionsNavigation')};

  @media only screen and (min-width: 321px) {
    top: 140vw;
  }

  ${media.max('md')`
    ${ifNotProp(
      'showInMobile',
      css`
        display: none;
      `
    )}
  `}

  ${media.min('ms')`
    top: 66vw;
  `}

  ${media.min('md')`
    right: ${units(4)};
    top: 34vw;
  `}
`;

/**
 * `Indicator` styled component.
 */

const Indicator = styled.div`
  align-items: center;
  color: ${color('black')};
  display: flex;
  flex-direction: column;
  grid-row-gap: ${units(2)};
  justify-content: center;
  position: relative;

  span {
    ${theme('typography.styles.small')}

    text-transform: uppercase;
    transform: rotate(180deg);
    white-space: nowrap;
    writing-mode: vertical-rl;
  }
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  border-right: 1px solid ${color('black')};
  height: ${units(15)};
  text-align: center;
`;

/**
 * `ScrollDownIndicator` component.
 */

const ScrollDownIndicator = (props: Props): ReactElement => {
  const { className, showInMobile = false } = props;

  const { translate } = useTranslate();
  const ref = useRef<HTMLDivElement>();
  const locomotiveScroll = useLocomotiveScroll();
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    if (ref?.current && locomotiveScroll?.scroll) {
      locomotiveScroll?.scroll.on('scroll', ({ scroll }) => {
        setIsVisible(scroll.y < ref?.current?.offsetHeight * 1.5);
      });
    }
  }, [locomotiveScroll?.scroll]);

  return (
    <Wrapper
      className={className}
      isVisible={isVisible}
      ref={ref}
      showInMobile={showInMobile}
    >
      <Indicator>
        <span>{translate('common:actions.scrollDown')}</span>

        <Line />
      </Indicator>
    </Wrapper>
  );
};

/**
 * Export `ScrollDownIndicator` component.
 */

export default ScrollDownIndicator;
