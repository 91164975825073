/**
 * Constants.
 */

const defaultLocale = process.env.NEXT_PUBLIC_LOCALIZATION_DEFAULT_LOCALE;
const locales = process.env.NEXT_PUBLIC_LOCALIZATION_LOCALES;

/**
 * Export `i18n` configuration.
 */

module.exports = {
  defaultLocale,
  loadLocaleFrom: (locale, namespace) => {
    return import(`./public/static/locales/${locale}/${namespace}.json`).then(
      response => response.default
    );
  },
  locales: locales.split(','),
  pages: {
    '*': ['common', 'errors', 'footer', 'forms', 'menu'],
    '/': ['homepage'],
    '/_error': ['errors'],
    '/about-us': ['about-us'],
    '/advisor': ['advisor'],
    '/awards': ['awards'],
    '/contact-us': ['contact-us'],
    '/downloads': ['downloads'],
    '/human-resources': ['human-resources'],
    '/innovation': ['innovation'],
    '/legal/cookies-policy': ['cookies-policy'],
    '/legal/management-policy': ['management-policy'],
    '/legal/privacy-policy': ['privacy-policy'],
    '/legal/terms-conditions': ['terms-conditions'],
    '/partnerships': ['partnerships'],
    '/patents': ['patents'],
    '/sign-in': ['sign-in'],
    '/support-programs': ['support-programs'],
    '/sustainability': ['sustainability'],
    'rgx:^/account': ['account'],
    'rgx:^/model-range': ['technologies'],
    'rgx:^/my-products': ['my-products'],
    'rgx:^/news-exhibitions': ['news-exhibitions'],
    'rgx:^/password': ['password'],
    'rgx:^/sign-up': ['sign-up'],
    'rgx:^/technologies': ['technologies']
  }
};
