/**
 * Module dependencies.
 */

import { apiEndpoints } from 'src/core/api-endpoints';
import { authApiEndpoints } from 'src/core/auth-api-endpoints';
import template from 'lodash/template';

/**
 * `ApiEndpoint` type.
 */

type ApiEndpoint = keyof typeof apiEndpoints;

/**
 * `AuthApiEndpoint` type.
 */

type AuthApiEndpoint = keyof typeof authApiEndpoints;

/**
 * `Interpolations` type.
 */

type Interpolations = {
  [key: string]: string | number;
};

/**
 * Template options.
 */

const templateOptions = {
  interpolate: /:([\s\S]+?)\b/g
};

/**
 * Export `getApiEndpoint`.
 */

export function getApiEndpoint(
  endpointName: ApiEndpoint,
  interpolations?: Interpolations
): string {
  const endpoint = apiEndpoints[endpointName];

  if (interpolations) {
    return template(endpoint, templateOptions)(interpolations);
  }

  return endpoint;
}

/**
 * Export `getAuthApiEndpoint`.
 */

export function getAuthApiEndpoint(
  endpointName: AuthApiEndpoint,
  interpolations?: Interpolations
): string {
  const endpoint = authApiEndpoints[endpointName];

  if (interpolations) {
    return template(endpoint, templateOptions)(interpolations);
  }

  return endpoint;
}
