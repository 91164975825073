/**
 * Module dependencies.
 */

import { Fill, color, media, units } from '@untile/react-components';
import { ReactElement } from 'react';
import { Type } from '../typography';
import { icons } from '../icons';
import { theme } from 'styled-tools';
import Button, { ButtonProps } from '../buttons/button';
import IconButton from '../buttons/icon-button';
import ModalPortal from './modal-portal';
import styled from 'styled-components';

/**
 * `Action` type.
 */

type Action = Pick<ButtonProps, 'colorTheme' | 'onClick'> & {
  label: string;
};

/**
 * Export `ModalProps` interface.
 */

export interface ModalProps {
  actions: Action[];
  className?: string;
  isOpen?: boolean;
  onClose: () => void;
  title: string;
}

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)`
  align-items: center;
  background-color: ${color.transparentize('grey900', 0.4)};
  display: flex;
  justify-content: center;
  overflow: auto;
  padding: ${units(2)};
  position: fixed;
  scroll-snap-type: y mandatory;
  z-index: ${theme('zIndex.modal')};
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  box-shadow: 0 10px ${units(3)} ${color.transparentize('black', 0.1)};
  display: flex;
  flex-direction: column;
  max-width: ${units(42)};
  padding: ${units(2)};

  ${media.min('ms')`
    max-width: ${units(59)};
  `}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(8)};
`;

/**
 * `ButtonsWrapper` styled component.
 */

const ButtonsWrapper = styled.div`
  align-items: center;
  align-self: end;
  display: flex;
  grid-column-gap: ${units(3)};
  margin-right: ${units(0.5)};
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton)`
  padding: 0;
`;

/**
 * `Modal` component.
 */

const Modal = (props: ModalProps): ReactElement => {
  const { actions, className, isOpen, onClose, title } = props;

  return (
    <ModalPortal isOpen={isOpen}>
      <Overlay onClick={onClose}>
        <Wrapper
          className={className}
          onClick={event => event.stopPropagation()}
        >
          <Header>
            <Type.H5>{title}</Type.H5>

            <CloseButton
              icon={icons.close}
              iconSize={units(3)}
              onClick={onClose}
            />
          </Header>

          <ButtonsWrapper>
            {actions.map(({ label, ...rest }, index) => (
              <Button
                key={index}
                size={'medium'}
                {...rest}
              >
                {label}
              </Button>
            ))}
          </ButtonsWrapper>
        </Wrapper>
      </Overlay>
    </ModalPortal>
  );
};

/**
 * Export `Modal` component.
 */

export default Modal;
