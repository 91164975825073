/**
 * Module dependencies.
 */

import { getKeyFromObject } from './objects';

/**
 * Network errors.
 */

const networkErrors = {
  changePassword: {
    validationFailed: 'validation_failed'
  },
  confirmationEmail: {
    forbidden: 'forbidden',
    notFound: 'not_found'
  },
  newsletter: {
    alreadySubscribed: '409'
  },
  resendConfirmation: {
    unauthorized: 'unauthorized'
  },
  resetPassword: {
    notFound: 'not_found'
  },
  signIn: {
    unauthorized: 'unauthorized'
  },
  signUp: {
    unauthorized: 'unauthorized'
  },
  updateUser: {
    notFound: 'not_found'
  }
} as const;

/**
 * `Error` type.
 */

type Error = keyof typeof networkErrors;

/**
 * Export `getNetworkErrorTranslatedKey`.
 *
 * Shape on translations: ${key}.errors.network.${error}.
 * The default ${error} key is `default`;
 */

export function getNetworkErrorTranslatedKey(
  error: Error,
  errorCode: string | null
) {
  if (!errorCode) {
    return `${error}.network.default`;
  }

  const networkError = networkErrors[error];
  const errorValues = Object.values(networkError) as string[];
  const hasErrorCode = errorValues.includes(errorCode);

  const errorTranslatedKey = hasErrorCode
    ? getKeyFromObject(networkError, errorCode)
    : 'default';

  return `${error}.network.${errorTranslatedKey}`;
}
