/**
 * Module dependencies.
 */

/**
 * `SvgRemove` component.
 */

const SvgRemove: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={'M8 24a2 2 0 0 1 2-2h28a2 2 0 1 1 0 4H10a2 2 0 0 1-2-2Z'}
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgRemove` component.
 */

export default SvgRemove;
