/**
 * Module dependencies.
 */

/**
 * `SvgProducts` component.
 */

const SvgProducts: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M16.524 3.5a1.523 1.523 0 0 0 0 3.046h.472v5.24h-.88a5.615 5.615 0 0 0-5.616 5.615v16.626a5.615 5.615 0 0 0 3.939 5.341V44.5h19.618v-5.132a5.615 5.615 0 0 0 3.94-5.34V17.4a5.615 5.615 0 0 0-5.616-5.615H31.5v-5.24h.472a1.523 1.523 0 0 0 0-3.046H16.524Zm3.518 8.286v-5.24h8.412v5.24h-2.683v-1.699a1.523 1.523 0 0 0-3.046 0v1.7h-2.683Zm-5.744 3.799a2.569 2.569 0 0 1 1.817-.753h16.266a2.57 2.57 0 0 1 2.57 2.57v8.4H13.545v-8.4c0-.682.27-1.335.753-1.817Zm-.752 18.44v-5.177H34.95v5.178a2.57 2.57 0 0 1-2.569 2.569H16.115a2.569 2.569 0 0 1-2.569-2.57Zm3.939 7.429v-1.813h13.527v1.813H17.485Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgProducts` component.
 */

export default SvgProducts;
