/**
 * Module dependencies.
 */

import { resolve } from 'url';

/**
 * Export `absoluteUrlResolver`.
 */

export function absoluteUrlResolver(path: string): string {
  return resolve(process.env.NEXT_PUBLIC_BASE_URL, path);
}

/**
 * `isValidUrl`.
 */

export function isValidUrl(urlString: string): boolean {
  try {
    new URL(urlString); // eslint-disable-line no-new

    return true;
  } catch (err) {
    return false;
  }
}
