/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { color, units } from '@untile/react-components';
import { ifProp, prop, theme } from 'styled-tools';
import map from 'lodash/map';
import styled from 'styled-components';
import times from 'lodash/times';

/**
 * Export `GalleryNavigationProps` interface.
 */

export interface GalleryNavigationProps {
  activeDot: number;
  className?: string;
  onClickDot: (index) => void;
  total: number;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ total: number }>`
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(${prop('total')}, ${units(4)});
  width: max-content;
`;

/**
 * `Dot` styled component.
 */

const Dot = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  padding: ${units(2)} 0;
  pointer-events: ${ifProp('isActive', 'none', 'auto')};
  position: relative;

  &::after {
    background-color: ${ifProp('isActive', color('grey700'), color('grey500'))};
    content: '';
    height: ${ifProp('isActive', '2px', '1px')};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: ${theme('animations.fastTransition')};
    transition-property: background-color, height;
    width: 100%;
  }

  &:hover {
    &::after {
      background-color: ${color('grey700')};
      height: 2px;
    }
  }
`;

/**
 * `GalleryNavigation` component.
 */

const GalleryNavigation = (props: GalleryNavigationProps): ReactElement => {
  const { activeDot, className, onClickDot, total } = props;

  return (
    <Wrapper
      className={className}
      total={total}
    >
      {map(times(total), (_, index) => (
        <Dot
          isActive={index === activeDot}
          key={index}
          onClick={() => onClickDot(index)}
        />
      ))}
    </Wrapper>
  );
};

/**
 * Export `GalleryNavigation` component.
 */

export default GalleryNavigation;
