/**
 * Module dependencies.
 */

import {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { color, media, units, useBreakpoint } from '@untile/react-components';
import { prop, theme } from 'styled-tools';
import { theme as stylesTheme } from 'src/styles/theme';
import ArrowNavigation from './arrow-navigation';
import SwiperCore, {
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  SwiperOptions
} from 'swiper';

import merge from 'lodash/merge';
import styled, { createGlobalStyle } from 'styled-components';
import swiperStyles from 'swiper/swiper-bundle.css';

/**
 * Load swiper components.
 */

SwiperCore.use([
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar
]);

/**
 * Export `CarouselProps` interface.
 */

export interface CarouselProps extends SwiperOptions {
  activeSlide: number;
  carouselConfig?: any;
  children: ReactNode;
  className?: string;
  onSetActiveSlide: Dispatch<SetStateAction<number>>;
  ref?: any;
  showArrows?: boolean;
  showScrollbar?: boolean;
  totalItems?: number;
}

/**
 * `GlobalStyle` component.
 */

const GlobalStyle = createGlobalStyle`
  ${swiperStyles}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    height: auto;
    width: 100%;
  }

  .swiper-container {
    height: 100%;
    margin: 0;
    padding-left: ${theme('grid.gutterMobile')}px;

    ${media.min('md')`
      padding-left: calc(100% / 12);
    `}
  }

  .swiper-slide {
    height: auto;
  }
`;

/**
 * `CarouselArrowNavigation` styled component.
 */

const CarouselArrowNavigation = styled(ArrowNavigation)`
  margin: 0 auto;

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `ScrollbarWrapper` styled component.
 */

const ScrollbarWrapper = styled.div`
  margin-left: ${theme('grid.gutterMobile')}px;
  overflow: hidden;
  padding-top: ${units(3)};
  position: relative;
  width: calc(100% - ${units(5)});

  ${media.min('md')`
    margin-left: calc(100% / 12);
    padding-top: 0;
    width: calc((100% / 12) * 10);
  `}
`;

/**
 * `CarouselScrollbar` styled component.
 */

const CarouselScrollbar = styled.div<{ modifierClass: string }>`
  padding: ${units(1)} 0;

  .${prop('modifierClass')}scrollbar {
    background-color: ${color('grey200')};
    height: 1px;
  }

  .${prop('modifierClass')}scrollbar-drag {
    background: ${color('grey800')};
    height: 1px;
  }
`;

/**
 * Default carousel config.
 */

const defaultCarouselConfig = ({ isMobile, totalItems, windowWidth }) => {
  return {
    slidesOffsetAfter:
      totalItems > 1 &&
      (isMobile ? stylesTheme.grid.gutterMobile : windowWidth / 12),
    slidesPerView: 1.15,
    spaceBetween: 28
  };
};

/**
 * Export `Slide` component.
 */

export const Slide = SwiperSlide;

/**
 * `Carousel` component.
 */

const Carousel: FC<CarouselProps> = forwardRef<any, CarouselProps>(
  (props: CarouselProps, ref: any): ReactElement => {
    const {
      activeSlide,
      carouselConfig,
      children,
      className,
      containerModifierClass,
      onSetActiveSlide,
      showArrows = true,
      showScrollbar = true,
      speed,
      totalItems,
      ...rest
    } = props;

    const [windowWidth, setWindowWidth] = useState<number>();
    const isMobile = useBreakpoint('md', 'max');
    const modifierClassName = containerModifierClass
      ? `${containerModifierClass}-`
      : '';

    const normalizedCarouselConfig = useMemo(() => {
      if (!carouselConfig) {
        return null;
      }

      const defaultConfig = defaultCarouselConfig({
        isMobile,
        totalItems,
        windowWidth
      });

      return merge({}, carouselConfig, defaultConfig);
    }, [carouselConfig, isMobile, totalItems, windowWidth]);

    const handleGoToNext = useCallback(() => {
      onSetActiveSlide(current => current + 1);

      if (ref && ref.current) {
        ref.current.swiper.slideNext();
      }
    }, [onSetActiveSlide, ref]);

    const handleGoToPrevious = useCallback(() => {
      onSetActiveSlide(current => current - 1);

      if (ref && ref.current) {
        ref.current.swiper.slidePrev();
      }
    }, [onSetActiveSlide, ref]);

    const handleSlideChange = useCallback(
      ({ activeIndex }) => {
        onSetActiveSlide(activeIndex);
      },
      [onSetActiveSlide]
    );

    const handleWindowResize = useCallback(() => {
      setWindowWidth(window.innerWidth);

      if (ref && ref.current) {
        ref.current.swiper.update();
      }
    }, [ref]);

    useEffect(() => {
      if (!windowWidth) {
        setWindowWidth(window.innerWidth);
      }
    }, [windowWidth]);

    useEffect(() => {
      window.addEventListener('resize', handleWindowResize);

      return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    return (
      <Wrapper className={className}>
        <GlobalStyle />

        <Swiper
          {...normalizedCarouselConfig}
          onSlideChange={handleSlideChange}
          ref={ref}
          scrollbar={{
            dragClass: `${modifierClassName}scrollbar-drag`,
            el: `.${modifierClassName}scrollbar`,
            hide: false
          }}
          speed={speed ?? 750}
          {...rest}
        >
          {children}
        </Swiper>

        {totalItems > 1 && showArrows && (
          <CarouselArrowNavigation
            activeSlide={activeSlide}
            onGoToNext={handleGoToNext}
            onGoToPrevious={handleGoToPrevious}
            total={totalItems}
          />
        )}

        {totalItems > 1 && showScrollbar && (
          <ScrollbarWrapper>
            <CarouselScrollbar modifierClass={modifierClassName}>
              <div className={`${modifierClassName}scrollbar`} />
            </CarouselScrollbar>
          </ScrollbarWrapper>
        )}
      </Wrapper>
    );
  }
);

/**
 * `Carousel` display name.
 */

Carousel.displayName = 'Carousel';

/**
 * Export `Carousel` component.
 */

export default Carousel;
