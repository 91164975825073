/**
 * Module dependencies.
 */

/**
 * `SvgEye` component.
 */

const SvgEye: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={'M30 24a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z'}
        fill={'currentColor'}
      />

      <path
        clipRule={'evenodd'}
        d={
          'M43.789 23.106C39.472 14.473 31.806 10 24 10c-7.806 0-15.472 4.473-19.789 13.106a2 2 0 0 0 0 1.788C8.528 33.527 16.194 38 24 38c7.806 0 15.472-4.473 19.789-13.106a2 2 0 0 0 0-1.788ZM24 34c-5.938 0-12.004-3.242-15.74-10 3.736-6.758 9.802-10 15.74-10 5.938 0 12.004 3.242 15.74 10-3.736 6.758-9.802 10-15.74 10Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgEye` component.
 */

export default SvgEye;
