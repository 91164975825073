/**
 * Module dependencies.
 */

/**
 * `SvgArrowLeftSmall` component.
 */

const SvgArrowLeftSmall: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M23.847 15.642a.5.5 0 1 1 .698.716L17.228 23.5h15.439a.5.5 0 1 1 0 1H17.228l7.317 7.142a.5.5 0 0 1-.698.716l-8.196-8a.5.5 0 0 1 0-.716l8.196-8Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgArrowLeftSmall` component.
 */

export default SvgArrowLeftSmall;
