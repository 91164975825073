/**
 * Module dependencies.
 */

import { Error } from 'src/types/forms';
import { FC, ReactElement, ReactNode, forwardRef } from 'react';
import {
  FormControl,
  FormGroup,
  HelpText,
  Label,
  Wrapper
} from '../fields-styled-components';

import { color, units } from '@untile/react-components';
import IconButton from '../../buttons/icon-button';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `InputProps` interface.
 */

export interface InputProps {
  as?: any;
  className?: string;
  disabled?: boolean;
  error?: Error | null | undefined;
  helpText?: string;
  icon?: string | ReactNode;
  iconLabel?: string;
  id?: string;
  isRequired?: boolean;
  label?: string;
  name: string;
  onChange?: ({ target }) => void;
  onClickIcon?: () => void;
  placeholder?: string;
  ref?: any;
  type?: string;
  unit?: string;
  value?: string | number;
}

/**
 * `Unit` styled component.
 */

const Unit = styled.div`
  color: ${color('grey400')};
  font-size: 14px;
  line-height: 24px;
  padding: 0 ${units(1)};
  text-align: right;
`;

/**
 * `Input` component.
 */

const Input: FC<InputProps> = forwardRef<any, InputProps>(
  (props: InputProps, ref: any): ReactElement => {
    const {
      className,
      disabled,
      error,
      helpText,
      icon,
      iconLabel,
      id,
      isRequired,
      label,
      name,
      onClickIcon,
      type,
      unit,
      ...rest
    } = props;

    const hasError = !isEmpty(error);

    return (
      <FormGroup
        className={className}
        disabled={disabled}
      >
        {label && (
          <Label
            hasError={hasError}
            htmlFor={id ?? name}
            isDisabled={disabled}
            isRequired={isRequired}
          >
            {label}
          </Label>
        )}

        <Wrapper
          disabled={disabled}
          hasError={hasError}
        >
          <FormControl
            hasIcon={!!icon}
            hasUnit={!!unit}
            id={id ?? name}
            name={name}
            ref={ref}
            type={type ?? 'text'}
            {...rest}
          />

          {!!icon && (
            <IconButton
              aria-label={name}
              disabled={disabled}
              icon={icon}
              iconSize={units(3)}
              onClick={onClickIcon}
              {...(iconLabel && { 'aria-label': iconLabel, id: iconLabel })}
            />
          )}

          {!!unit && <Unit>{unit}</Unit>}
        </Wrapper>

        {hasError && <HelpText hasError>{error?.message}</HelpText>}

        {helpText && !hasError && <HelpText>{helpText}</HelpText>}
      </FormGroup>
    );
  }
);

/**
 * `Input` display name.
 */

Input.displayName = 'Input';

/**
 * Export `Input` component.
 */

export default Input;
