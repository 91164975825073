/**
 * Module dependencies.
 */

import { Limits, Section, SectionsNavigationContext } from './context';
import { ReactNode, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `SectionsNavigationProvider` provider.
 */

const SectionsNavigationProvider = ({ children }: Props) => {
  const [currentSection, setCurrentSection] = useState<string>('overview');
  const [limits, setLimits] = useState<Limits | null>(null);
  const [isVisible, setVisible] = useState<boolean>();
  const [sections, setSections] = useState<Section[]>([]);

  return (
    <SectionsNavigationContext.Provider
      value={{
        currentSection,
        isVisible,
        limits,
        onSetCurrentSection: setCurrentSection,
        onSetLimits: setLimits,
        onSetSections: setSections,
        onSetVisible: setVisible,
        sections
      }}
    >
      {children}
    </SectionsNavigationContext.Provider>
  );
};

/**
 * Export `SectionsNavigationProvider` provider.
 */

export default SectionsNavigationProvider;
