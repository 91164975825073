/**
 * Module dependencies.
 */

import i18nRoutes, { I18nRoutes } from 'i18n-routes';

/**
 * Export `routes`.
 */

export const routes: I18nRoutes = i18nRoutes;

/**
 * Export `authRoutes`.
 */

export const authRoutes = {
  account: {
    expiredLink: '/account/expired-link',
    resendConfirmationEmail: '/account/resend-confirmation-email',
    thankYou: '/account/thank-you'
  },
  checkEmail: '/sign-up/check-email',
  password: {
    expiredLink: '/password/expired-link',
    recoverPassword: '/password/recover',
    resetPassword: '/password/reset',
    thankYou: '/password/thank-you'
  },
  resendConfirmationEmailSignUp: '/sign-up/resend-confirmation-email',
  signIn: '/sign-in',
  signUp: '/sign-up'
};

/**
 * Export `myProductsRoutes`.
 */

export const myProductsRoutes = {
  changePassword: '/my-products/settings/password',
  customizationQuote: '/my-products/customizations/:id/quote',
  customizations: '/my-products/customizations',
  favourites: '/my-products/favourites',
  home: '/my-products',
  settings: '/my-products/settings'
};
