/**
 * Module dependencies.
 */

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ReactElement, ReactNode, useCallback, useReducer } from 'react';
import { SnackbarContext } from './context';
import { media, units } from '@untile/react-components/dist/styles';
import { theme } from 'styled-tools';
import Snackbar, { Options } from 'src/components/core/snackbar';
import get from 'lodash/get';
import map from 'lodash/map';
import reject from 'lodash/reject';
import styled, { createGlobalStyle } from 'styled-components';

/**
 * `State` type.
 */

type State = {
  messages: Array<{
    content: ReactNode;
    id: number;
    options?: Options;
  }>;
  total: number;
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * Action types.
 */

const actionTypes = {
  addMessage: 'ADD_MESSAGE',
  removeMessage: 'REMOVE_MESSAGE'
};

/**
 * Reducer.
 */

function reducer(state: State, { payload, type }) {
  switch (type) {
    case actionTypes.addMessage: {
      const total = state.total + 1;

      return {
        messages: [
          ...state.messages,
          {
            ...get(payload, 'message'),
            id: total
          }
        ],
        total
      };
    }

    case actionTypes.removeMessage:
      return {
        messages: reject(state.messages, payload),
        total: state.total - 1
      };

    default:
      return state;
  }
}

/**
 * `GlobalStyle` component.
 */

const GlobalStyle = createGlobalStyle`
  .snackbar-item-enter {
    opacity: 0;
    transform: translateY(${units(1)});
  }

  .snackbar-item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition-duration: 0.3s;
    transition-property: opacity, transform;
    transition-timing-function: ease-out;
  }

  .snackbar-item-exit {
    opacity: 1;
  }

  .snackbar-item-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  left: 0;
  margin: 0 auto;
  max-width: ${theme('breakpoints.xxl')}px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${theme('zIndex.snackbar')};

  ${media.min('ms')`
    left: ${units(2)};
    max-width: 420px;
    right: ${units(2)};
    top: ${units(2)};
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
`;

/**
 * `SnackbarProvider` provider.
 */

const SnackbarProvider = ({ children }: Props): ReactElement => {
  const [{ messages }, dispatch] = useReducer(reducer, {
    messages: [],
    total: 0
  });

  const showMessage = useCallback((content: ReactNode, options: Options) => {
    dispatch({
      payload: {
        message: { content, options }
      },
      type: actionTypes.addMessage
    });
  }, []);

  const removeMessage = useCallback((id: number) => {
    dispatch({
      payload: { id },
      type: actionTypes.removeMessage
    });
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        removeMessage,
        showMessage
      }}
    >
      {children}

      <GlobalStyle />

      <Wrapper>
        <Content>
          <TransitionGroup>
            {map(messages, ({ content, id, options }) => (
              <CSSTransition
                classNames={'snackbar-item'}
                key={id}
                timeout={255}
              >
                <Snackbar
                  id={id}
                  onDismiss={removeMessage}
                  options={options}
                >
                  {content}
                </Snackbar>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </Content>
      </Wrapper>
    </SnackbarContext.Provider>
  );
};

/**
 * Export `SnackbarProvider` provider.
 */

export default SnackbarProvider;
