/**
 * Module dependencies.
 */

import { ReactElement, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `GoogleTagManager` component.
 */

const GoogleTagManager = ({ children }: Props): ReactElement => {
  const router = useRouter();

  useEffect(() => {
    if (!window.dataLayer) {
      return;
    }

    const handlePageView = url => {
      window.dataLayer?.push({
        event: 'pageview',
        page: url
      });
    };

    router.events.on('routeChangeComplete', handlePageView);

    return () => {
      router.events.off('routeChangeComplete', handlePageView);
    };
  }, [router]);

  return <>{children}</>;
};

/**
 * Export `GoogleTagManager` component.
 */

export default GoogleTagManager;
