/**
 * Module dependencies.
 */

/**
 * `SvgArrowRightSmall` component.
 */

const SvgArrowRightSmall: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M24.82 15.642a.5.5 0 1 0-.699.716L31.44 23.5H16a.5.5 0 0 0 0 1h15.439l-7.318 7.142a.5.5 0 0 0 .699.716l8.196-8a.5.5 0 0 0 0-.716l-8.196-8Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgArrowRightSmall` component.
 */

export default SvgArrowRightSmall;
