/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { RouterLink } from 'src/components/core';
import { color, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import map from 'lodash/map';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
};

/**
 * `List` styled component.
 */

const List = styled.ul`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  display: inline-block;
  transition: opacity ${theme('animations.defaultTransition')};

  &:not(:last-child) {
    margin-right: ${units(1)};
  }
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(RouterLink)<{ active: boolean }>`
  ${theme('typography.styles.small')}

  color: ${color.transparentize('grey800', 0.6)};
  cursor: pointer;
  text-transform: uppercase;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('grey800')};
  }

  ${ifProp(
    'active',
    css`
      color: ${color('grey800')};
      cursor: default;
      pointer-events: none;
    `
  )}
`;

/**
 * `LanguageSwitcher` component.
 */

const LanguageSwitcher = ({ className }: Props): ReactElement => {
  const { locale, locales } = useRouter();
  const routeResolver = useLocalizedRoute();

  return (
    <List className={className}>
      {locales.length > 1 &&
        map(locales, language => (
          <ListItem key={language}>
            <ListItemLink
              active={language === locale}
              href={routeResolver('home')}
              locale={language}
            >
              {language}
            </ListItemLink>
          </ListItem>
        ))}
    </List>
  );
};

/**
 * Export `LanguageSwitcher` component.
 */

export default LanguageSwitcher;
