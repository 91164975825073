/**
 * Module dependencies.
 */

import {
  Context,
  Dispatch,
  SetStateAction,
  createContext,
  useContext
} from 'react';

/**
 * Export `Section` type.
 */

export type Section = {
  height: number;
  label?: string;
  name?: string;
  yPosition: number;
};

/**
 * Export `Limits` type.
 */

export type Limits = {
  end: number;
  start: number;
};

/**
 * `Props` type.
 */

type Props = {
  currentSection: string;
  isVisible: boolean;
  limits: Limits;
  onSetCurrentSection: Dispatch<SetStateAction<string>>;
  onSetLimits: Dispatch<SetStateAction<Limits>>;
  onSetSections: Dispatch<SetStateAction<Section[]>>;
  onSetVisible: Dispatch<SetStateAction<boolean>>;
  sections: Section[];
};

/**
 * Export `SectionsNavigationContext` context.
 */

export const SectionsNavigationContext: Context<Props> = createContext(
  {} as Props
);

/**
 * Export `useSectionsNavigation` hook.
 */

export const useSectionsNavigation = () =>
  useContext(SectionsNavigationContext);
