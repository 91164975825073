/**
 * Module dependencies.
 */

import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react';
import { color, units } from '@untile/react-components';
import { theme } from 'styled-tools';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'>;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.label`
  display: inline-block;
  height: ${units(3)};
  position: relative;
  width: ${units(7)};

  > input {
    display: none;
  }

  > span {
    background-color: ${color('grey200')};
    border-radius: ${units(4)};
    cursor: pointer;
    inset: 0;
    position: absolute;
    transition: background-color ${theme('animations.defaultTransition')};
  }

  > span::before {
    aspect-ratio: 1;
    background-color: ${color('white')};
    border-radius: 50%;
    bottom: 2px;
    content: '';
    left: 2px;
    position: absolute;
    top: 2px;
    transition: transform ${theme('animations.defaultTransition')};
  }

  > input:checked + span {
    background-color: ${color('blue400')};
  }

  > input:checked + span::before {
    transform: translateX(${units(4)});
  }
`;

/**
 * `ToggleComponent` component.
 */

const ToggleComponent = (props: Props, forwardedRef: ForwardedRef<any>) => (
  <Wrapper onClick={event => event.stopPropagation()}>
    <input
      {...props}
      ref={forwardedRef}
      type={'checkbox'}
    />

    <span />
  </Wrapper>
);

/**
 * Export `SimpleToggle`.
 */

export const SimpleToggle = forwardRef<any, Props>(ToggleComponent);
