/**
 * Module dependencies.
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * `Result` Props.
 */

type Result = [boolean, Dispatch<SetStateAction<boolean>>];

/**
 * `Options` type.
 */

type Options = {
  initialState: boolean;
};

/**
 * `useDetectOutsideClick` hook.
 */

function useDetectOutsideClick(ref, { initialState }: Options): Result {
  const router = useRouter();
  const [active, setActive] = useState<boolean>(initialState);

  useEffect(() => {
    const onClick = ({ target }) => {
      if (ref.current !== null && !ref.current.contains(target)) {
        setActive(false);
      }
    };

    if (active) {
      window.addEventListener('click', onClick);
      router.events.on('routeChangeStart', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
      router.events.off('routeChangeStart', onClick);
    };
  }, [active, ref, router.events]);

  return [active, setActive];
}

/**
 * Export `useDetectOutsideClick` hook.
 */

export default useDetectOutsideClick;
