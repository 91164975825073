/**
 * Module dependencies.
 */

import { Fill, color, media, units } from '@untile/react-components';
import {
  IconButton,
  ModalPortal,
  RouterLink,
  Svg,
  icons
} from 'src/components/core';

import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { authRoutes } from 'src/core/routes';
import { ifProp, theme } from 'styled-tools';
import { revokeToken } from 'src/api/auth/revoke-token';
import { useAuth } from 'src/context/auth/context';
import { useClientAreaLinks } from 'src/core/content-config/navbar';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { useSnackbar } from 'src/context/snackbar/context';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
  logo: ReactNode;
  logoSize: string;
  onClose: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{ visible: boolean }>`
  background-color: ${color('backgrounds.sage02')};
  outline: none;
  overflow: auto;
  padding-bottom: ${units(19)};
  position: fixed !important;
  transition: visibility ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.modal')};

  ${ifProp(
    'visible',
    css`
      animation: ${theme('keyframes.fadeIn')}
        ${theme('animations.defaultTransition')} both;
      visibility: visible;
    `,
    css`
      animation: ${theme('keyframes.fadeOut')}
        ${theme('animations.defaultTransition')} both;
      pointer-events: none;
      visibility: hidden;
    `
  )}

  ${media.min('md')`
    padding-bottom: ${units(18)};
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas:
    '. logo .    closeButton .'
    '. menu menu menu        .';
  grid-template-columns: ${theme('grid.gutterMobile')}px 134px 1fr ${units(3)} ${theme(
      'grid.gutterMobile'
    )}px;
  grid-template-rows: ${theme('dimensions.navbarHeightMobile')}px 1fr;
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  align-self: center;
  grid-area: logo;
  height: ${units(6)};
  justify-self: start;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.7;
  }
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton).attrs({
  icon: icons.close,
  iconSize: units(3)
})`
  grid-area: closeButton;
  justify-self: end;
  line-height: 0;
  padding: 0;
`;

/**
 * `MenuWrapper` styled component.
 */

const MenuWrapper = styled.div`
  display: grid;
  grid-area: menu;
  grid-auto-columns: 100%;
  grid-row-gap: ${units(2)};
  padding-top: ${units(4)};
`;

/**
 * `StyledRouterLink` styled component.
 */

const StyledRouterLink = styled(RouterLink)`
  ${theme('typography.otherStyles.subtitle2')}

  color: ${color('grey800')};
  font-weight: 700;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`;

/**
 * `StyledSvg` styled component.
 */

const LogoutSvg = styled(Svg).attrs({
  icon: icons.logout,
  size: units(3)
})`
  color: ${color('black')};
`;

/**
 * `LogoutButton` styled component.
 */

const LogoutButton = styled.button`
  ${theme('typography.otherStyles.subtitle2')}

  align-items: center;
  background: none;
  border: 0;
  border-radius: 0;
  color: ${color('grey800')};
  cursor: pointer;
  display: grid;
  grid-column-gap: ${units(2)};
  grid-template-columns: max-content 1fr;
  outline: none;
  padding: 0;
  text-align: left;
`;

/**
 * `MyProductsMobileMenu` component.
 */

const MyProductsMobileMenu = (props: Props): ReactElement => {
  const clientAreaLinks = useClientAreaLinks();
  const router = useRouter();
  const routeResolver = useLocalizedRoute();
  const { isOpen, logo, logoSize, onClose } = props;
  const { locale, translate } = useTranslate();
  const { onClearToken, token } = useAuth();
  const { showMessage } = useSnackbar();
  const { mutate } = useMutation(revokeToken);
  const handleLogout = useCallback(() => {
    mutate(
      { token },
      {
        onSuccess: () => {
          onClose();
          onClearToken();
          showMessage(translate('common:logout.successMessage'), {
            appearance: 'success'
          });

          router.push(authRoutes.signIn);
        }
      }
    );
  }, [mutate, onClearToken, onClose, router, showMessage, token, translate]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        onClose();
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [isOpen, onClose, router.events]);

  return (
    <ModalPortal isOpen={isOpen}>
      <Wrapper
        aria-hidden={!isOpen}
        aria-modal
        role={'dialog'}
        tabIndex={isOpen ? -1 : undefined}
        visible={isOpen}
      >
        <Grid>
          <LogoLink
            href={routeResolver('home')}
            locale={locale}
          >
            <Svg
              icon={logo}
              size={logoSize}
            />
          </LogoLink>

          <CloseButton onClick={onClose} />

          <MenuWrapper>
            {clientAreaLinks.map(({ href, label }, index) => (
              <StyledRouterLink
                href={href}
                key={index}
                locale={locale}
              >
                {translate(label)}
              </StyledRouterLink>
            ))}

            <LogoutButton onClick={handleLogout}>
              <LogoutSvg />

              {translate('common:actions.logout')}
            </LogoutButton>
          </MenuWrapper>
        </Grid>
      </Wrapper>
    </ModalPortal>
  );
};

/**
 * Export `MyProductsMobileMenu` component.
 */

export default MyProductsMobileMenu;
