/**
 * Module dependencies.
 */

import findKey from 'lodash/findKey';

/**
 * `ObjectType` type.
 */

type ObjectType = {
  [key: string]: any;
};

/**
 * Export `getKeyFromObject`.
 */

export function getKeyFromObject(object: ObjectType, selectedValue: any) {
  return findKey(object, value => value === selectedValue);
}
