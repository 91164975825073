/**
 * Module dependencies.
 */

import { GetServerSidePropsContext } from 'next';
import { authRoutes } from 'src/core/routes';
import { destroyCookie, parseCookies } from 'nookies';
import { fetchUser } from 'src/api/user/fetch-user';
import isEmpty from 'lodash/isEmpty';

/**
 * Export `serverAuth`.
 */

export async function serverAuth(ctx) {
  // Get cookies.
  const cookies = parseCookies(ctx);

  // Get token.
  let token = cookies?.token;

  // Get user.
  const userData = await fetchUser({ ssr: true, token });

  if (!userData) {
    destroyCookie(ctx, 'token', { path: '/' });
    token = null;
  }

  return {
    isAuthenticated: !isEmpty(userData),
    token,
    user: userData
  };
}

/**
 * Export `withSession`
 */

export function withSession() {
  return async (ctx: GetServerSidePropsContext) => {
    const { isAuthenticated } = await serverAuth(ctx);

    if (!isAuthenticated) {
      return {
        redirect: {
          destination: authRoutes.signIn,
          permanent: false
        }
      };
    }

    return {
      props: {}
    };
  };
}
