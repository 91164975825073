/**
 * Module dependencies.
 */

import { Market } from 'src/types/markets';
import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  locale: string;
};

/**
 * Export `fetchMarkets`.
 */

export async function fetchMarkets({ locale }: Query): Promise<Market[]> {
  const endpoint = getApiEndpoint('getMarkets');

  try {
    const { data } = await request.get(endpoint, {
      headers: acceptLanguageHeader(locale)
    });

    return data?.data;
  } catch (error) {
    handleRequestError(error);
  }
}
