/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { color, units } from '@untile/react-components';
import { icons } from '../icons';
import { ifProp, theme } from 'styled-tools';
import IconButton, { IconButtonProps } from '../buttons/icon-button';
import styled, { css } from 'styled-components';

/**
 * Export `ArrowNavigationProps` interface.
 */

export interface ArrowNavigationProps {
  activeSlide: number;
  className?: string;
  onGoToNext: () => void;
  onGoToPrevious: () => void;
  total: number;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ total: number }>`
  display: flex;
  width: max-content;
`;

/**
 * `ArrowButton` styled component.
 */

const ArrowButton = styled(IconButton)<
  IconButtonProps & { isDisabled: boolean }
>`
  color: ${color('black')};
  padding: 0;
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;

  ${ifProp(
    'isDisabled',
    css`
      color: ${color.transparentize('black', 0.1)};
      cursor: default;
      pointer-events: none;
    `
  )}

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

/**
 * `ArrowNavigation` component.
 */

const ArrowNavigation = (props: ArrowNavigationProps): ReactElement => {
  const { activeSlide, className, onGoToNext, onGoToPrevious, total } = props;

  return (
    <Wrapper
      className={className}
      total={total}
    >
      <ArrowButton
        icon={icons.arrowLeftSmall}
        iconSize={units(6)}
        isDisabled={activeSlide === 0}
        onClick={onGoToPrevious}
      />

      <ArrowButton
        icon={icons.arrowRightSmall}
        iconSize={units(6)}
        isDisabled={activeSlide === total - 1}
        onClick={onGoToNext}
      />
    </Wrapper>
  );
};

/**
 * Export `ArrowNavigation` component.
 */

export default ArrowNavigation;
