/**
 * Module dependencies.
 */

/**
 * `SvgCircleInformation` component.
 */

const SvgCircleInformation: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M24 8C15.163 8 8 15.163 8 24s7.163 16 16 16 16-7.163 16-16S32.837 8 24 8ZM4 24C4 12.954 12.954 4 24 4s20 8.954 20 20-8.954 20-20 20S4 35.046 4 24Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />

      <path
        clipRule={'evenodd'}
        d={'M24 20a2 2 0 0 1 2 2v12a2 2 0 1 1-4 0V22a2 2 0 0 1 2-2Z'}
        fill={'currentColor'}
        fillRule={'evenodd'}
      />

      <path
        d={'M27 15a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'}
        fill={'currentColor'}
      />
    </svg>
  );
};

/**
 * Export `SvgCircleInformation` component.
 */

export default SvgCircleInformation;
