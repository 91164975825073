/**
 * Module dependencies.
 */

/**
 * `SvgEyeOff` component.
 */

const SvgEyeOff: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M9.414 6.586a2 2 0 1 0-2.828 2.828l4.848 4.848c-2.861 2.153-5.355 5.109-7.223 8.844a2 2 0 0 0 0 1.788C8.528 33.527 16.194 38 24 38c3.11 0 6.198-.71 9.061-2.11l5.525 5.524a2 2 0 1 0 2.828-2.828l-32-32Zm20.614 26.27-3.441-3.44a6 6 0 0 1-8.002-8.002l-4.288-4.289C11.976 18.76 9.894 21.043 8.259 24 11.996 30.758 18.062 34 24 34c2.027 0 4.069-.378 6.027-1.144Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />

      <path
        d={
          'M37.105 27.791A21.927 21.927 0 0 0 39.74 24C36.004 17.242 29.938 14 24 14c-.224 0-.448.005-.672.014l-3.57-3.569A20.425 20.425 0 0 1 24 10c7.806 0 15.472 4.473 19.789 13.106a2 2 0 0 1 0 1.788c-1.088 2.176-2.389 4.088-3.851 5.73l-2.833-2.833Z'
        }
        fill={'currentColor'}
      />
    </svg>
  );
};

/**
 * Export `SvgEyeOff` component.
 */

export default SvgEyeOff;
