/**
 * Module dependencies.
 */

/**
 * `SvgPlay` component.
 */

const SvgPlay: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M12 13.482c0-3.087 3.349-5.01 6.015-3.455l18.031 10.518c2.646 1.543 2.646 5.367 0 6.91l-18.03 10.518C15.348 39.53 12 37.605 12 34.518V13.482ZM34.03 24 16 13.482v21.036L34.03 24Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgPlay` component.
 */

export default SvgPlay;
