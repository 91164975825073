/**
 * Module dependencies.
 */

import { FC, ReactElement, ReactNode } from 'react';
import { color, units } from '@untile/react-components/dist/styles';
import { icons } from '../icons';
import { ifProp, switchProp, theme } from 'styled-tools';
import IconButton from '../buttons/icon-button';
import Svg from '../svg';
import styled, { css } from 'styled-components';

/**
 * Export `MessageAppearance` type.
 */

export type MessageAppearance = 'error' | 'info' | 'success';

/**
 * `MessageProps` type.
 */

export interface MessageProps {
  appearance?: MessageAppearance;
  children: ReactNode;
  className?: string;
  hasCloseButton?: boolean;
  hasIcon?: boolean;
  onClose?: (event: any) => void;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  appearance: MessageAppearance;
  hasCloseButton?: boolean;
  hasIcon?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}>`
  ${theme('typography.styles.p')}

  align-items: start;
  background-color: ${color('white')};
  box-shadow: 0 ${units(1.5)} ${units(4)} ${color.transparentize('black', 0.15)};
  display: grid;
  grid-column-gap: ${units(2)};
  grid-template-columns: 1fr ${units(3)};
  padding: ${units(3)} ${units(2)};
  position: relative;

  ${ifProp(
    'hasIcon',
    css`
      grid-template-columns: ${units(3)} 1fr;
    `
  )}

  ${ifProp(
    ['hasCloseButton', 'hasIcon'],
    css`
      grid-template-columns: ${units(3)} 1fr ${units(3)};
    `
  )}

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
    `
  )}

  ${switchProp('appearance', {
    error: css`
      color: ${color('red600')};
    `,
    info: css`
      color: ${color('blue600')};
    `,
    success: css`
      color: ${color('green600')};
    `
  })}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton)<{ appearance: MessageAppearance }>`
  height: ${units(3)};
  padding: 0;

  ${switchProp('appearance', {
    error: css`
      color: ${color('red600')};
    `,
    info: css`
      color: ${color('blue600')};
    `,
    success: css`
      color: ${color('green600')};
    `
  })}
`;

/**
 * `Message` component.
 */

const Message: FC<MessageProps> = (props: MessageProps): ReactElement => {
  const {
    appearance,
    children,
    className,
    hasCloseButton = true,
    hasIcon,
    onClose
  } = props;

  return (
    <Wrapper
      appearance={appearance}
      className={className}
      hasCloseButton={hasCloseButton}
      hasIcon={hasIcon}
      onClick={onClose}
    >
      {hasIcon && <Svg
        icon={icons.circleInformation}
        size={units(3)}
      />}

      {children}

      {hasCloseButton && (
        <CloseButton
          appearance={appearance}
          aria-label={'close'}
          icon={icons.close}
          iconSize={units(3)}
        />
      )}
    </Wrapper>
  );
};

/**
 * `Message` default props.
 */

Message.defaultProps = {
  appearance: 'info',
  hasIcon: true
};

/**
 * Export `Message` component.
 */

export default Message;
