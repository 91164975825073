/**
 * Module dependencies.
 */

import NextLink from 'next/link';

/**
 * Export `RouterLink` component.
 */

export default NextLink;
