/**
 * Module dependencies.
 */

/**
 * `SvgDownload` component.
 */

const SvgDownload: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M24 4a2 2 0 0 1 2 2v21.172l4.586-4.586a2 2 0 1 1 2.828 2.828l-8 8a2 2 0 0 1-2.828 0l-8-8a2 2 0 1 1 2.828-2.828L22 27.172V6a2 2 0 0 1 2-2ZM10 34a2 2 0 0 1 2 2v4h24v-4a2 2 0 1 1 4 0v4a4 4 0 0 1-4 4H12a4 4 0 0 1-4-4v-4a2 2 0 0 1 2-2Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgDownload` component.
 */

export default SvgDownload;
