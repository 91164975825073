/**
 * Module dependencies.
 */

import { CustomizationProps } from 'src/types/my-products';
import { Token } from 'src/core/utils/requests';
import { fetchCustomizations, paginationLimit } from './fetch-customizations';
import { useInfiniteQuery } from 'react-query';

/**
 * Export `InitialData` type.
 */

export type InitialData = {
  pageParams: number[];
  pages: Array<{
    data: CustomizationProps[];
    nextPage: number | null;
    total: number;
  }>;
};

/**
 * `Options` type.
 */

type Options = {
  enabled?: boolean;
  initialData?: InitialData | null;
  limit?: number;
  token: Token;
};

/**
 * Action type.
 */

const actionType = 'customizations';

/**
 * `useCustomizations` hook.
 */

function useCustomizations(options?: Options) {
  const { initialData, limit, token } = options;
  const result = useInfiniteQuery(
    actionType,
    ({ pageParam = 1 }) =>
      fetchCustomizations({
        params: {
          limit: limit ?? paginationLimit,
          page: pageParam
        },
        token
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const pageLimit = Math.ceil(allPages[0]?.total / paginationLimit);
        const nextPage = lastPage?.nextPage;

        return nextPage <= pageLimit ? nextPage : false;
      },
      initialData
    }
  );

  return result;
}

/**
 * Export `useCustomizations` hook.
 */

export default useCustomizations;
