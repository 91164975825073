/**
 * Module dependencies.
 */

import { myProductsRoutes } from '../routes';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMemo } from 'react';

/**
 * Export `NavbarLink` type.
 */

export type NavbarLink = {
  disabled?: boolean;
  href?: string | null;
  label: string;
};

/**
 * Export `useNavbarLinks`.
 */

export function useNavbarLinks(): NavbarLink[] {
  const routeResolver = useLocalizedRoute();
  const navbarLinks = useMemo(() => {
    return [
      {
        href: routeResolver('markets'),
        label: 'menu:labels.markets'
      },
      {
        href: routeResolver('contactUs'),
        label: 'menu:contactUs'
      },
      {
        href: 'https://www.eservice-amtrol-alfa.com/',
        label: 'menu:clientArea.label'
      }
    ];
  }, [routeResolver]);

  return navbarLinks;
}

/**
 * Export `useClientAreaLinks`.
 */

export function useClientAreaLinks(): NavbarLink[] {
  const clientAreaLinks = useMemo(() => {
    return [
      {
        href: myProductsRoutes.home,
        label: 'menu:clientArea.dropdown.dashboard'
      },
      {
        href: myProductsRoutes.customizations,
        label: 'menu:clientArea.dropdown.3dCustomizations'
      },
      {
        href: myProductsRoutes.favourites,
        label: 'menu:clientArea.dropdown.favourites'
      },
      {
        href: myProductsRoutes.settings,
        label: 'menu:clientArea.dropdown.settings'
      }
    ];
  }, []);

  return clientAreaLinks;
}
