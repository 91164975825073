/**
 * Module dependencies.
 */

/**
 * `SvgAmtrolAlfaLogo` component.
 */

const SvgAmtrolAlfaLogo: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 217 40'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={
          'M26.771 4.74 23.925 0 0 40l5.554-.01L26.77 4.74ZM27.634 39.946l.002-8.365-7.447.007 2.528-4.178 12.811.036L26.85 12.94 10.294 39.978l17.34-.032Z'
        }
        fill={'#B1132F'}
      />

      <path
        d={'m38.069 31.61-6.326-.014-.016 8.34 11.306-.022-4.965-8.304Z'}
        fill={'#B1132F'}
      />

      <path
        clipRule={'evenodd'}
        d={
          'm42.322 31.37 11.87-19.503L65.314 31.37h-3.52l-3.879-6.923h-8.043L45.8 31.369h-3.477Zm11.87-14.087-2.898 4.74h5.429l-2.532-4.74ZM189.371 31.37l11.877-19.503L212.36 31.37h-3.53l-3.871-6.923h-8.034l-4.077 6.923h-3.477Zm11.877-14.087-2.902 4.74h5.433l-2.531-4.74Z'
        }
        fill={'#1A1A1A'}
        fillRule={'evenodd'}
      />

      <path
        d={
          'm77.382 25.89-9.056-14.01-2.218 19.477h3.073l1.048-10.746h.055l7.098 10.746 7.097-10.746h.058l1.078 10.746h2.983L86.433 11.88l-9.051 14.01ZM92.712 14.694h-4.738l-.296-2.67 13.13-.014v2.684h-5.071l.032 16.668H92.7l.013-16.668ZM192.118 18.762h-4.277v-3.695l7.007.005 1.201-2.811h-11.223v19.101h3.015v-10.19h4.277v-2.41ZM143.719 22.244h6.891l1.156-2.413-8.047.006v2.407ZM172.268 12.684l3.034-.41V28.56l8.052-.005-1.159 2.816h-9.902l-.025-18.688ZM138.763 12.273l-3.036.411.014 18.688h9.902l1.158-2.816-8.038.005V12.273Z'
        }
        fill={'#1A1A1A'}
      />

      <path
        clipRule={'evenodd'}
        d={
          'M160.498 11.867 148.623 31.37h3.477l4.093-6.93h8.02l3.89 6.93h3.525l-11.13-19.502Zm-2.893 10.156 2.893-4.74 2.549 4.74h-5.442ZM214.805 29.217c.288 0 .549.084.549.416a.44.44 0 0 1-.122.317.379.379 0 0 1-.304.115l.401.717h-.257l-.418-.716h-.282v.716h-.232v-1.565h.665Zm-.422.62h.275c.214.001.476.002.476-.184 0-.2-.164-.214-.359-.214h-.392v.398Z'
        }
        fill={'#1A1A1A'}
        fillRule={'evenodd'}
      />

      <path
        clipRule={'evenodd'}
        d={
          'M214.685 28.593c-.256.02-.501.119-.704.284-.203.165-.357.39-.442.647a1.482 1.482 0 0 0-.035.798c.062.264.195.503.382.688.188.185.423.308.676.353.252.046.512.012.747-.097s.435-.288.575-.516c.14-.227.215-.493.214-.765a1.501 1.501 0 0 0-.122-.55 1.426 1.426 0 0 0-.312-.458 1.337 1.337 0 0 0-.454-.296 1.286 1.286 0 0 0-.525-.088Zm0 2.562a1.069 1.069 0 0 1-.592-.237 1.169 1.169 0 0 1-.372-.542 1.232 1.232 0 0 1 .289-1.25 1.08 1.08 0 0 1 .566-.297c.213-.039.431-.01.628.08.198.091.366.242.484.433.118.19.181.414.181.643a1.28 1.28 0 0 1-.102.461 1.208 1.208 0 0 1-.261.385c-.11.109-.239.193-.38.249a1.084 1.084 0 0 1-.441.075ZM114.421 21.617c0-5.65 4.844-9.745 10.004-9.745 5.159 0 10.004 4.103 10.004 9.745 0 5.642-4.845 9.745-10.004 9.745-5.16 0-10.004-4.095-10.004-9.745Zm2.837 0c0 4.024 3.356 7.116 7.167 7.116 3.817 0 7.167-3.094 7.167-7.116 0-4.021-3.357-7.12-7.167-7.12-3.811 0-7.167 3.096-7.167 7.12Z'
        }
        fill={'#1A1A1A'}
        fillRule={'evenodd'}
      />

      <path
        clipRule={'evenodd'}
        d={
          'M108.677 22.86c2.919-.475 4.618-2.603 4.618-5.26 0-3.12-2.17-5.615-6.898-5.615h-4.261l-.018 19.384h2.985l.063-8.588h.048l6.28 8.588h3.889l-6.706-8.51Zm-3.52-1.946v-6.467h1.566c1.98 0 3.66 1.176 3.66 3.237 0 1.887-1.453 3.23-3.639 3.23h-1.587Z'
        }
        fill={'#1A1A1A'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgAmtrolAlfaLogo` component.
 */

export default SvgAmtrolAlfaLogo;
