/**
 * Module dependencies.
 */

import { Collapse, color, units } from '@untile/react-components';
import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { Type } from '../typography';
import { icons } from '../icons';
import { ifProp, theme } from 'styled-tools';
import Svg, { SvgProps } from '../svg';
import styled, { css } from 'styled-components';

/**
 * Export `DisclosureProps` interface.
 */

export interface DisclosureProps {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  header?: ReactNode;
  isAdvisorDisclosure?: boolean;
  isOpen: boolean;
  onClickOpen?: void;
  reverse?: boolean;
  scrollOnOpen?: boolean;
  scrollOnOpenPosition?: 'start' | 'center' | 'end' | 'nearest';
  title?: string;
  toggle: () => void;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<
  Pick<DisclosureProps, 'disabled' | 'isAdvisorDisclosure'>
>`
  border-bottom: 1px solid ${color('grey50')};
  display: flex;
  flex-direction: ${ifProp('isAdvisorDisclosure', 'column-reverse', 'column')};
  position: relative;

  ${ifProp(
    'disabled',
    css`
      opacity: 0.5;
      pointer-events: none;
    `
  )}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div<
  Pick<DisclosureProps, 'disabled' | 'reverse'> & {
    hasBorder: boolean;
    type: string;
  }
>`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: grid;
  grid-column-gap: ${units(2)};
  grid-template-areas: 'icon title';
  grid-template-columns: max-content 1fr;
  padding: ${units(3)} 0;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  ${ifProp(
    'hasBorder',
    css`
      border-bottom: 1px solid ${color('grey800')};
      grid-column-gap: ${units(1)};
      padding: 0;
      width: max-content;
    `
  )}

  ${ifProp(
    'disabled',
    css`
      cursor: default;
    `
  )}

  ${ifProp(
    'reverse',
    css`
      grid-template-areas: 'title icon';
    `
  )}
`;

/**
 * `ArrowSvg` styled component.
 */

const ArrowSvg = styled(Svg).attrs({ size: units(2) })<
  SvgProps & {
    isAdvisorDisclosure?: boolean;
    isOpen: boolean;
  }
>`
  grid-area: icon;
  transition: transform ${theme('animations.defaultTransition')};

  ${ifProp(
    'isOpen',
    css`
      transform: rotate(${ifProp('isAdvisorDisclosure', 180, 90)}deg);
    `
  )}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Paragraph).attrs({ as: 'span' })`
  grid-area: title;
`;

/**
 * `StyledCollapse` styled component.
 */

const StyledCollapse = styled(Collapse)`
  position: relative;
`;

/**
 * `Disclosure` component.
 */

const Disclosure = (props: DisclosureProps): ReactElement => {
  const {
    children,
    disabled,
    header,
    isAdvisorDisclosure,
    isOpen,
    reverse,
    scrollOnOpen,
    scrollOnOpenPosition,
    title,
    toggle,
    ...rest
  } = props;

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (disabled && isOpen) {
      toggle();
    }
  }, [disabled, isOpen, toggle]);

  useEffect(() => {
    if (!scrollOnOpen || !isOpen) {
      return;
    }

    const timeout = setTimeout(() => {
      if (ref.current && ref.current.scrollIntoView) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: scrollOnOpenPosition
        });
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [isOpen, scrollOnOpen, scrollOnOpenPosition]);

  return (
    <Wrapper
      disabled={disabled}
      isAdvisorDisclosure={isAdvisorDisclosure}
      ref={ref}
      {...rest}
    >
      <Header
        aria-expanded={isOpen}
        disabled={disabled}
        hasBorder={isAdvisorDisclosure}
        onClick={toggle}
        reverse={reverse}
        type={'button'}
      >
        <ArrowSvg
          icon={isAdvisorDisclosure ? icons.chevronDown : icons.chevronRight}
          isAdvisorDisclosure
          isOpen={isOpen}
        />

        {header}

        {title && !header && (
          <Title {...(isAdvisorDisclosure && { textTransform: 'uppercase' })}>
            {title}
          </Title>
        )}
      </Header>

      <StyledCollapse
        initialHeight={0}
        isOpen={isOpen}
      >
        {children}
      </StyledCollapse>
    </Wrapper>
  );
};

/**
 * Export `Disclosure` component.
 */

export default Disclosure;
