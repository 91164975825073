/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { RouterLink } from 'src/components/core';
import { Type, color } from '@untile/react-components';
import { theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  i18nKey: string;
  isKiosk?: boolean;
};

/**
 * `TermsConditionsLinkProps` type.
 */

type TermsConditionsLinkProps = {
  children?: string;
  isKiosk?: boolean;
};

/**
 * `StyledRouterLink` styled component.
 */

const StyledRouterLink = styled(RouterLink)`
  ${theme('typography.styles.small')}

  color: ${color('black')};
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  width: max-content;
`;

/**
 * `TermsConditionsLink` component.
 */

const TermsConditionsLink = ({ children }: TermsConditionsLinkProps) => {
  const { locale } = useRouter();
  const routeResolver = useLocalizedRoute();

  return (
    <StyledRouterLink
      href={routeResolver('termsConditions')}
      locale={locale}
    >
      {children}
    </StyledRouterLink>
  );
};

/**
 * `TermsConditionsCheckboxLabel` component.
 */

const TermsConditionsCheckboxLabel = ({ i18nKey }: Props): ReactElement => {
  return (
    <Type.Small>
      <Trans
        components={[<TermsConditionsLink key={'terms-conditions'} />]}
        i18nKey={i18nKey}
      />
    </Type.Small>
  );
};

/**
 * Export `TermsConditionsCheckboxLabel` component.
 */

export default TermsConditionsCheckboxLabel;
