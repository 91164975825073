/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { RouterLink } from 'src/components/core';
import { color, isExternalUrl, media, units } from '@untile/react-components';
import { theme } from 'styled-tools';
import { useMenuFooterLinks } from 'src/core/content-config/menu';
import map from 'lodash/map';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.ul`
  align-items: center;
  display: grid;
  grid-gap: ${units(2)};
  grid-template-columns: repeat(3, max-content);

  ${media.min('ms')`
    grid-gap: ${units(3)};
  `}
`;

/**
 * `ItemLink` styled component.
 */

const ItemLink = styled.a.attrs(({ href }) => ({
  as: (href && !isExternalUrl(href) && RouterLink) || 'a'
}))`
  ${theme('typography.styles.small')}

  color: ${color('textColor')};
  cursor: pointer;
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color.transparentize('textColor', 0.4)};
  }
`;

/**
 * `FooterMenu` component.
 */

const FooterMenu = (): ReactElement => {
  const { locale, translate } = useTranslate();
  const menuFooterLinks = useMenuFooterLinks();

  return (
    <Wrapper>
      {map(menuFooterLinks, ({ href, label }, index) => (
        <li key={index}>
          <ItemLink
            href={href}
            key={index}
            {...(href && !isExternalUrl(href)
              ? { locale }
              : {
                  rel: 'noopener',
                  target: '_blank'
                })}
          >
            {translate(label)}
          </ItemLink>
        </li>
      ))}
    </Wrapper>
  );
};

/**
 * Export `FooterMenu` component.
 */

export default FooterMenu;
