/**
 * Module dependencies.
 */

import { Fill, color, media, units } from '@untile/react-components';
import {
  IconButton,
  ModalPortal,
  RouterLink,
  Svg,
  Type,
  icons
} from 'src/components/core';

import {
  Index,
  InstantSearch,
  connectSearchBox
} from 'react-instantsearch-dom';

import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';

import { client, indexes } from 'src/core/search-config';
import { ifProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import SearchForm from 'src/components/search/search-form';
import SearchResults from './search-results';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
  logo: ReactNode;
  logoSize: string;
  onClose: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{ visible: boolean }>`
  background-color: ${color('backgrounds.sage02')};
  outline: none;
  overflow: auto;
  padding-bottom: ${units(19)};
  position: fixed !important;
  transition: visibility ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.modal')};

  ${ifProp(
    'visible',
    css`
      animation: ${theme('keyframes.fadeIn')}
        ${theme('animations.defaultTransition')} both;
      visibility: visible;
    `,
    css`
      animation: ${theme('keyframes.fadeOut')}
        ${theme('animations.defaultTransition')} both;
      pointer-events: none;
      visibility: hidden;
    `
  )}

  ${media.min('md')`
    padding-bottom: ${units(18)};
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  align-items: center;
  display: grid;
  grid-template-areas:
    '. logo          .             closeButton   .'
    '. title         title         title         .'
    '. searchWrapper searchWrapper searchWrapper .';
  grid-template-columns: ${theme('grid.gutterMobile')}px 134px 1fr ${units(3)} ${theme(
      'grid.gutterMobile'
    )}px;
  grid-template-rows: ${theme('dimensions.navbarHeightMobile')}px max-content 1fr;

  ${media.min('md')`
    grid-template-areas:
      'logo logo          closeButton'
      '.    title         .'
      '.    searchWrapper .'
      '.    searchResults .';
    grid-template-columns: 3fr 6fr 3fr;
    grid-template-rows: ${theme(
      'dimensions.navbarHeight'
    )}px repeat(2, max-content) 1fr;
  `}
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  align-self: center;
  grid-area: logo;
  height: ${units(6)};
  justify-self: start;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.7;
  }

  ${media.min('md')`
    padding-left: ${units(5)};
  `}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton).attrs({
  icon: icons.close,
  iconSize: units(3)
})`
  grid-area: closeButton;
  justify-self: end;
  line-height: 0;
  padding: 0;

  ${media.min('md')`
    align-self: center;
    margin-right: ${units(5)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  grid-area: title;
  margin-bottom: ${units(6)};
  padding-top: ${units(8)};
`;

/**
 * `SearchWrapper` styled component.
 */

const SearchWrapper = styled.div`
  grid-area: searchWrapper;
`;

/**
 * `VirtalSearchBox` component.
 */

const VirtalSearchBox = connectSearchBox(() => null);

/**
 * `Search` component.
 */

const Search = (props: Props): ReactElement => {
  const { isOpen, logo, logoSize, onClose } = props;
  const { locale, translate } = useTranslate();
  const router = useRouter();
  const routeResolver = useLocalizedRoute();
  const [query, setQuery] = useState('');
  const handleSuggestionSelected = useCallback((value: string) => {
    setQuery(value);
  }, []);

  const handleSuggestionClear = useCallback(() => {
    setQuery('');
  }, []);

  const handleClose = useCallback(() => {
    handleSuggestionClear();
    onClose();
  }, [handleSuggestionClear, onClose]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        onClose();
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [isOpen, onClose, router.events]);

  return (
    <ModalPortal isOpen={isOpen}>
      <Wrapper
        aria-hidden={!isOpen}
        aria-modal
        role={'dialog'}
        tabIndex={isOpen ? -1 : undefined}
        visible={isOpen}
      >
        <Grid>
          <LogoLink
            href={routeResolver('home')}
            locale={locale}
          >
            <Svg
              icon={logo}
              size={logoSize}
            />
          </LogoLink>

          <CloseButton onClick={onClose} />

          <Title>{translate('common:search.title')}</Title>

          <SearchWrapper>
            <InstantSearch
              indexName={indexes.modelRanges}
              searchClient={client}
            >
              <SearchForm
                onClose={onClose}
                onSuggestionClear={handleSuggestionClear}
                onSuggestionSelected={handleSuggestionSelected}
              />

              {Object.values(indexes).map((indexName, index) => (
                <Index
                  indexName={indexName}
                  key={index}
                />
              ))}
            </InstantSearch>

            <InstantSearch
              indexName={indexes.modelRanges}
              searchClient={client}
            >
              <VirtalSearchBox defaultRefinement={query} />

              {Object.values(indexes).map((indexName, index) => (
                <Index
                  indexName={indexName}
                  key={index}
                />
              ))}

              <SearchResults
                currentSearch={query}
                onClose={handleClose}
              />
            </InstantSearch>
          </SearchWrapper>
        </Grid>
      </Wrapper>
    </ModalPortal>
  );
};

/**
 * Export `Search` component.
 */

export default Search;
