/**
 * Module dependencies.
 */

/**
 * `SvgDoubleBrandLogoMobile` component.
 */

const SvgDoubleBrandLogoMobile: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 277.93 97.26'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={
          'm25.35 69.14 23.96-.04V58.22l-10.29.01 3.49-5.44 17.71.05-11.99-18.87-22.88 35.18Zm38.39-10.89L55 58.23l-.02 10.85 15.63-.03-6.86-10.8ZM48.12 23.29l-3.93-6.16-33.07 52.04h7.68l29.32-45.88Z'
        }
        fill={'#ce0e2d'}
      />

      <path
        d={
          'M4.27 80.12h1.49l1.75-2.8h3.44l1.67 2.8h1.51l-4.77-7.87-5.09 7.87Zm3.85-3.77 1.24-1.91 1.09 1.91H8.12ZM19.31 77.91l-3.88-5.65-.95 7.86h1.31l.45-4.34h.03l3.04 4.34 3.05-4.34h.02l.46 4.34h1.28l-.93-7.86-3.88 5.65zM23.85 73.39h2.04l-.01 6.73h1.32l-.02-6.73h2.18v-1.08h-5.63l.12 1.08zM34.72 74.56c0-1.26-.93-2.27-2.96-2.27h-1.83v7.82h1.27l.03-3.47h.02l2.69 3.47h1.67l-2.87-3.43c1.25-.19 1.98-1.05 1.98-2.12m-3.49 1.34v-2.61h.67c.85 0 1.57.48 1.57 1.3 0 .76-.62 1.3-1.56 1.3h-.68Z'
        }
        fill={'#26231e'}
      />

      <path
        d={
          'M39.49 72.25c-2.21 0-4.29 1.65-4.29 3.94s2.08 3.93 4.29 3.93 4.29-1.65 4.29-3.93-2.08-3.94-4.29-3.94m0 6.81c-1.64 0-3.07-1.25-3.07-2.87s1.44-2.87 3.07-2.87 3.07 1.25 3.07 2.87-1.44 2.87-3.07 2.87M45.64 72.41l-1.31.17.01 7.54h4.25l.5-1.13h-3.45v-6.58zM61.31 72.41l-1.3.17.01 7.54h4.24l.5-1.13h-3.45v-6.58zM78.2 79c-.34 0-.61.25-.61.56s.27.56.61.56.61-.25.61-.56-.27-.56-.61-.56m0 1.03c-.28 0-.51-.21-.51-.47s.23-.47.51-.47.51.2.51.47-.23.47-.51.47'
        }
        fill={'#26231e'}
      />

      <path
        d={
          'M78.49 79.42c0-.13-.11-.17-.24-.17h-.29v.63h.1v-.29h.13l.17.29h.11l-.17-.29c.09 0 .18-.05.18-.17m-.32.08h-.11v-.16h.17c.08 0 .15 0 .15.09s-.13.07-.22.07M68.53 75.03h-1.84v-1.49h3l.52-1.13h-4.82v7.71h1.3v-4.11h1.84v-.98zM49.87 80.12h1.49l1.75-2.8h3.44l1.67 2.8h1.51l-4.77-7.87-5.09 7.87Zm3.85-3.77 1.24-1.91 1.09 1.91h-2.33ZM67.34 80.12h1.49l1.75-2.8h3.44l1.67 2.8h1.51l-4.77-7.87-5.09 7.87Zm3.85-3.77 1.24-1.91 1.09 1.91h-2.33ZM51.21 75.46h-3.44v.98h2.95l.49-.98z'
        }
        fill={'#26231e'}
      />

      <path
        d={
          'M117.63 0h2.23v97.26h-2.23z'
        }
        fill={'#1d1d1b'}
      />

      <path
        d={
          'm242.3 17.27 1.67 2.9c4.92 0 9.7 2.55 12.34 7.12 2.64 4.57 2.45 9.99 0 14.26l1.67 2.9 15.68-27.17h-31.35ZM249.51 59.11l-24.13-41.84h-24.09l36.18 62.71 12.04-20.87zM206.91 59.11l-24.13-41.84h-24.09l36.18 62.71 12.04-20.87zM247.97 76.33v3.13h-.62v-3.13h-.71v-.53h2.04v.53h-.71zM251.76 79.46l.02-2.81h-.02l-.76 2.81h-.5l-.74-2.81h-.01l.01 2.81h-.58V75.8h.86l.72 2.68h.02l.71-2.68h.9v3.66h-.63z'
        }
        fill={'#000'}
      />
    </svg>
  );
};

/**
 * Export `SvgDoubleBrandLogoMobile` component.
 */

export default SvgDoubleBrandLogoMobile;
