/**
 * Module dependencies.
 */

import { ExternalLink, RouterLink, Type } from 'src/components/core';
import {
  MenuOption,
  useCategories,
  useMenu
} from 'src/core/content-config/menu';

import { ReactElement, useMemo } from 'react';
import {
  color,
  isExternalUrl,
  media,
  setFontStyle,
  units
} from '@untile/react-components';

import { ifProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import union from 'lodash/union';
import useMarkets from 'src/api/markets/use-markets';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  visible: boolean;
};

/**
 * `Grid` styled component.
 */

const Grid = styled.div<{ visible?: boolean }>`
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: ${units(4.5)} 0;
  grid-template-columns: 100%;
  opacity: ${ifProp('visible', 1, 0)};
  pointer-events: ${ifProp('visible', 'auto', 'none')};
  transform: translateY(${ifProp('visible', '0', '-100%')});
  transition: ${theme('animations.fastTransition')};
  transition-property: opacity, transform;
  width: 100%;

  ${ifProp(
    'visible',
    css`
      transition-delay: 0.6s;
    `
  )}

  ${media.min('md')`
    grid-gap: 0 ${units(8)};
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.min('lg')`
    grid-gap: 0 ${units(12)};
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.min('xl')`
    grid-gap: 0 ${units(17)};
    grid-template-columns: repeat(3, 1fr);
  `}
`;

/**
 * `Group` styled component.
 */

const Group = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

/**
 * `GroupName` styled component.
 */

const GroupName = styled(Type.H6)`
  color: ${color('grey400')};
  font-weight: 400;
  margin-bottom: ${units(3)};

  ${media.min('md')`
    margin-bottom: ${units(4)};
  `}
`;

/**
 * `GroupList` styled component.
 */

const GroupList = styled.ul`
  display: grid;
  grid-auto-columns: 100%;
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled.a.attrs(({ href }) => ({
  as:
    (href && !isExternalUrl(href) && RouterLink) ||
    (href && isExternalUrl(href) && ExternalLink) ||
    'a'
}))<{
  isDisabled?: boolean;
  isHighlighted?: boolean;
}>`
  ${props =>
    setFontStyle({
      fontFamily: props.theme.typography.fontFamily.sansSerif,
      fontSize: 20,
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: 32
    })}

  align-items: center;
  color: ${color('textColor')};
  cursor: pointer;
  display: inline-block;
  margin-bottom: ${units(2)};
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  width: max-content;

  ${media.min('md')`
    margin-bottom: ${units(1.5)};
  `}

  ${ifProp(
    'isDisabled',
    css`
      color: ${color('grey200')};
      cursor: default;
      pointer-events: none;
    `
  )}

  ${ifProp(
    'isHighlighted',
    css`
      font-weight: 700;
    `
  )}

  &::after {
    background-color: ${color('textColor')};
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform ${theme('animations.defaultTransition')};
    width: 100%;
  }

  &:focus,
  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;

/**
 * `EmptyBlock` styled component.
 */

const EmptyBlock = styled.li`
  height: ${units(1)};

  ${media.min('md')`
    height: ${units(2.5)};
  `}
`;

/**
 * `Menu` component.
 */

const Menu = ({ visible }: Props): ReactElement => {
  const { locale, translate } = useTranslate();
  const routeResolver = useLocalizedRoute();
  const menuCategories = useCategories();
  const menu = useMenu();
  const { data, isSuccess } = useMarkets();

  const normalizedMenu = useMemo(() => {
    const normalizedMenu = menu;
    const markets: MenuOption[] = map(data, ({ slug, title }) => ({
      href: routeResolver('marketDetails', { slug }),
      isHighlighted: true,
      label: title
    }));

    normalizedMenu[0].options = union(
      markets,
      menuCategories.markets.options,
      menuCategories.others.options
    );

    return normalizedMenu;
  }, [
    data,
    menu,
    menuCategories.markets.options,
    menuCategories.others.options,
    routeResolver
  ]);

  return (
    <Grid visible={visible}>
      {isSuccess &&
        map(normalizedMenu, ({ options, title }, index) => (
          <Group key={index}>
            <GroupName>{translate(title)}</GroupName>

            <GroupList>
              {map(options, (option, optionIndex) => {
                const { href, isEmpty, isHighlighted, label } = option;

                return isEmpty ? (
                  <EmptyBlock key={optionIndex} />
                ) : (
                  <li key={optionIndex}>
                    <ListItemLink
                      href={href}
                      isDisabled={!href}
                      isHighlighted={isHighlighted}
                      {...(href && !isExternalUrl(href) && { locale })}
                    >
                      {translate(label)}
                    </ListItemLink>
                  </li>
                );
              })}
            </GroupList>
          </Group>
        ))}
    </Grid>
  );
};

/**
 * Export `Menu` component.
 */

export default Menu;
