/**
 * Module dependencies.
 */

import { ReactElement, useEffect, useState } from 'react';
import { color, units } from '@untile/react-components';
import { icons } from '../icons';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import Svg from '../svg';
import styled from 'styled-components';

/**
 * Export `VideoPlayerProps` interface.
 */

export interface VideoPlayerProps extends ReactPlayerProps {
  className?: string;
  image?: string | null;
  loop?: boolean;
  playing?: boolean;
  stopOnUnmount?: boolean;
  url: string;
  wait?: number;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('grey100')};
  padding-top: 56.2%;
  width: 100%;
`;

/**
 * `StyledReactPlayer` styled component.
 */

const StyledReactPlayer = styled(ReactPlayer)`
  left: 0;
  position: absolute;
  top: 0;
`;

/**
 * `PlaySvg` styled component.
 */

const PlaySvg = styled(Svg).attrs({
  icon: icons.play,
  size: units(4)
})`
  color: ${color('white')};
  transition: transform 0.3s ease;
`;

/**
 * `PlayButton` styled component.
 */

const PlayButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: ${color.transparentize('white', 0.4)};
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  height: ${units(9)};
  justify-content: center;
  left: 50%;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
  width: ${units(9)};

  &::before {
    background-color: ${color.transparentize('white', 0.2)};
    border-radius: 50%;
    content: '';
    height: ${units(11)};
    position: absolute;
    transition: transform 0.3s ease;
    width: ${units(11)};
  }

  &:hover {
    ${PlaySvg} {
      transform: scale(1.2);
    }

    &::before {
      transform: scale(1.05);
    }
  }
`;

/**
 * `PlayButton` component.
 */

export const PlayVideoButton = (): ReactElement => (
  <PlayButton>
    <PlaySvg />
  </PlayButton>
);

/**
 * Export `VideoPlayer` component.
 */

export const VideoPlayer = (props: VideoPlayerProps): ReactElement => {
  const { className, controls, image, url, volume, wait, ...rest } = props;
  const [isPlaying, setIsPlaying] = useState<boolean>();

  useEffect(() => {
    if (props?.playing && wait) {
      const timeout = setTimeout(() => {
        setIsPlaying(props?.playing);
      }, wait);

      return () => clearTimeout(timeout);
    }

    setIsPlaying(props?.playing);
  }, [props?.playing, wait]);

  return (
    <Wrapper className={className}>
      <StyledReactPlayer
        {...rest}
        config={{
          youtube: {
            playerVars: {
              rel: 0
            }
          }
        }}
        controls={controls ?? true}
        height={'100%'}
        light={image ? image : null}
        playIcon={image ? <PlayVideoButton /> : null}
        playing={isPlaying}
        url={url}
        volume={volume ?? 0.3}
        width={'100%'}
      />
    </Wrapper>
  );
};

/**
 * Export `VideoPlayer` component.
 */

export default VideoPlayer;
