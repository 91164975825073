/**
 * Module dependencies.
 */

/**
 * `SvgOptionsVertical` component.
 */

const SvgOptionsVertical: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={
          'M20 24a4 4 0 1 0 8 0 4 4 0 0 0-8 0ZM20 12a4 4 0 1 0 8 0 4 4 0 0 0-8 0ZM20 36a4 4 0 1 0 8 0 4 4 0 0 0-8 0Z'
        }
        fill={'currentColor'}
      />
    </svg>
  );
};

/**
 * Export `SvgOptionsVertical` component.
 */

export default SvgOptionsVertical;
