/**
 * Module dependencies.
 */

import { Box, RawHtml, units } from '@untile/react-components';
import { Button, Subtitle, Type } from 'src/components/core';
import { Configure, Index, connectStateResults } from 'react-instantsearch-dom';
import { ReactElement, useCallback, useMemo } from 'react';
import { Result } from 'src/types/search';
import { getKeyFromObject } from 'src/core/utils/objects';
import { hitsPerPage, indexes, orderedIndexes } from 'src/core/search-config';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import InfiniteHits from 'src/components/search/search-results/hits/infinite-hits';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `AllResults` type.
 */

type AllResults = {
  [key: string]: Result;
};

/**
 * `Totals` type.
 */

type Totals = {
  downloads?: number;
  modelRanges?: number;
  news?: number;
  total: number;
};

/**
 * `Props` type.
 */

type Props = {
  allSearchResults: AllResults;
  currentSearch: string;
  onClose: () => void;
};

/**
 * `NoResults` styled component.
 */

const NoResults = styled(Subtitle)`
  margin-bottom: ${units(5)};
`;

/**
 * `TitleWrapper` styled component.
 */

const TitleWrapper = styled.div`
  align-items: start;
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: repeat(2, max-content);
  margin-bottom: ${units(3)};
`;

/**
 * Get totals.
 */

function getTotals(results: AllResults): Totals {
  try {
    const modelRanges = results[indexes.modelRanges]?.nbHits ?? 0;
    const news = results[indexes.news]?.nbHits ?? 0;
    const downloads = results[indexes.downloads]?.nbHits ?? 0;

    return {
      downloads,
      modelRanges,
      news,
      total: modelRanges + news + downloads
    };
  } catch {
    return { total: 0 };
  }
}

/**
 * `SearchResults` component.
 */

const SearchResults = (props: Props): ReactElement => {
  const { allSearchResults, currentSearch, onClose } = props;
  const { translate } = useTranslate();
  const router = useRouter();
  const routeResolver = useLocalizedRoute();
  const totals = useMemo(() => getTotals(allSearchResults), [allSearchResults]);
  const handleGoToHomepage = useCallback(() => {
    onClose();
    router.push(routeResolver('home'));
  }, [onClose, routeResolver, router]);

  return (
    <>
      {totals?.total === 0 ? (
        <>
          {!isEmpty(currentSearch) && (
            <>
              <RawHtml element={NoResults}>
                {translate('common:search.noResults', { currentSearch })}
              </RawHtml>

              <Button
                onClick={handleGoToHomepage}
                size={'medium'}
              >
                {translate('common:actions.goToHomepage')}
              </Button>
            </>
          )}
        </>
      ) : (
        Object.values(orderedIndexes).map(indexName => {
          const indexKey = getKeyFromObject(indexes, indexName);

          return totals[indexKey] > 0 ? (
            <Index
              indexName={indexName}
              key={indexName}
            >
              <TitleWrapper>
                <Type.H5>{translate(`common:labels.${indexKey}`)}</Type.H5>

                <Type.Paragraph>{`(${totals[indexKey]})`}</Type.Paragraph>
              </TitleWrapper>

              <Box marginBottom={units(5)}>
                <Configure hitsPerPage={hitsPerPage} />

                <InfiniteHits index={indexName} />
              </Box>
            </Index>
          ) : null;
        })
      )}
    </>
  );
};

/**
 * Export `SearchResults` component.
 */

export default connectStateResults(SearchResults);
