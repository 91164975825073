/**
 * Module dependencies.
 */

import { ReactElement, useCallback, useEffect } from 'react';
import { color, media, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useSectionsNavigation } from 'src/context/sections-navigation/context';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
};

/**
 * `Navigation` styled component.
 */

const Navigation = styled.div<{ isVisible: boolean }>`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(5)};
  grid-template-rows: repeat(4, max-content);
  left: ${units(4)};
  opacity: ${ifProp('isVisible', 1, 0)};
  pointer-events: ${ifProp('isVisible', 'auto', 'none')};
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 750ms ease;
  z-index: ${theme('zIndex.sectionsNavigation')};

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `Option` styled component.
 */

const Option = styled.button<{ isActive?: boolean }>`
  ${theme('typography.styles.small')}

  background: none;
  border: 0;
  color: ${color('black')};
  cursor: pointer;
  outline: 0;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  transition: border-bottom 1s ease-in 1.5s,
    opacity ${theme('animations.defaultTransition')};
  width: max-content;

  span {
    transform: rotate(180deg);
    white-space: nowrap;
    writing-mode: vertical-rl;
  }

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  ${ifProp(
    'isActive',
    css`
      border-right: 1px solid ${color('black')};
    `
  )}
`;

/**
 * `SectionsNavigation` component.
 */

const SectionsNavigation = ({ className }: Props): ReactElement => {
  const { translate } = useTranslate();
  const locomotiveScroll = useLocomotiveScroll();
  const { currentSection, isVisible, limits, onSetVisible, sections } =
    useSectionsNavigation();

  const handleScroll = useCallback(
    ({ yPosition }) => {
      if (locomotiveScroll?.scroll) {
        locomotiveScroll?.scroll.scrollTo(yPosition, 0);
      }
    },
    [locomotiveScroll?.scroll]
  );

  useEffect(() => {
    if (locomotiveScroll?.scroll && limits) {
      locomotiveScroll?.scroll.on('scroll', ({ scroll }) => {
        onSetVisible(scroll.y > limits.start && scroll.y < limits.end);
      });
    }
  }, [limits, locomotiveScroll?.scroll, onSetVisible]);

  return (
    <Navigation
      className={className}
      isVisible={isVisible}
    >
      {map(sections, ({ label, name, yPosition }, index) => {
        return label && name ? (
          <Option
            isActive={name === currentSection}
            key={index}
            onClick={() => handleScroll({ yPosition })}
          >
            <span>{translate(label)}</span>
          </Option>
        ) : null;
      })}
    </Navigation>
  );
};

/**
 * Export `SectionsNavigation` component.
 */

export default SectionsNavigation;
