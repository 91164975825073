/**
 * Module dependencies.
 */

import { Error } from 'src/types/forms';
import { FC, ReactElement, SyntheticEvent, forwardRef } from 'react';
import {
  FormGroup,
  HelpText,
  Label,
  Wrapper,
  formControlStyle
} from '../fields-styled-components';

import { color, media, units } from '@untile/react-components';
import { icons } from '../../icons';
import { ifProp, theme } from 'styled-tools';
import Svg from '../../svg';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * Export `Props` interface.
 */

export interface SelectProps {
  className?: string;
  disabled?: boolean;
  error?: Error | null | undefined;
  helpText?: string;
  id?: string;
  isRequired?: boolean;
  label?: string;
  name: string;
  onChange?: (event: SyntheticEvent) => void;
  value?: string | null | undefined;
}

/**
 * `StyledSelect` styled component.
 */

const StyledSelect = styled.select<{ hasError?: boolean }>`
  ${formControlStyle}

  appearance: none;
  cursor: pointer;
  padding: ${units(1)} ${units(5.5)} ${units(1)} 0;
  text-overflow: ellipsis;
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)<Pick<SelectProps, 'disabled'>>`
  bottom: ${units(1.5)};
  color: ${color('grey800')};
  pointer-events: none;
  position: absolute;
  right: ${units(2)};
  text-decoration: none;
  transition: color ${theme('animations.defaultTransition')};

  ${ifProp(
    'disabled',
    css`
      color: ${color('grey200')};
    `
  )}

  ${media.min('ms')`
    right: ${units(1.5)};
  `}
`;

/**
 * `Select` component.
 */

const Select: FC<SelectProps> = forwardRef<any, SelectProps>(
  (props: SelectProps, ref: any): ReactElement => {
    const {
      className,
      disabled,
      error,
      helpText,
      id,
      isRequired,
      label,
      name,
      ...rest
    } = props;

    const hasError = !isEmpty(error);

    return (
      <FormGroup
        className={className}
        disabled={disabled}
      >
        <Label
          htmlFor={id ?? name}
          isRequired={isRequired}
        >
          {label}
        </Label>

        <Wrapper>
          <StyledSelect
            {...rest}
            disabled={disabled}
            hasError={hasError}
            id={id ?? name}
            name={name}
            ref={ref}
          />

          <Icon
            disabled={disabled}
            icon={icons.chevronDown}
            size={units(3)}
          />
        </Wrapper>

        {hasError && <HelpText hasError>{error?.message}</HelpText>}

        {helpText && !hasError && <HelpText>{helpText}</HelpText>}
      </FormGroup>
    );
  }
);

/**
 * `Select` display name.
 */

Select.displayName = 'Select';

/**
 * Export `Select` component.
 */

export default Select;
