/**
 * Module dependencies.
 */

import { Context, createContext, useContext } from 'react';
import { CustomizationProps, FavoriteProps } from 'src/types/my-products';

/**
 * `Props` type.
 */

type Props = {
  customizations: {
    pageParams: unknown[];
    pages: Array<{
      data: CustomizationProps[];
      nextPage: unknown;
      total: unknown;
    }>;
  };
  favorites: {
    pageParams: unknown[];
    pages: Array<{
      data: FavoriteProps[];
      nextPage: unknown;
      total: unknown;
    }>;
  };
  isLoading: boolean;
  isSubmitting: boolean;
  isSuccess: boolean;
  onAddFavorite: (id: string, hideMessages?: boolean) => Promise<any>;
  onRemoveCustomization: (id: string) => Promise<any>;
  onRemoveFavorite: (id: string) => Promise<any>;
};

/**
 * Export `FavoritesContext` context.
 */

export const FavoritesContext: Context<Props> = createContext({} as Props);

/**
 * Export `useFavoritesContext` hook.
 */

export const useFavoritesContext = () => useContext(FavoritesContext);
