/**
 * Module dependencies.
 */

import { keyframes as keyframe } from 'styled-components';

/**
 * `fadeInUp` keyframes.
 */

const fadeInUp = keyframe`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

/**
 * `fadeOut` keyframes.
 */

const fadeOut = keyframe`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * `fadeInUpSmall` keyframes.
 */

const fadeInUpSmall = keyframe`
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

/**
 * `pageAnimationEnd` keyframes.
 */

const pageAnimationEnd = keyframe`
  from {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(0);
  }
`;

/**
 * `pageAnimationStart` keyframes.
 */

const pageAnimationStart = keyframe`
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
`;

/**
 * Export `keyframe`.
 */

export const keyframes = {
  fadeInUp,
  fadeInUpSmall,
  fadeOut,
  pageAnimationEnd,
  pageAnimationStart
};
