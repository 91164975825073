/**
 * Module dependencies.
 */

import { ExternalLink, Type } from 'src/components/core';
import { LocaleHits } from 'src/types/search';
import { ReactElement } from 'react';
import { bytesToSize } from 'src/core/utils/downloads';
import { color, media, units } from '@untile/react-components';
import { theme } from 'styled-tools';
import split from 'lodash/split';
import styled from 'styled-components';
import useSearchTranslations from 'src/hooks/use-search-translations';

/**
 * `Props` type.
 */

type Props = {
  [key: string]: string | LocaleHits;
  id: string;
};

/**
 * `Card` styled component.
 */

const Card = styled(ExternalLink)`
  align-items: center;
  color: ${color('grey800')};
  cursor: pointer;
  display: flex;
  position: relative;
  text-decoration: none;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  ${media.min('md')`
    grid-column-gap: ${units(1)};
  `}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Paragraph)`
  color: ${color('grey500')};
  text-transform: uppercase;
`;

/**
 * `DownloadCard` component.
 */

const DownloadCard = (props: Props): ReactElement | null => {
  const { getTranslations } = useSearchTranslations();
  const { file, name } = getTranslations(props);

  if (!file) {
    return null;
  }

  const fileExtension = split(file?.url, '.').pop();

  return (
    <Card href={file?.url}>
      <Type.Paragraph>{name}</Type.Paragraph>

      <Label>{`${fileExtension} ${bytesToSize(file?.size)}`}</Label>
    </Card>
  );
};

/**
 * Export `DownloadCard` component.
 */

export default DownloadCard;
