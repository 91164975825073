/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { color, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Constants.
 */

const duration = '0.3s';
const bezier = 'cubic-bezier(0.23, 1, 0.32, 1)';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  isOpen: boolean;
  onClick: () => void;
};

/**
 * `Button` styled component.
 */

const Button = styled.button<{ active: boolean }>`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background: none;
  border: 0;
  color: ${color('grey800')};
  cursor: pointer;
  display: flex;
  height: ${units(2)};
  justify-content: center;
  margin: ${units(0.5)};
  outline: none;
  padding: 0;
  transition: color 0.3s ease, opacity 0.3s ease-out;
  width: ${units(2)};
  z-index: ${theme('zIndex.hamburgerMenu')};

  ${ifProp(
    'active',
    css`
      transition-delay: 0.4s, 0s;
    `
  )}

  &:hover,
  &:focus {
    opacity: 0.6;
    transition-delay: 0.4s, 0s;
  }
`;

/**
 * `Lines` styled component.
 */

const Lines = styled.span<{ active: boolean }>`
  position: relative;
  transition: background-color 0.3s ease;

  &,
  &::before,
  &::after {
    background-color: currentcolor;
    height: 2px;
    width: 16px;
  }

  &::before,
  &::after {
    content: '';
    left: 0;
    position: absolute;
  }

  &::before {
    bottom: 6px;
    transition: bottom ${duration} ${duration} ${bezier},
      transform ${duration} ${bezier};
  }

  &::after {
    top: 6px;
    transition: top ${duration} ${duration} ${bezier},
      transform ${duration} ${bezier};
  }

  ${ifProp(
    'active',
    css`
      background-color: transparent !important;

      &::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom ${duration} ${bezier},
          transform ${duration} ${duration} ${bezier};
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
        transition: top ${duration} ${bezier},
          transform ${duration} ${duration} ${bezier};
      }
    `
  )}
`;

/**
 * `HamburgerMenu` component.
 */

const HamburgerMenu = (props: Props): ReactElement => {
  const { className, isOpen, onClick } = props;

  return (
    <Button
      active={isOpen}
      aria-label={'Menu'}
      className={className}
      onClick={onClick}
    >
      <Lines active={isOpen} />
    </Button>
  );
};

/**
 * Export `HamburgerMenu` component.
 */

export default HamburgerMenu;
