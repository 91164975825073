/**
 * Module dependencies.
 */

import { Image, RouterLink, Type } from 'src/components/core';
import { ReactElement } from 'react';
import { color, units } from '@untile/react-components';
import { theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  featured_image: string; // eslint-disable-line id-match
  slug: string;
  title: string;
};

/**
 * `Title` styled component.
 */

const Title = styled(Type.H6)`
  grid-area: title;
  transform: opacity ${theme('animations.defaultTransition')};
`;

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)`
  align-items: center;
  color: ${color('grey800')};
  cursor: pointer;
  display: grid;
  grid-column-gap: ${units(2)};
  grid-template-areas: 'image title';
  grid-template-columns: ${units(7)} max-content;
  grid-template-rows: ${units(7)};
  position: relative;
  text-decoration: none;

  &:focus,
  &:hover {
    ${Title} {
      opacity: 0.8;
    }
  }
`;

/**
 * `ImageOuterWrapper` styled component.
 */

const ImageOuterWrapper = styled.div`
  background-color: ${color.transparentize('white', 0.5)};
  height: 100%;
  width: 100%;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: image;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
`;

/**
 * `ModelRangeSearchCard` component.
 */

const ModelRangeSearchCard = (props: Props): ReactElement => {
  const {
    featured_image, // eslint-disable-line id-match
    slug,
    title
  } = props;

  const routeResolver = useLocalizedRoute();

  return (
    <Card href={routeResolver('modelRangeDetails', { slug })}>
      <ImageOuterWrapper>
        <ImageWrapper>
          <Image
            alt={title}
            fill
            src={
              !isEmpty(featured_image)
                ? featured_image
                : '/static/images/empty.jpg'
            }
            style={{ objectFit: 'scale-down' }}
          />
        </ImageWrapper>
      </ImageOuterWrapper>

      <Title>{title}</Title>
    </Card>
  );
};

/**
 * Export `ModelRangeSearchCard` component.
 */

export default ModelRangeSearchCard;
