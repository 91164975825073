/**
 * Module dependencies.
 */

import { Fill, color, media, units } from '@untile/react-components';
import { IconButton, VideoPlayer, icons } from 'src/components/core';
import { ReactElement, useEffect, useRef } from 'react';
import { ifProp, theme } from 'styled-tools';
import ModalPortal from '../modals/modal-portal';
import styled, { css } from 'styled-components';

/**
 * Export `LightboxProps` interface.
 */

export interface LightboxProps {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{ visible: boolean }>`
  background-color: ${color('white')};
  display: grid;
  grid-template-areas:
    'closeButton .      .'
    'player      player player';
  grid-template-columns: ${units(5)} 1fr ${units(5)};
  grid-template-rows: ${units(5)} 1fr;
  outline: none;
  padding: ${units(4)} ${theme('grid.gutterMobile')}px;
  position: fixed !important;
  transition: visibility ${theme('animations.defaultTransition')};
  z-index: ${theme('zIndex.modal')};

  ${ifProp(
    'visible',
    css`
      animation: ${theme('keyframes.fadeIn')}
        ${theme('animations.defaultTransition')} both;
      visibility: visible;
    `,
    css`
      animation: ${theme('keyframes.fadeOut')}
        ${theme('animations.defaultTransition')} both;
      pointer-events: none;
      visibility: hidden;
    `
  )}

  ${media.min('ms')`
    grid-template-areas:
      '. .      closeButton'
      '. player .';
    padding: ${units(4)};
  `}

  ${media.min('md')`
    grid-template-areas:
    '. . .      . closeButton'
    '. . player . .';
    grid-template-columns: ${units(5)} 1fr 10fr 1fr ${units(5)};
  `}

  ${media.min('xl')`
    grid-template-columns: ${units(5)} 2fr 8fr 2fr ${units(5)};
  `}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton)`
  grid-area: closeButton;
`;

/**
 * `PlayerWrapper` styled component.
 */

const PlayerWrapper = styled.div`
  align-items: center;
  display: flex;
  grid-area: player;
  position: relative;

  &::after {
    border: 1px solid ${color('white')};
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  > div {
    position: relative;
  }
`;

/**
 * `Lightbox` component.
 */

const Lightbox = (props: LightboxProps): ReactElement => {
  const { isOpen, onClose, videoUrl } = props;
  const modalRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isOpen && modalRef && modalRef?.current) {
      const timeout = setTimeout(() => modalRef.current.focus(), 500);

      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  return (
    <ModalPortal isOpen={isOpen}>
      <Wrapper
        aria-hidden={!isOpen}
        aria-modal
        ref={modalRef}
        role={'dialog'}
        tabIndex={isOpen ? -1 : undefined}
        visible={isOpen}
      >
        <CloseButton
          icon={icons.close}
          iconSize={units(3)}
          onClick={onClose}
        />

        <PlayerWrapper>
          <VideoPlayer
            playing={isOpen}
            url={videoUrl}
          />
        </PlayerWrapper>
      </Wrapper>
    </ModalPortal>
  );
};

/**
 * Default props.
 */

Lightbox.defaultProps = {
  name: 'lightbox'
};

/**
 * Export `Lightbox` component.
 */

export default Lightbox;
