/**
 * Module dependencies.
 */

/**
 * `SvgArrowLeftLarge` component.
 */

const SvgArrowLeftLarge: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M12.847 32.358a.5.5 0 1 0 .698-.716L6.228 24.5h37.968a.5.5 0 1 0 0-1H6.228l7.317-7.142a.5.5 0 0 0-.698-.716l-8.196 8a.5.5 0 0 0 0 .716l8.196 8Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgArrowLeftLarge` component.
 */

export default SvgArrowLeftLarge;
