/**
 * Module dependencies.
 */

import { Type } from '../typography';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `DropdownList` styled component.
 */

export const DropdownList = styled.ul`
  padding: ${units(2)};
`;

/**
 * Export `DropdownListItem` styled component.
 */

export const DropdownListItem = styled(Type.Paragraph).attrs(({ href }) => ({
  as: href ? 'a' : 'div'
}))<{
  isActive?: boolean;
  isDisabled?: boolean;
}>`
  align-items: center;
  background-color: ${color('white')};
  color: ${color('black')};
  cursor: pointer;
  display: flex;
  margin: 0;
  min-height: ${units(5)};
  padding: ${units(1)} ${units(2)};
  position: relative;
  text-decoration: none;
  transition: ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    background-color: ${color('grey25')};
  }

  ${ifProp(
    'isActive',
    css`
      background-color: ${color('grey50')} !important;
    `
  )}

  ${ifProp(
    'isDisabled',
    css`
      color: ${color('grey200')};
    `
  )}
`;
