/**
 * Module dependencies.
 */

import { ReactNode, forwardRef } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar
} from 'swiper';

import styled, { createGlobalStyle } from 'styled-components';
import swiperStyles from 'swiper/swiper-bundle.css';

/**
 * Load swiper components.
 */

SwiperCore.use([
  Autoplay,
  Controller,
  EffectFade,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar
]);

/**
 * Export `SliderProps` interface.
 */

export interface SliderProps extends SwiperProps {
  children: ReactNode;
  className?: string;
  ref: any;
}

/**
 * `GlobalStyle` component.
 */

const GlobalStyle = createGlobalStyle`
  ${swiperStyles}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;

  &,
  > .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
  }
`;

/**
 * Export `Slide` component.
 */

export const Slide = SwiperSlide;

/**
 * `Slider` component.
 */

const Slider = forwardRef<any, SliderProps>((props, ref) => {
  const { children, className, ...rest } = props;

  return (
    <Wrapper className={className}>
      <GlobalStyle />

      <Swiper
        ref={ref}
        {...rest}
      >
        {children}
      </Swiper>
    </Wrapper>
  );
});

/**
 * `Slider` display name.
 */

Slider.displayName = 'Slider';

/**
 * Export `Slider` component.
 */

export default Slider;
