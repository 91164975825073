/**
 * Module dependencies.
 */

/**
 * `SvgLogout` component.
 */

const SvgLogout: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M10 8a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h8a2 2 0 1 1 0 4h-8a6 6 0 0 1-6-6V10a6 6 0 0 1 6-6h8a2 2 0 1 1 0 4h-8Zm20.586 4.586a2 2 0 0 1 2.828 0l9.998 9.997.024.025a1.994 1.994 0 0 1 .409 2.165 1.993 1.993 0 0 1-.434.645l-9.997 9.996a2 2 0 1 1-2.828-2.828L37.172 26H18a2 2 0 1 1 0-4h19.172l-6.586-6.586a2 2 0 0 1 0-2.828Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgLogout` component.
 */

export default SvgLogout;
