/**
 * Module dependencies.
 */

import {
  authRequest,
  authorizationHeader,
  handleRequestError
} from 'src/core/utils/requests';

import { getAuthApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  token: string;
};

/**
 * Export `revokeToken`.
 */

export async function revokeToken({ token }: Query) {
  const endpoint = getAuthApiEndpoint('revokeToken');

  if (!token) {
    return;
  }

  try {
    return await authRequest.post(endpoint, null, {
      headers: authorizationHeader(token)
    });
  } catch (error) {
    throw handleRequestError(error);
  }
}
