/**
 * Module dependencies.
 */

/**
 * `SvgAmtrolAlfaLogoMobile` component.
 */

const SvgAmtrolAlfaLogoMobile: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        d={
          'M13.515 37.158h15.377v-6.982h-6.605l2.247-3.483H35.89L28.217 14.59 13.515 37.158Zm24.63-6.982h-5.618v6.966h10.024l-4.406-6.966ZM28.12 7.745l-2.52-3.957L4.39 37.174h4.92L28.12 7.744Z'
        }
        fill={'#B1132F'}
      />

      <path
        clipRule={'evenodd'}
        d={
          'M0 44.204h.93l1.125-1.798H4.26l1.068 1.798H6.3l-3.042-5.056L0 44.204Zm2.464-2.408.802-1.228.699 1.228H2.464Zm7.183.987L7.15 39.156l-.602 5.048h.842l.29-2.785h.015l1.95 2.785 1.95-2.785h.017l.305 2.785h.818l-.602-5.048-2.487 3.627Zm4.22-2.897H12.56l-.08-.69h3.61v.69h-1.396l.008 4.318h-.842l.008-4.318Zm5.659.747c0-.803-.594-1.453-1.894-1.453h-1.18v5.024h.803v-2.231l1.725 2.23h1.075l-1.845-2.206a1.365 1.365 0 0 0 1.268-1.364h.048Zm-2.287.858v-1.669h.433a.89.89 0 0 1 1.003.803.882.882 0 0 1-.995.802l-.441.064Zm4.013-1.815c.39-.3.857-.481 1.348-.52a2.664 2.664 0 0 1 2.736 2.56 2.536 2.536 0 1 1-4.084-2.04Zm.35 3.478c.29.218.636.346.997.368a1.926 1.926 0 0 0 1.959-1.806 1.846 1.846 0 1 0-2.955 1.438Zm4.938-3.894-.843.105.008 4.839h2.729l.313-.73-2.207.008V39.26Zm9.213.105.835-.105v4.222l2.215-.008-.321.73h-2.72l-.009-4.84Zm11.67 4.117a.36.36 0 1 0-.001.721.36.36 0 0 0 0-.721Zm0 .666a.313.313 0 0 1-.322-.305.306.306 0 0 1 .321-.297.311.311 0 0 1 .33.297.32.32 0 0 1-.33.305Zm.031-.506c.08 0 .153.024.153.112h-.008c0 .113-.056.113-.12.113l.12.184h-.072l-.113-.184h-.08v.184h-.056v-.409h.176Zm-.128.16H47.438l.032.002c.05.002.105.005.105-.041 0-.057-.049-.057-.097-.057h-.152v.097Zm-6.107-2.864h-1.18v-.955h1.934l.329-.73h-3.09v4.951h.827v-2.64h1.18v-.626Zm-11.011 3.266h-.963l3.298-5.056 3.066 5.056h-.971l-1.1-1.798h-2.215l-1.115 1.798Zm2.311-3.636-.802 1.228h1.5l-.698-1.228Zm7.937 3.636h.963l1.116-1.798h2.215l1.067 1.798h.971l-3.066-5.056-3.266 5.056Zm2.472-2.408.802-1.228.699 1.228h-1.501Zm-15.024-.578h2.207l-.313.626h-1.894v-.626Z'
        }
        fill={'#000'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgAmtrolAlfaLogoMobile` component.
 */

export default SvgAmtrolAlfaLogoMobile;
