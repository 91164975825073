/**
 * Module dependencies.
 */

/**
 * `SvgChevronDown` component.
 */

const SvgChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M10.586 10.586a2 2 0 0 1 2.828 0L24 21.172l10.586-10.586a2 2 0 1 1 2.828 2.828L26.828 24l10.586 10.586a2 2 0 1 1-2.828 2.828L24 26.828 13.414 37.414a2 2 0 1 1-2.828-2.828L21.172 24 10.586 13.414a2 2 0 0 1 0-2.828Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgChevronDown` component.
 */

export default SvgChevronDown;
