/**
 * Module dependencies.
 */

/**
 * `SvgSocialIcon` component.
 */

const SvgSocialIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M38 9.75C34.25 6 29.25 4 24 4 13 4 4 13 4 24c0 3.5 1 7 2.75 10L4 44l10.5-2.75c3 1.5 6.25 2.5 9.5 2.5 11 0 20-9 20-20 0-5.25-2.25-10.25-6-14ZM24 40.5c-3 0-6-.75-8.5-2.25L15 38l-6.25 1.75 1.75-6L10 33c-1.75-2.75-2.5-5.75-2.5-8.75 0-9 7.5-16.5 16.5-16.5 4.5 0 8.5 1.75 11.75 4.75 3.25 3.25 4.75 7.25 4.75 11.75C40.5 33 33.25 40.5 24 40.5ZM33 28c-.5-.25-3-1.5-3.5-1.5-.5-.25-.75-.25-1 .25s-1.25 1.5-1.5 2c-.25.25-.5.25-1 .25-.5-.25-2-.75-4-2.5-1.5-1.25-2.5-3-2.75-3.5s0-.75.25-1l.75-.75c.25-.25.25-.5.5-.75s0-.5 0-.75-1-2.75-1.5-3.75c-.25-.75-.75-.75-1-.75h-1c-.25 0-.75 0-1.25.5S14.25 17.5 14.25 20s1.75 4.75 2 5.25c.25.25 3.5 5.5 8.5 7.5 4.25 1.75 5 1.25 6 1.25s3-1.25 3.25-2.25c.5-1.25.5-2.25.25-2.25C34 28.25 33.5 28.25 33 28Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgSocialIcon` component.
 */

export default SvgSocialIcon;
