/**
 * Module dependencies.
 */

import { LocaleHits } from 'src/types/search';
import { ReactElement } from 'react';
import { RouterLink, Type } from 'src/components/core';
import { color, media, units } from '@untile/react-components';
import { dateToString } from 'src/core/utils/dates';
import { theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import useSearchTranslations from 'src/hooks/use-search-translations';

/**
 * `Props` type.
 */

type Props = {
  [key: string]: string | LocaleHits;
  date: string;
};

/**
 * `Card` styled component.
 */

const Card = styled(RouterLink)`
  align-items: center;
  color: ${color('grey800')};
  cursor: pointer;
  display: grid;
  grid-template-rows: repeat(2, max-content);
  position: relative;
  text-decoration: none;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  ${media.min('md')`
    grid-column-gap: ${units(1)};
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: 100%;
  `}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Paragraph)`
  color: ${color('grey500')};
`;

/**
 * `NewsCard` component.
 */

const NewsCard = (props: Props): ReactElement | null => {
  const { date, ...rest } = props;
  const routeResolver = useLocalizedRoute();
  const { locale } = useRouter();
  const { getTranslations } = useSearchTranslations();
  const { slug, title } = getTranslations(rest);
  const normalizedDate = dateToString(new Date(date), locale);

  if (!title || !slug) {
    return null;
  }

  return (
    <Card href={routeResolver('newExhibitionDetails', { slug })}>
      <Type.Paragraph>{title}</Type.Paragraph>

      <Label>{normalizedDate}</Label>
    </Card>
  );
};

/**
 * Export `NewsCard` component.
 */

export default NewsCard;
