/**
 * Export `dateToString`.
 */

export function dateToString(date: Date, locale: string) {
  const customLocale = locale === 'en' ? 'en-GB' : locale;

  return date.toLocaleString(customLocale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
}

/**
 * Export `formatDatetimeString`.
 */

export function formatDatetimeString(date: string, locale: string) {
  const customLocale = locale === 'en' ? 'en-GB' : locale;
  const parsedDate = new Date(date);

  return parsedDate
    .toLocaleString(customLocale, {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    })
    .replaceAll('/', '.');
}
