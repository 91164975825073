/**
 * Module dependencies.
 */

import { Context, createContext, useContext } from 'react';
import { Token } from 'src/core/utils/requests';
import { User } from 'src/types/user';

/**
 * `Props` type.
 */

type Props = {
  isAuthenticated: boolean;
  onClearToken: () => void;
  onUpdateToken: (token: Token) => void;
  token: string | null;
  updateUser: () => void;
  user: User;
};

/**
 * Export `AuthContext` context.
 */

export const AuthContext: Context<Props> = createContext({} as Props);

/**
 * Export `useAuth` hook.
 */

export const useAuth = () => useContext(AuthContext);
