/**
 * My Products endpoints.
 */

const myProductsEndpoints = {
  createUser: '/users',
  customization: '/customizations/:id',
  customizations: '/customizations',
  favorite: '/favorites/:id',
  favoriteExists: '/favorites/exists',
  favorites: '/favorites',
  getUser: '/users/me',
  requestQuoteForm: '/forms/request-quote'
};

/**
 * API endpoints.
 */

export const apiEndpoints = {
  ...myProductsEndpoints,
  advisorForm: '/forms/recommender',
  getBanners: '/banners',
  getDownloads: '/downloads',
  getHistory: '/history',
  getMarket: '/markets/:slug',
  getMarkets: '/markets',
  getModelRange: '/model-ranges/:slug',
  getModelRangeFilters: '/model-range-filters',
  getModelRanges: '/model-ranges',
  getNewsExhibitions: '/news',
  getNewsExhibitionsDetail: '/news/:slug',
  getPageFields: '/page-fields',
  getPatents: '/patents',
  getTechnologies: '/technologies',
  getTechnology: '/technologies/:slug',
  newsletter: '/forms/newsletter',
  requestModelRangeContact: '/forms/request-info-model-range',
  requestTechnologyContact: '/forms/request-info-technology',
  submitContactUsForm: '/forms/contact-us'
};
