/**
 * Module dependencies.
 */

import { Collapse, color, units } from '@untile/react-components';
import { ReactNode, useState } from 'react';
import { icons } from 'src/components/core/icons';
import { ifProp, theme } from 'styled-tools';
import Svg from 'src/components/core/svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  header: ReactNode;
  rightContent?: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border-bottom: 1px solid ${color('grey50')};
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: ${units(1)};
  grid-template-columns: max-content 1fr max-content;
  padding: ${units(3)} 0;
`;

/**
 * `FlippableSvg` styled component.
 */

const FlippableSvg = styled(Svg)<{ isOpen?: boolean }>`
  transition: transform ${theme('animations.defaultTransition')};

  ${ifProp(
    'isOpen',
    css`
      transform: rotate(-180deg);
    `
  )}
`;

/**
 * `CollapseContent` styled component.
 */

const CollapseContent = styled.div`
  padding-bottom: ${units(3)};
  padding-left: ${units(3)};
  padding-right: ${units(1)};
`;

/**
 * Export `Accordion` component.
 */

export const Accordion = ({ children, header, rightContent }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      <Header onClick={() => setIsOpen(isOpen => !isOpen)}>
        <FlippableSvg
          icon={icons.chevronDown}
          isOpen={isOpen}
          size={units(2)}
        />

        {header}

        {rightContent}
      </Header>

      <Collapse
        initialHeight={0}
        isOpen={isOpen}
      >
        <CollapseContent>{children}</CollapseContent>
      </Collapse>
    </Wrapper>
  );
};
