/**
 * Module dependencies.
 */

/**
 * `SvgArrowRightLarge` component.
 */

const SvgArrowRightLarge: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M36.35 15.642a.5.5 0 1 0-.7.716l7.318 7.142H5a.5.5 0 0 0 0 1h37.968l-7.317 7.142a.5.5 0 1 0 .698.716l8.196-8a.5.5 0 0 0 0-.716l-8.196-8Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgArrowRightLarge` component.
 */

export default SvgArrowRightLarge;
