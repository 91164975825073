/**
 * Module dependencies.
 */

import {
  Dropdown,
  DropdownList,
  DropdownListItem,
  IconButton,
  RouterLink,
  Svg,
  icons
} from 'src/components/core';

import { ReactElement, useCallback, useRef } from 'react';
import { authRoutes } from 'src/core/routes';
import { color, units } from '@untile/react-components';
import { ifProp } from 'styled-tools';
import { revokeToken } from 'src/api/auth/revoke-token';
import { useAuth } from 'src/context/auth/context';
import { useClientAreaLinks } from 'src/core/content-config/navbar';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { useSnackbar } from 'src/context/snackbar/context';
import styled from 'styled-components';
import useDetectOutsideClick from 'src/hooks/use-detect-outside-click';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  isVisible: boolean;
};

/**
 * `CustomButton` styled component.
 */

const CustomButton = styled.button`
  background: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition: opacity 0.3s ease;

  &:focus,
  &:hover {
    opacity: 0.6;
  }
`;

/**
 * `StyledDropdown` styled component.
 */

const StyledDropdown = styled(Dropdown)<{ visible?: boolean }>`
  opacity: ${ifProp('visible', 1, 0)};
  pointer-events: ${ifProp('visible', 'auto', 'none')};
  transition: opacity 0.3s ease;
`;

/**
 * `StyledSvg` styled component.
 */

const LogoutSvg = styled(Svg).attrs({
  icon: icons.logout,
  size: units(3)
})`
  color: ${color('black')};
`;

/**
 * `LogoutButton` styled component.
 */

const LogoutButton = styled(CustomButton)`
  align-items: center;
  color: ${color('grey800')};
  display: grid;
  grid-column-gap: ${units(2)};
  grid-template-columns: max-content 1fr;
  text-align: left;
  width: 100%;
`;

/**
 * `StyledRouterLink` styled component.
 */

const StyledRouterLink = styled(RouterLink)`
  color: ${color('grey800')};
  width: 100%;
`;

/**
 * `MyProductsDropdown` component.
 */

const MyProductsDropdown = ({ className, isVisible }: Props): ReactElement => {
  const clientAreaLinks = useClientAreaLinks();
  const dropdownRef = useRef();
  const router = useRouter();
  const [active, setActive] = useDetectOutsideClick(dropdownRef, {
    initialState: false
  });

  const { onClearToken, token } = useAuth();
  const { showMessage } = useSnackbar();
  const { locale, translate } = useTranslate();
  const { mutate } = useMutation(revokeToken);
  const handleLogout = useCallback(() => {
    mutate(
      { token },
      {
        onSuccess: () => {
          onClearToken();
          showMessage(translate('common:logout.successMessage'), {
            appearance: 'success'
          });

          router.push(authRoutes.signIn);
        }
      }
    );
  }, [mutate, onClearToken, router, showMessage, token, translate]);

  return (
    <StyledDropdown
      className={className}
      isActive={active}
      onClick={() => setActive(true)}
      ref={dropdownRef}
      renderButton={buttonProps => (
        <IconButton
          {...buttonProps}
          icon={icons.products}
          iconSize={units(3)}
        />
      )}
      setActive={setActive}
      visible={isVisible}
    >
      <DropdownList>
        {clientAreaLinks.map(({ disabled, href, label }, index) => (
          <DropdownListItem
            isActive={router.pathname === href}
            isDisabled={disabled}
            key={index}
          >
            {href ? (
              <StyledRouterLink
                href={href}
                locale={locale}
              >
                {translate(label)}
              </StyledRouterLink>
            ) : (
              translate(label)
            )}
          </DropdownListItem>
        ))}

        <DropdownListItem key={clientAreaLinks.length}>
          <LogoutButton onClick={handleLogout}>
            <LogoutSvg />

            {translate('common:actions.logout')}
          </LogoutButton>
        </DropdownListItem>
      </DropdownList>
    </StyledDropdown>
  );
};

/**
 * Export `MyProductsDropdown` component.
 */

export default MyProductsDropdown;
