/**
 * Module dependencies.
 */

import { getNetworkErrorTranslatedKey } from 'src/core/utils/errors';
import { subscribeNewsletter } from './subscribe-newsletter';
import { unsubscribeNewsletter } from './unsubscribe-newsletter';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useSnackbar } from 'src/context/snackbar/context';
import useTranslate from 'src/hooks/use-translate';

/**
 * `FormDataProps` type.
 */

type FormDataProps = {
  email: string;
  recaptchaToken: string;
};

/**
 * `Options` type.
 */

type Options = {
  hideMessages?: boolean;
};

/**
 * Export `useNewsletter` hook.
 */

export function useNewsletter(options?: Options) {
  const hideMessages = options?.hideMessages;
  const { locale, translate } = useTranslate();
  const { showMessage } = useSnackbar();
  const { isLoading: isSubscribing, mutateAsync: subscribeNewsletterMutation } =
    useMutation(subscribeNewsletter);

  const {
    isLoading: isUnsubscribing,
    mutateAsync: unsubscribeNewsletterMutation
  } = useMutation(unsubscribeNewsletter);

  const handleSubscribeNewsletter = useCallback(
    (formData: FormDataProps) => {
      return subscribeNewsletterMutation(
        { formData, locale },
        {
          onError: ({ status }) => {
            const translatedKey = getNetworkErrorTranslatedKey(
              'newsletter',
              status
            );

            if (!hideMessages) {
              showMessage(translate(`footer:${translatedKey}`), {
                appearance: 'error'
              });
            }
          },
          onSuccess: () => {
            if (!hideMessages) {
              showMessage(
                translate('footer:newsletter.subscribe.successMessage'),
                {
                  appearance: 'success'
                }
              );
            }
          }
        }
      );
    },
    [hideMessages, locale, showMessage, subscribeNewsletterMutation, translate]
  );

  const handleUnsubscribeNewsletter = useCallback(
    (formData: Pick<FormDataProps, 'email' | 'recaptchaToken'>) => {
      return unsubscribeNewsletterMutation(formData, {
        onError: ({ status }) => {
          const translatedKey = getNetworkErrorTranslatedKey(
            'newsletter',
            `${status}`
          );

          showMessage(translate(`footer:${translatedKey}`), {
            appearance: 'error'
          });
        },
        onSuccess: () => {
          showMessage(
            translate('footer:newsletter.unsubscribe.successMessage'),
            {
              appearance: 'success'
            }
          );
        }
      });
    },
    [showMessage, translate, unsubscribeNewsletterMutation]
  );

  return {
    isSubmitting: isSubscribing || isUnsubscribing,
    onSubscribeNewsletter: handleSubscribeNewsletter,
    onUnsubscribeNewsletter: handleUnsubscribeNewsletter
  };
}
