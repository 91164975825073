/**
 * Module dependencies.
 */

/**
 * `SvgExternalLink` component.
 */

const SvgExternalLink: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M28 10a2 2 0 1 1 0-4h12a2 2 0 0 1 2 2v12a2 2 0 1 1-4 0v-7.172L19.414 31.414a2 2 0 1 1-2.828-2.828L35.172 10H28ZM6 14a4 4 0 0 1 4-4h10a2 2 0 1 1 0 4H10v24h24V28a2 2 0 1 1 4 0v10a4 4 0 0 1-4 4H10a4 4 0 0 1-4-4V14Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgExternalLink` component.
 */

export default SvgExternalLink;
