/**
 * Export `i18nRoutes`.
 */

module.exports = {
  en: {
    aboutUs: '/about-us',
    advisor: '/advisor',
    awards: '/awards',
    contactUs: '/contact-us',
    cookiesPolicy: '/legal/cookies-policy',
    customizationTechnology: '/3d-customization/:slug',
    downloads: '/downloads',
    home: '/',
    humanResources: '/human-resources',
    innovation: '/innovation',
    managementPolicy: '/legal/management-policy',
    marketDetails: '/markets/:slug',
    markets: '/markets',
    modelRangeDetails: '/model-range/:slug',
    modelRangeDetailsContact: '/model-range/:slug/contact',
    modelRangeGallery: '/model-range/:slug/gallery',
    newExhibitionDetails: '/news-exhibitions/:slug',
    newsExhibitions: '/news-exhibitions',
    ourStory: '/our-story',
    partnerships: '/partnerships',
    privacyPolicy: '/legal/privacy-policy',
    supportPrograms: '/support-programs',
    sustainability: '/sustainability',
    technologyDetails: '/technologies/:slug',
    technologyDetailsContact: '/technologies/:slug/contact',
    technologyGallery: '/technologies/:slug/gallery',
    termsConditions: '/legal/terms-conditions'
  },
  fr: {
    aboutUs: '/a-propos-de-nous',
    advisor: '/conseiller',
    awards: '/recompenses',
    contactUs: '/contactez-nous',
    cookiesPolicy: '/juridique/politique-de-cookies',
    customizationTechnology: '/3d-customization/:slug',
    downloads: '/telechargements',
    home: '/',
    humanResources: '/ressources-humaines',
    innovation: '/innovation',
    managementPolicy: '/legal/politique-de-gestion',
    marketDetails: '/marches/:slug',
    markets: '/marches',
    modelRangeDetails: '/modele/:slug',
    modelRangeDetailsContact: '/modele/:slug/contact',
    modelRangeGallery: '/modele/:slug/gallery',
    newExhibitionDetails: '/actualites-salons/:slug',
    newsExhibitions: '/actualites-salons',
    ourStory: '/notre-histoire',
    partnerships: '/partenariats',
    privacyPolicy: '/juridique/politique-de-confidentialite',
    supportPrograms: '/programmes-de-soutien',
    sustainability: '/durabilite',
    technologyDetails: '/technologies/:slug',
    technologyDetailsContact: '/technologies/:slug/contact',
    technologyGallery: '/technologies/:slug/gallery',
    termsConditions: '/juridique/conditions-utilisation'
  }
};
