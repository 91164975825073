/**
 * Module dependencies.
 */

import {
  authRequest,
  authorizationHeader,
  handleRequestError
} from 'src/core/utils/requests';

import { getAuthApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * Endpoint.
 */

const endpoint = getAuthApiEndpoint('getUser');

/**
 * `Query` type.
 */

type Query = {
  ssr?: boolean;
  token: string | null | undefined;
};

/**
 * Export `fetchUser`.
 */

export async function fetchUser({ ssr, token }: Query) {
  if (!token) {
    return;
  }

  try {
    const { data } = await authRequest.get(endpoint, {
      headers: authorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    if (!ssr) {
      throw handleRequestError(error);
    }
  }
}
