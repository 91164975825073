/**
 * Module dependencies.
 */

import { FC, ReactElement, ReactNode, forwardRef } from 'react';
import { color, units } from '@untile/react-components';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `CheckboxProps` interface.
 */

export interface CheckboxProps {
  alignment?: 'flex-start' | 'center' | 'flex-end';
  'aria-describedby'?: string;
  'aria-label'?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  label?: ReactNode;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  reverse?: boolean;
  value?: any;
}

/**
 * Checkbox size.
 */

const checkboxSize = units(3);

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<Pick<CheckboxProps, 'disabled'>>`
  padding: ${units(1)} 0;
  position: relative;

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      pointer-events: none;
    `
  )}
`;

/**
 * `CheckmarkWrapper` styled component.
 */

const CheckmarkWrapper = styled.div`
  border: 1px solid ${color('grey400')};
  grid-area: checkbox;
  height: ${checkboxSize};
  transition: border-color ${theme('animations.fastTransition')};
  width: ${checkboxSize};
`;

/**
 * `Checkmark` styled component.
 */

const Checkmark = styled.div`
  height: calc(7 * ${checkboxSize} / 12);
  margin: ${units(0.5)};
  transition: background-color ${theme('animations.fastTransition')};
  width: calc(7 * ${checkboxSize} / 12);
`;

/**
 * `Label` styled component.
 */

const Label = styled.label<
  Pick<CheckboxProps, 'alignment' | 'reverse'> & { hasLabel: boolean }
>`
  align-items: ${prop('alignment', 'center')};
  cursor: pointer;
  display: grid;
  grid-column-gap: ${ifProp('hasLabel', 0, 10)}px;
  grid-template-areas: 'checkbox label';
  grid-template-columns:
    ${checkboxSize} calc(
      100% - ${checkboxSize} - ${ifProp('hasLabel', 0, 10)}px
    )
    ${checkboxSize};

  ${ifProp(
    'reverse',
    css`
      grid-template-areas: 'label checkbox';
      grid-template-columns:
        calc(100% - ${checkboxSize} - ${ifProp('hasLabel', 0, 10)}px)
        ${checkboxSize};
    `
  )}
`;

/**
 * `LabelText` styled component.
 */

const LabelText = styled.span`
  ${theme('typography.styles.small')}

  color: ${color('textColor')};
  grid-area: label;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;
`;

/**
 * `Input` styled component.
 */

const Input = styled.input`
  cursor: pointer;
  grid-area: checkbox;
  height: ${checkboxSize};
  opacity: 0;
  width: ${checkboxSize};
  z-index: 1;

  &:checked {
    & ~ ${CheckmarkWrapper} {
      border-color: ${color('grey400')};

      ${Checkmark} {
        background-color: ${color('grey400')};
      }
    }
  }

  &:focus,
  &:hover {
    & ~ ${LabelText} {
      opacity: 0.75;
    }

    &:not(:checked) {
      & ~ ${CheckmarkWrapper} {
        border-color: ${color('grey400')};

        ${Checkmark} {
          background-color: ${color('grey50')};
        }
      }
    }

    &:checked {
      & ~ ${CheckmarkWrapper} {
        border-color: ${color('grey800')};

        ${Checkmark} {
          background-color: ${color('grey800')};
        }
      }
    }
  }

  ${ifProp(
    'disabled',
    css`
      & ~ ${LabelText} {
        opacity: 0.25;
      }

      & ~ ${CheckmarkWrapper} {
        border-color: ${color('grey100')};
      }

      &:checked {
        & ~ ${CheckmarkWrapper} {
          ${Checkmark} {
            background-color: ${color('grey100')};
          }
        }
      }
    `
  )}
`;

/**
 * `Checkbox` component.
 */

const Checkbox: FC<CheckboxProps> = forwardRef<any, CheckboxProps>(
  (props: CheckboxProps, ref: any): ReactElement => {
    const {
      alignment,
      className,
      disabled,
      id,
      label,
      name,
      reverse,
      ...rest
    } = props;

    return (
      <Wrapper
        className={className}
        disabled={disabled}
      >
        <Label
          alignment={alignment}
          hasLabel={!label}
          reverse={reverse}
        >
          <Input
            disabled={disabled}
            id={id ?? name}
            name={name}
            ref={ref}
            type={'checkbox'}
            {...rest}
          />

          {label && <LabelText>{label}</LabelText>}

          <CheckmarkWrapper>
            <Checkmark />
          </CheckmarkWrapper>
        </Label>
      </Wrapper>
    );
  }
);

/**
 * `Checkbox` display name.
 */

Checkbox.displayName = 'Checkbox';

/**
 * Export `Checkbox` component.
 */

export default Checkbox;
