/**
 * Module dependencies.
 */

import { ElementType, FC, ReactElement, ReactNode, forwardRef } from 'react';
import { color, states, units } from '@untile/react-components/dist/styles';
import { ifProp, switchProp, theme } from 'styled-tools';
import { isExternalUrl } from '@untile/react-components/dist/utils';
import Loading from '../loading';
import RouterLink from '../links/router-link';
import Svg from '../svg';
import styled, { css } from 'styled-components';

/**
 * Export `ButtonColorTheme` type.
 */

export type ButtonColorTheme = 'primary' | 'secondary' | 'tertiary';

/**
 * Export `ButtonSize` type.
 */

export type ButtonSize = 'large' | 'medium' | 'small';

/**
 * Export `ButtonProps` interface.
 */

export interface ButtonProps {
  as?: ElementType;
  children?: ReactNode;
  className?: string;
  colorTheme?: ButtonColorTheme;
  disabled?: boolean;
  href?: string;
  icon?: string | ReactNode | undefined | null;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isReversed?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  rel?: string;
  size?: ButtonSize;
  target?: string;
  type?: string;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs<ButtonProps>(({ as, href, type }) => {
  const isExternal = isExternalUrl(href);
  const element =
    as ||
    (href && !isExternal && RouterLink) ||
    (href && isExternal && 'a') ||
    'button';

  return {
    as: element,
    type: type || (element === 'button' ? 'button' : null)
  };
})`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  border: 1px solid;
  border-radius: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none;
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, border-color, color, opacity, width;
  white-space: nowrap;

  ${states.action`
    outline: none;
    text-decoration: none;
  `}

  ${ifProp(
    'isFullWidth',
    css`
      justify-content: center;
      width: 100%;
    `,
    css`
      width: max-content;
    `
  )}

  ${ifProp(
    'isLoading',
    css`
      cursor: default;
      opacity: 0.8;
      pointer-events: none;
    `
  )}

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      pointer-events: none;
    `
  )}

  ${switchProp('colorTheme', {
    primary: css`
      background-color: ${color('grey800')};
      border-color: ${color('grey800')};
      color: ${color('white')};

      &:focus,
      &:hover {
        background-color: ${color('grey600')};
        border-color: ${color('grey600')};
      }

      ${ifProp(
        'disabled',
        css`
          background-color: ${color('grey200')};
          border-color: ${color('grey200')};
        `
      )}
    `,
    secondary: css`
      background-color: transparent;
      border-color: ${color('grey800')};
      color: ${color('black')};

      &:focus,
      &:hover {
        border-color: ${color('grey600')};
        color: ${color('grey600')};
      }

      ${ifProp(
        'disabled',
        css`
          border-color: ${color('grey200')};
          color: ${color('grey200')};
        `
      )}
    `,
    tertiary: css`
      background-color: transparent;
      border: none;
      border-bottom: 1px solid ${color('grey800')};
      color: ${color('grey800')};
      font-weight: 500;
      padding: 0 !important;

      &:focus,
      &:hover {
        border-bottom: 1px solid ${color('grey600')};
        color: ${color('grey600')};
      }

      ${ifProp(
        'disabled',
        css`
          border-bottom: 1px solid ${color('grey200')};
          color: ${color('grey200')};
        `
      )}
    `
  })}

  ${switchProp('size', {
    large: css`
      min-height: ${units(7)};
      padding: 17px 15px 13px;
    `,
    medium: css`
      min-height: ${units(5)};
      padding: ${units(1)} 15px 6px;
    `,
    small: css`
      min-height: ${units(3)};
      padding: 1px 15px;
    `
  })}
`;

/**
 * `InnerWrapper` styled component.
 */

const InnerWrapper = styled.span<{
  isLoading?: boolean;
  isReversed?: boolean;
  size: ButtonSize;
}>`
  align-items: center;
  display: flex;
  flex-direction: ${ifProp('isReversed', 'row-reverse', 'row')};
  font-family: ${theme('typography.fontFamily.sansSerif')};
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.1rem;
  line-height: 24px;
  opacity: ${ifProp('isLoading', 0.5, 1)};
  text-transform: uppercase;
  transition: opacity ${theme('animations.defaultTransition')};

  ${switchProp('size', {
    large: css`
      font-size: 16px;
    `,
    medium: css`
      font-size: 14px;
    `,
    small: css`
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    `
  })}
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)<{
  hasMargin?: boolean;
  isReversed?: boolean;
}>`
  ${ifProp(
    'hasMargin',
    css`
      ${ifProp(
        'isReversed',
        css`
          margin-left: ${units(1)};
        `,
        css`
          margin-right: ${units(1)};
        `
      )}
    `
  )}
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading).attrs({
  relative: true,
  size: 16
})<{
  hasMargin?: boolean;
  isReversed?: boolean;
}>`
  ${ifProp(
    'hasMargin',
    css`
      ${ifProp(
        'isReversed',
        css`
          margin: ${units(0.5)} ${units(0.5)} ${units(0.5)} ${units(1.5)};
        `,
        css`
          margin: ${units(0.5)} ${units(1.5)} ${units(0.5)} ${units(0.5)};
        `
      )}
    `,
    css`
      margin: ${units(0.5)};
    `
  )}
`;

/**
 * `Button` component.
 */

const Button: FC<ButtonProps> = forwardRef<any, ButtonProps>(
  (props: ButtonProps, ref: any): ReactElement => {
    const {
      children,
      colorTheme = 'primary',
      disabled,
      icon,
      isLoading,
      isReversed,
      size = 'large',
      ...rest
    } = props;

    const iconSize = (size === 'small' && units(2)) || units(3);

    return (
      <Wrapper
        colorTheme={colorTheme}
        disabled={disabled || isLoading}
        ref={ref}
        size={size}
        {...rest}
      >
        <InnerWrapper
          isLoading={isLoading}
          isReversed={isReversed}
          size={size}
        >
          {isLoading ? (
            <StyledLoading
              active
              hasMargin={!!children}
              isReversed={isReversed}
            />
          ) : (
            icon && (
              <Icon
                hasMargin={!!children}
                icon={icon}
                isReversed={isReversed}
                size={iconSize}
              />
            )
          )}

          {children}
        </InnerWrapper>
      </Wrapper>
    );
  }
);

/**
 * `Button` display name.
 */

Button.displayName = 'Button';

/**
 * Export `Button` component.
 */

export default Button;
