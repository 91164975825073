/**
 * Auth API endpoints.
 */

export const authApiEndpoints = {
  changePassword: '/users/password/change',
  confirmAccount: '/users/confirm',
  createToken: '/auth/token',
  createUser: '/users',
  getUser: '/users/me',
  recoverPassword: '/users/password/forgot',
  resendConfirmationEmail: '/me/confirm/resend',
  resetPassword: '/users/password/reset',
  revokeToken: '/auth/revoke',
  updateUser: '/users/me'
};
