/**
 * Module dependencies.
 */

import { ExternalLink, RouterLink, Svg, Type } from 'src/components/core';
import { Fragment, ReactElement, useMemo } from 'react';
import { MenuOption, useMenuFooterLinks } from 'src/core/content-config/menu';
import {
  color,
  isExternalUrl,
  media,
  units,
  useBreakpoint
} from '@untile/react-components';

import { portugal2020Link, useFooter } from 'src/core/content-config/footer';
import { theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import Container from 'src/components/core/layout/container';
import Newsletter from './newsletter';
import awardsLogo from 'src/assets/svg/awards.svg';
import map from 'lodash/map';
import portugal2020Logo from 'src/assets/svg/portugal-2020.svg';
import styled from 'styled-components';
import union from 'lodash/union';
import useMarkets from 'src/api/markets/use-markets';
import useTranslate from 'src/hooks/use-translate';

/**
 * Contansts.
 */

const currentYear = new Date().getFullYear();
const untileWebsiteUrl = 'https://untile.pt';

/**
 * `Section` styled component.
 */

const Section = styled.footer`
  background-color: ${color('grey25')};
  padding: ${units(8)} 0;

  ${media.min('md')`
    padding: ${units(10)} 0;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'logo'
    'newsletter'
    'portugal2020'
    'links';
  grid-template-columns: 100%;

  ${media.min('md')`
    grid-template-areas:
      '. menu  . newsletter   .'
      '. links . portugal2020 .';
    grid-template-columns: 1fr 6fr ${theme('grid.columnsGutter')}px 4fr 1fr;
    grid-template-rows: max-content 1fr max-content;
  `}
`;

/**
 * `MenuWrapper` styled component.
 */

const MenuWrapper = styled.div`
  display: grid;
  grid-area: menu;
  grid-auto-rows: max-content;
  grid-column-gap: ${theme('grid.columnsGutter')}px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `Group` styled component.
 */

const Group = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

/**
 * `GroupName` styled component.
 */

const GroupName = styled(Type.Small)`
  color: ${color('grey400')};

  ${media.min('md')`
    margin-bottom: ${units(3)};
  `}
`;

/**
 * `GroupList` styled component.
 */

const GroupList = styled.ul`
  display: grid;
  grid-auto-columns: 100%;

  ${media.min('md')`
    grid-row-gap: ${units(1)};
  `}
`;

/**
 * `MenuItemLink` styled component.
 */

const MenuItemLink = styled.a.attrs(({ href }) => ({
  as:
    (href && !isExternalUrl(href) && RouterLink) ||
    (href && isExternalUrl(href) && ExternalLink) ||
    'a'
}))`
  ${theme('typography.styles.p')}

  align-items: center;
  color: ${color('grey800')};
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: opacity ${theme('animations.defaultTransition')};

  &:hover {
    opacity: 0.6;
  }
`;

/**
 * `NewsletterWrapper` styled component.
 */

const NewsletterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: newsletter;
`;

/**
 * `NewsletterTitle` styled component.
 */

const NewsletterTitle = styled(Type.H4)`
  color: ${color('black')};
  margin-bottom: ${units(3)};
`;

/**
 * `AwardsLogo` styled component.
 */

const AwardsLogo = styled(Svg).attrs({
  icon: awardsLogo,
  size: '195px'
})`
  margin-bottom: ${units(4)};
  width: 100%;

  ${media.min('md')`
    align-self: end;
    margin-bottom: ${units(11.5)};
  `}
`;

/**
 * `Portugal2020Logo` styled component.
 */

const Portugal2020Logo = styled(Svg).attrs({
  icon: portugal2020Logo,
  size: units(57.5)
})`
  max-width: 100%;
  transition: opacity ${theme('animations.defaultTransition')};

  ${media.max('md')`
    margin-bottom: ${units(4)};
  `}
`;

/**
 * `Portugal2020Link` styled component.
 */

const Portugal2020Link = styled.a.attrs(({ href }) => ({
  as: (href && !isExternalUrl(href) && RouterLink) || 'a'
}))`
  cursor: pointer;
  display: flex;
  grid-area: portugal2020;
  justify-content: flex-end;
  text-decoration: none;

  ${media.max('md')`
    justify-content: flex-start;
  `}

  &:focus,
  &:hover {
    ${Portugal2020Logo} {
      opacity: 0.8;
    }
  }
`;

/**
 * `FooterLinksWrapper` styled component.
 */

const FooterLinksWrapper = styled.div`
  ${theme('typography.styles.small')}

  align-self: end;
  color: ${color('grey800')};
  grid-area: links;

  ${media.min('md')`
    color: ${color('grey400')};
  `}
`;

/**
 * `FooterLink` styled component.
 */

const FooterLink = styled.a.attrs(({ href }) => ({
  as: (href && !isExternalUrl(href) && RouterLink) || 'a'
}))`
  ${theme('typography.styles.small')}

  color: ${color('grey800')};
  cursor: pointer;
  text-decoration: none;
  transition: opacity ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    opacity: 0.8;
  }

  ${media.min('md')`
    color: ${color('grey400')};
  `}
`;

/**
 * `Footer` component.
 */

const Footer = (): ReactElement => {
  const { locale, translate } = useTranslate();
  const isMobile = useBreakpoint('ms', 'max');
  const footer = useFooter();
  const routeResolver = useLocalizedRoute();
  const menuFooterLinks = useMenuFooterLinks();
  const animProps = {
    'data-scroll': 'fade-in-up',
    'data-scroll-offset': '10%'
  };

  const { data } = useMarkets();
  const normalizedFooter = useMemo(() => {
    const normalizedFooter = footer;
    const markets: MenuOption[] = map(data, ({ slug, title }) => ({
      href: routeResolver('marketDetails', { slug }),
      isHighlighted: true,
      label: title
    }));

    normalizedFooter[1].options = union(markets, footer[1].options);

    return normalizedFooter;
  }, [data, footer, routeResolver]);

  return (
    <Section>
      <Container fluid>
        <Grid>
          <MenuWrapper>
            {map(normalizedFooter, ({ options, title }, index) => (
              <Group key={index}>
                <GroupName {...animProps}>{translate(title)}</GroupName>

                <GroupList>
                  {map(options, ({ href, label }, optionIndex) => (
                    <li
                      {...animProps}
                      key={optionIndex}
                    >
                      <MenuItemLink
                        href={href}
                        {...(href && !isExternalUrl(href) && { locale })}
                      >
                        {translate(label)}
                      </MenuItemLink>
                    </li>
                  ))}
                </GroupList>
              </Group>
            ))}
          </MenuWrapper>

          <NewsletterWrapper>
            <NewsletterTitle {...animProps}>
              {translate('footer:newsletter.title')}
            </NewsletterTitle>

            <div {...animProps}>
              <Newsletter />
            </div>

            <AwardsLogo
              data-scroll={'fade-in-up'}
              data-scroll-animation-delay={'150'}
              data-scroll-offset={'10%'}
            />
          </NewsletterWrapper>

          <Portugal2020Link
            aria-label={translate('common:labels.supportPrograms')}
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={'150'}
            href={portugal2020Link}
            {...(portugal2020Link &&
              !isExternalUrl(portugal2020Link) && { locale })}
          >
            <Portugal2020Logo />
          </Portugal2020Link>

          <FooterLinksWrapper
            data-scroll={'fade-in-up'}
            data-scroll-animation-delay={'300'}
          >
            <Type.Small>
              {translate('footer:allRightsReserved', { year: currentYear })}
            </Type.Small>

            {map(menuFooterLinks, ({ href, label }, index) => (
              <Fragment key={index}>
                {!isMobile && ' | '}

                {isMobile && (index > 0 ? ' | ' : <br />)}

                <FooterLink
                  href={href}
                  key={index}
                  {...(href && !isExternalUrl(href)
                    ? { locale }
                    : {
                        rel: 'noopener',
                        target: '_blank'
                      })}
                >
                  {translate(label)}
                </FooterLink>
              </Fragment>
            ))}

            {!isMobile ? ' | ' : <br />}

            <FooterLink
              href={untileWebsiteUrl}
              key={'untile-website'}
              rel={'noopener'}
              target={'_blank'}
            >
              {translate('footer:byUntile')}
            </FooterLink>
          </FooterLinksWrapper>
        </Grid>
      </Container>
    </Section>
  );
};

/**
 * Export `Footer` component.
 */

export default Footer;
