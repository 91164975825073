/**
 * Module dependencies.
 */

import { Button, RouterLink, Type } from 'src/components/core';
import { CookiePreferences } from 'src/types/cookies';
import { CookiesDrawer } from './drawer';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { color, media, units } from '@untile/react-components';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { switchProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import Script from 'next/script';
import Trans from 'next-translate/Trans';
import Transition, {
  TransitionStatus
} from 'react-transition-group/Transition';

import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * Google tag manager dd.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * Cookie name.
 */

const cookieName = 'cookie-preferences';

/**
 * Duration.
 */

const duration = 500;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ transitionState: TransitionStatus }>`
  bottom: ${units(2.5)};
  box-shadow: 0 12px 33px rgba(0, 0, 0, 0.15);
  left: ${units(2.5)};
  max-width: calc(100vw - ${units(5)});
  opacity: 0;
  position: fixed;
  right: ${units(2.5)};
  transform: translateX(-100%);
  transition: ${theme('animations.defaultTransition')};
  transition-delay: 0s 0.35s;
  transition-property: transform, opacity;
  width: 928px;
  z-index: ${theme('zIndex.cookiesBar')};

  ${media.min('ms')`
    bottom: ${units(4)};
    left: ${units(4)};
    max-width: calc(100vw - ${units(8)});
  `}

  ${switchProp('transitionState', {
    entered: css`
      opacity: 1;
      transform: translateX(0);
    `,
    entering: css`
      opacity: 1;
      transform: translateX(0);
    `,
    exited: css`
      opacity: 0;
      transform: translateX(-100%);
    `,
    exiting: css`
      opacity: 0;
      transform: translateX(-100%);
    `
  })}
`;

/**
 * `Message` styled component.
 */

const Message = styled.div`
  background-color: ${color('white')};
  display: flex;
  flex-direction: column;
  padding: ${units(3)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H4)`
  margin-bottom: ${units(2)};
`;

/**
 * `DescriptionWrapper` styled component.
 */

const DescriptionWrapper = styled.div`
  ${theme('typography.styles.small')}

  margin: 0 0 ${units(2)};

  > br {
    margin-bottom: ${units(1)};
  }
`;

/**
 * `ButtonsBar` styled component.
 */

const ButtonsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${units(2)};

  ${media.max('ms')`
    flex-direction: column;
  `}
`;

/**
 * `StyledRouterLink` styled component.
 */

const StyledRouterLink = styled(RouterLink)`
  color: ${color('textColor')};
  text-decoration: underline;
  transition: color ${theme('animations.defaultTransition')};
  white-space: nowrap;

  &:focus,
  &:hover {
    color: ${color.transparentize('textColor', 0.6)};
  }
`;

/**
 * `Description` component.
 */

const Description = ({ locale }): ReactElement => {
  const { translate } = useTranslate();
  const routeResolver = useLocalizedRoute();

  return (
    <DescriptionWrapper>
      <Trans
        components={[
          <br key={'break'} />,
          <StyledRouterLink
            href={routeResolver('privacyPolicy')}
            key={'privacy-policy-link'}
            locale={locale}
          >
            {translate('menu:privacyPolicy').toLowerCase()}
          </StyledRouterLink>,
          <StyledRouterLink
            href={routeResolver('cookiesPolicy')}
            key={'cookies-policy-link'}
            locale={locale}
          >
            {translate('menu:cookiesPolicy').toLowerCase()}
          </StyledRouterLink>
        ]}
        i18nKey={'common:cookiesBar.description'}
      />
    </DescriptionWrapper>
  );
};

/**
 * `CookiesBar` component.
 */

const CookiesBar = (): ReactElement | null => {
  const { locale, translate } = useTranslate();
  const cookies = parseCookies();
  const [isInit, setIsInit] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [value, setValue] = useState<CookiePreferences | null>(null);
  const handleAcceptTerms = useCallback((value: CookiePreferences) => {
    setValue(value);
    setCookie(null, cookieName, JSON.stringify(value), {
      maxAge: 30 * 24 * 60 * 60,
      path: '/'
    });
  }, []);

  useEffect(() => {
    if(isInit) {
      return;
    }

    setIsInit(true);

    if (cookies[cookieName]) {
      try {
        setValue(JSON.parse(cookies[cookieName]));
      } catch {
        destroyCookie(null, cookieName);
      }
    }
  }, [cookies, isInit]);

  return (
    <>
      {!!googleTagManagerId && value?.advertising && value?.performance && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`}
            strategy={'beforeInteractive'}
          />

          <Script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line id-match
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${googleTagManagerId}');
              `
            }}
            id={'gta'}
            strategy={'lazyOnload'}
          />
        </>
      )}

      <Transition
        in={isInit && value === null}
        mountOnEnter
        timeout={duration}
        unmountOnExit
      >
        {state => (
          <>
            <Wrapper transitionState={state}>
              <Message>
                <Title>{translate('common:cookiesBar.title')}</Title>

                <Description locale={locale} />

                <ButtonsBar>
                  <Button
                    onClick={() =>
                      handleAcceptTerms({
                        advertising: true,
                        functional: true,
                        performance: true
                      })
                    }
                    size={'medium'}
                  >
                    {translate('common:actions.acceptAll')}
                  </Button>

                  <Button
                    colorTheme={'secondary'}
                    onClick={() =>
                      handleAcceptTerms({
                        advertising: false,
                        functional: false,
                        performance: false
                      })
                    }
                    size={'medium'}
                  >
                    {translate('common:actions.acceptOnlyNecessary')}
                  </Button>

                  <Button
                    colorTheme={'secondary'}
                    onClick={() => setIsDrawerOpen(true)}
                    size={'medium'}
                  >
                    {translate('common:actions.adjustMyPreferences')}
                  </Button>
                </ButtonsBar>
              </Message>
            </Wrapper>

            <CookiesDrawer
              isOpen={isDrawerOpen}
              onAcceptTerms={handleAcceptTerms}
              onClose={() => setIsDrawerOpen(false)}
            />
          </>
        )}
      </Transition>
    </>
  );
};

/**
 * Export `CookiesBar` component.
 */

export default CookiesBar;
