/**
 * Module dependencies.
 */

import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';

/**
 * `TranslateProps` type.
 */

type TranslateProps = {
  locale: string;
  translate: Translate;
};

/**
 * `useTranslate` hook.
 */

function useTranslate(namespace?: string): TranslateProps {
  const {
    lang,
    t, // eslint-disable-line id-length
    ...rest
  } = useTranslation(namespace);

  return {
    ...rest,
    locale: lang,
    translate: t
  };
}

/**
 * Export `useTranslate` hook.
 */

export default useTranslate;
