/**
 * Module dependencies.
 */

import { theme } from 'src/styles/theme';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMemo } from 'react';

/**
 * Export `PageTransition` type.
 */

export type PageTransition = {
  bgColor: string;
  route: string;
};

/**
 * Export `colorsCombinations`.
 */

export const colorsCombinations = {
  [theme.colors.backgrounds.blue02]: theme.colors.backgrounds.blue03,
  [theme.colors.backgrounds.green02]: theme.colors.backgrounds.green03,
  [theme.colors.backgrounds.orange02]: theme.colors.backgrounds.orange03,
  [theme.colors.backgrounds.sage02]: theme.colors.backgrounds.sage03,
  [theme.colors.backgrounds.taupe02]: theme.colors.backgrounds.taupe03,
  [theme.colors.grey100]: theme.colors.grey50
};

export function usePageTransitionConfig(): PageTransition[] {
  const routeResolver = useLocalizedRoute();

  return useMemo(() => {
    return [
      {
        bgColor: theme.colors.backgrounds.sage02,
        route: routeResolver('aboutUs')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('awards')
      },
      {
        bgColor: theme.colors.backgrounds.sage02,
        route: routeResolver('contactUs')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('cookiesPolicy')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('downloads')
      },
      {
        bgColor: theme.colors.grey100,
        route: routeResolver('home')
      },
      {
        bgColor: theme.colors.backgrounds.blue02,
        route: routeResolver('humanResources')
      },
      {
        bgColor: theme.colors.backgrounds.orange02,
        route: routeResolver('innovation')
      },
      {
        bgColor: theme.colors.grey50,
        route: routeResolver('markets')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('newExhibitionDetails')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('newsExhibitions')
      },
      {
        bgColor: theme.colors.backgrounds.blue02,
        route: routeResolver('ourStory')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('partnerships')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('supportPrograms')
      },
      {
        bgColor: theme.colors.backgrounds.green02,
        route: routeResolver('sustainability')
      },
      {
        bgColor: theme.colors.backgrounds.taupe02,
        route: routeResolver('termsConditions')
      }
    ];
  }, [routeResolver]);
}
