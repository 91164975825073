/**
 * Module dependencies.
 */

import { color } from '@untile/react-components/dist/styles';
import { createGlobalStyle } from 'styled-components';
import { typography } from 'src/styles/type';

/**
 * Export `GlobalStyle` component.
 */

export default createGlobalStyle`
  body {
    ${typography.styles.p}

    color: ${color('textColor')};
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
