/**
 * Module dependencies.
 */

import { Market } from 'src/types/markets';
import {
  QueryObserverBaseResult,
  QueryObserverOptions,
  useQuery
} from 'react-query';

import { fetchMarkets } from './fetch-markets';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

/**
 * `Result` type.
 */

type Result = QueryObserverBaseResult<Market[], unknown>;

/**
 * `Options` type.
 */

type Options = QueryObserverOptions<Market[]>;

/**
 * Action type.
 */

const actionType = ({ locale }): string => {
  return `${locale}-markets`;
};

/**
 * `useMarkets` hook.
 */

function useMarkets(options?: Options): Result {
  const { locale } = useRouter();
  const variables = useMemo(() => ({ locale }), [locale]);
  const result = useQuery(
    [actionType(variables), variables],
    () => {
      return fetchMarkets(variables);
    },
    options
  );

  return result;
}

/**
 * Export `useMarkets` hook.
 */

export default useMarkets;
