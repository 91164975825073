/**
 * Module dependencies.
 */

import { Box, Type } from '@untile/react-components';
import { switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `TypeBaseComponent` styled component.
 */

const TypeBaseComponent = styled(Box)`
  font-feature-settings: 'calt', 'clig', 'kern', 'liga', 'locl', 'rlig';
`;

/**
 * `Display` styled component.
 */

const Display = styled(TypeBaseComponent).attrs(({ size }) => ({
  size: size ?? 'large'
}))`
  ${switchProp('size', {
    large: css`
      ${theme('typography.otherStyles.display1')}
    `,
    medium: css`
      ${theme('typography.otherStyles.display2')}
    `
  })}
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(TypeBaseComponent).attrs(({ size }) => ({
  size: size ?? 'medium'
}))`
  ${switchProp('size', {
    large: css`
      ${theme('typography.otherStyles.subtitle1')}
    `,
    medium: css`
      ${theme('typography.otherStyles.subtitle2')}
    `
  })}
`;

/**
 * Export `Type` component.
 */

export { Display, Subtitle, Type };
