/**
 * Module dependencies.
 */

import { AuthContext } from './context';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { Token } from 'src/core/utils/requests';
import { User } from 'src/types/user';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import useUser from 'src/api/user/use-user';

/**
 * `InitialData` type.
 */

type InitialData = {
  isAuthenticated: boolean;
  token?: Token;
  user: User | null;
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  initialData?: InitialData;
};

/**
 * `AuthProvider` provider.
 */

const AuthProvider = ({ children, initialData }: Props): ReactElement => {
  const cookies = parseCookies();
  const cookieToken = initialData?.token ?? cookies?.token;
  const [isAuthenticated, setIsAuthenticated] = useState(
    initialData?.isAuthenticated
  );

  const [token, setToken] = useState<Token>(cookieToken);
  const [user, setUser] = useState(initialData?.user);
  const { refetch: updateUser, remove: removeUser } = useUser(token, {
    enabled: !!token,
    initialData: initialData?.user,
    onSuccess: data => {
      setUser(data);
    }
  });

  // Update token.
  const handleUpdateToken = useCallback((token: Token) => {
    setToken(token);
    setCookie(null, 'token', token, {
      maxAge: 3650 * 24 * 60 * 60,
      path: '/'
    });
  }, []);

  // Clear token.
  const handleClearToken = useCallback(() => {
    setToken(null);
    destroyCookie(null, 'token', { path: '/' });
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated,
      onClearToken: handleClearToken,
      onUpdateToken: handleUpdateToken,
      token,
      updateUser,
      user
    }),
    [
      handleClearToken,
      handleUpdateToken,
      isAuthenticated,
      token,
      updateUser,
      user
    ]
  );

  useEffect(() => {
    if (user && token && !isAuthenticated) {
      setIsAuthenticated(true);
    }

    if (!token && user) {
      removeUser();
      setUser(null);
    }

    if (!token) {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated, removeUser, token, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 * Export `AuthProvider` provider.
 */

export default AuthProvider;
