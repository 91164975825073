
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    /**
 * Module dependencies.
 */
import { GlobalStyle as AmtrolAlfaGlobalStyle } from 'src/components/core';
import { CookiePreferences } from 'src/types/cookies';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactElement } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle as UntileGlobalStyle, createTheme, isProduction } from '@untile/react-components';
import { fetchPageFields } from 'src/api/page-fields/fetch-page-fields';
import { serverAuth } from 'src/core/utils/session';
import { theme } from 'src/styles/theme';
import { useRouter } from 'next/router';
import App, { AppContext, AppProps } from 'next/app';
import AuthProvider from 'src/context/auth/provider';
import FavoritesProvider from 'src/context/favorites/provider';
import GlobalStyle from 'src/components/core/global-style';
import GoogleTagManager from 'src/components/core/analytics/google-tag-manager';
import GridDebug from 'src/components/core/debug/grid';
import Head from 'next/head';
import Layout from 'src/components/layout';
import SnackbarProvider from 'src/context/snackbar/provider';
import countries from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import frCountries from 'i18n-iso-countries/langs/fr.json';
import nookies from 'nookies';
import packageJson from 'package.json';
/**
 * `CustomAppProps` type.
 */
type CustomAppProps = AppProps & {
    hasDoubleLogo: boolean;
    user: any;
};
/**
 * Query cache.
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});
/**
 * ReCaptchaKey key.
 */
const reCaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
/**
 * Register countries.
 */
countries.registerLocale(enCountries);
countries.registerLocale(frCountries);
/**
 * `PageApp` page.
 */
const PageApp = (props: CustomAppProps & {
    err: any;
}): ReactElement => {
    const { Component, err, hasDoubleLogo, pageProps, user } = props;
    const { locale } = useRouter();
    return (<>
      <Head>
        <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'}/>

        <meta content={'text/html;charset=utf-8'} httpEquiv={'Content-Type'}/>

        <meta content={'width=device-width, initial-scale=1'} name={'viewport'}/>

        <meta content={isProduction() ? "all" : "noindex,nofollow"} name={"robots"}/>

        <meta content={packageJson.version} name={'version'}/>

        <meta content={'true'} name={'HandheldFriendly'}/>

        <link href={'/static/favicons/apple-touch-icon.png'} rel={'apple-touch-icon'} sizes={'180x180'}/>

        <link href={'/static/favicons/favicon-32x32.png'} rel={'icon'} sizes={'32x32'} type={'image/png'}/>

        <link href={'/static/favicons/favicon-16x16.png'} rel={'icon'} sizes={'16x16'} type={'image/png'}/>

        <link href={'/favicon.ico'} rel={'shortcut icon'} type={'image/x-icon'}/>

        <link href={'/site.webmanifest'} rel={'manifest'}/>

        <link color={'#faf7ec'} href={'/static/favicons/safari-pinned-tab.svg'} rel={'mask-icon'}/>

        <meta content={'#faf7ec'} name={'msapplication-TileColor'}/>

        <meta content={'#faf7ec'} name={'theme-color'}/>

        <script 
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `history.scrollRestoration = "manual"`
        }}/>
      </Head>

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <GoogleTagManager>
            <ThemeProvider theme={createTheme(theme)}>
              <AuthProvider initialData={user}>
                <GoogleReCaptchaProvider language={locale} reCaptchaKey={reCaptchaKey}>
                  <SnackbarProvider>
                    <FavoritesProvider>
                      <UntileGlobalStyle />

                      <AmtrolAlfaGlobalStyle />

                      <GlobalStyle />

                      {!isProduction() && <GridDebug columns={12} gap={14}/>}

                      <Layout hasDoubleLogo={hasDoubleLogo} hasFooter={pageProps.hasFooter} hasMobileScrollDownIndicator={pageProps.hasMobileScrollDownIndicator} hasNavbar={pageProps.hasNavbar} hasScrollDownIndicator={pageProps.hasScrollDownIndicator} hasSectionsNavigation={pageProps.hasSectionsNavigation}>
                        <Component {...pageProps} err={err}/>
                      </Layout>
                    </FavoritesProvider>
                  </SnackbarProvider>
                </GoogleReCaptchaProvider>
              </AuthProvider>
            </ThemeProvider>
          </GoogleTagManager>
        </Hydrate>

        <ReactQueryDevtools initialIsOpen={!isProduction()}/>
      </QueryClientProvider>
    </>);
};
/**
 * Get initial props.
 */
PageApp.getInitialProps = async (appContext: AppContext) => {
    const { ctx, router } = appContext;
    const pageFields = await fetchPageFields({ locale: ctx.locale });
    const cookiePreferences: Partial<CookiePreferences> = (() => {
        const cookies = nookies.get(ctx);
        try {
            return JSON.parse(cookies['cookie-preferences']);
        }
        catch {
            nookies.destroy(ctx, 'cookie-preferences');
            return {};
        }
    })();
    if (cookiePreferences?.functional) {
        // Remove old locale cookie.
        // Only for production.
        nookies.destroy(ctx, 'NEXT_LOCALE', {
            domain: 'amtrol-alfa.com',
            path: '/'
        });
        // Set `locale` cookie.
        nookies.set(ctx, 'NEXT_LOCALE', router?.locale, {
            maxAge: 3650 * 24 * 60 * 60,
            path: '/'
        });
    }
    const user = await serverAuth(ctx);
    return {
        ...(await App.getInitialProps(appContext)),
        ...(pageFields && { hasDoubleLogo: pageFields?.homepageSecondLogo }),
        user
    };
};
const __Next_Translate__Page__191034ad85b__ = PageApp;

    export default __appWithI18n(__Next_Translate__Page__191034ad85b__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  