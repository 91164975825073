/**
 * Module dependencies.
 */

import { FC, ReactElement, forwardRef, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { icons } from '../../icons';
import Input, { InputProps } from './input';

/**
 * Export `PasswordProps` type.
 */

export type PasswordProps = Omit<InputProps, 'icon' | 'onClickIcon'>;

/**
 * `GlobalStyles` styled component.
 */

const GlobalStyles = createGlobalStyle`
  input[type='password']::-ms-reveal {
    display: none;
  }
`;

/**
 * `Password` component.
 */

const Password: FC<PasswordProps> = forwardRef(
  (props: PasswordProps, ref: any): ReactElement => {
    const [showPassword, setShowPassword] = useState<boolean>();

    return (
      <>
        <GlobalStyles />

        <Input
          {...props}
          icon={showPassword ? icons.eye : icons.eyeOff}
          onClickIcon={() => setShowPassword(!showPassword)}
          ref={ref}
          type={showPassword ? 'text' : 'password'}
        />
      </>
    );
  }
);

/**
 * `Password` display name.
 */

Password.displayName = 'Password';

/**
 * Export `Password` component.
 */

export default Password;
