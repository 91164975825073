/**
 * Module dependencies.
 */

import { FC, ReactElement, ReactNode, isValidElement } from 'react';
import { Svg as UntileSvg } from '@untile/react-components';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `SvgProps` interface.
 */

export interface SvgProps {
  className?: string;
  color?: string;
  icon: string | ReactNode;
  size: string | unknown;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span`
  display: inline-block;
  line-height: 0;
  max-width: ${prop('size')};
  position: relative;
  width: ${prop('size')};

  ${ifProp(
    'color',
    css`
      color: ${prop('color')};
    `
  )}
`;

/**
 * `Svg` component.
 */

const Svg: FC<SvgProps> = ({ icon, ...rest }: SvgProps): ReactElement => {
  return isValidElement(icon) ? (
    <Wrapper {...rest}>{icon}</Wrapper>
  ) : (
    <UntileSvg
      {...rest}
      icon={icon as string}
    />
  );
};

/**
 * Export `Svg` component.
 */

export default Svg;
