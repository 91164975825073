/**
 * Module dependencies.
 */

import { FC, ReactElement, ReactNode, SyntheticEvent, forwardRef } from 'react';
import { Type } from '../../typography';
import { color, units } from '@untile/react-components';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `RadioProps` interface.
 */

export interface RadioProps {
  alignment?: 'flex-start' | 'center' | 'flex-end';
  'aria-describedby'?: string;
  'aria-label'?: string;
  checked?: boolean;
  className?: string;
  defaultChecked?: boolean;
  description?: string;
  disabled?: boolean;
  label?: ReactNode;
  name: string;
  onChange?: (event: SyntheticEvent) => void;
  reverse?: boolean;
  value?: any;
}

/**
 * Radio size.
 */

const radioSize = units(3);

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<Pick<RadioProps, 'disabled'>>`
  position: relative;

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      pointer-events: none;
    `
  )}
`;

/**
 * `BulletWrapper` styled component.
 */

const BulletWrapper = styled.div`
  background-color: transparent;
  border: 1px solid ${color('grey400')};
  border-radius: 50%;
  grid-area: radio;
  height: ${radioSize};
  position: relative;
  transition: border-color ${theme('animations.fastTransition')};
  width: ${radioSize};
`;

/**
 * `Bullet` styled component.
 */

const Bullet = styled.div`
  background-color: transparent;
  border-radius: 50%;
  height: ${units(2)};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: background-color ${theme('animations.fastTransition')};
  width: ${units(2)};
`;

/**
 * `Label` styled component.
 */

const Label = styled.label<
  Pick<RadioProps, 'alignment' | 'reverse'> & { hasLabel: boolean }
>`
  align-items: ${prop('alignment', 'center')};
  cursor: pointer;
  display: grid;
  grid-column-gap: ${ifProp('hasLabel', 0, 10)}px;
  grid-template-areas:
    'radio label'
    '.     description';
  grid-template-columns: ${radioSize} calc(
      100% - ${radioSize} - ${ifProp('hasLabel', 0, 10)}px
    );

  ${ifProp(
    'reverse',
    css`
      grid-template-areas: 'label radio';
      grid-template-columns:
        calc(100% - ${radioSize} - ${ifProp('hasLabel', 0, 10)}px)
        ${radioSize};
    `
  )}
`;

/**
 * `LabelText` styled component.
 */

const LabelText = styled.span`
  color: ${color('textColor')};
  grid-area: label;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;
`;

/**
 * `Input` styled component.
 */

const Input = styled.input`
  cursor: pointer;
  grid-area: radio;
  height: ${radioSize};
  opacity: 0;
  width: ${radioSize};
  z-index: 1;

  &:checked {
    & ~ ${BulletWrapper} {
      border-color: ${color('grey400')};

      ${Bullet} {
        background-color: ${color('grey400')};
      }
    }
  }

  &:focus,
  &:hover {
    & ~ ${LabelText} {
      opacity: 0.75;
    }

    &:not(:checked) {
      & ~ ${BulletWrapper} {
        border-color: ${color('grey400')};

        ${Bullet} {
          background-color: ${color('grey50')};
        }
      }
    }

    &:checked {
      & ~ ${BulletWrapper} {
        border-color: ${color('grey800')};

        ${Bullet} {
          background-color: ${color('grey800')};
        }
      }
    }
  }

  ${ifProp(
    'disabled',
    css`
      & ~ ${LabelText} {
        opacity: 0.25;
      }

      & ~ ${BulletWrapper} {
        border-color: ${color('grey100')};
      }

      &:checked {
        & ~ ${BulletWrapper} {
          ${Bullet} {
            background-color: ${color('grey100')};
          }
        }
      }
    `
  )}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Small)`
  color: ${color('grey500')};
  grid-area: description;
`;

/**
 * `Radio` component.
 */

const Radio: FC<RadioProps> = forwardRef<any, RadioProps>(
  (props: RadioProps, ref: any): ReactElement => {
    const {
      alignment,
      checked,
      className,
      description,
      disabled,
      label,
      reverse,
      ...rest
    } = props;

    return (
      <Wrapper
        className={className}
        disabled={disabled}
      >
        <Label
          alignment={alignment}
          hasLabel={!label}
          reverse={reverse}
        >
          <Input
            checked={checked}
            disabled={disabled}
            ref={ref}
            type={'radio'}
            {...rest}
          />

          {label && <LabelText>{label}</LabelText>}

          <BulletWrapper>
            <Bullet />
          </BulletWrapper>

          {description && <Description>{description}</Description>}
        </Label>
      </Wrapper>
    );
  }
);

/**
 * `Radio` display name.
 */

Radio.displayName = 'Radio';

/**
 * Export `Radio` component.
 */

export default Radio;
