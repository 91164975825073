/**
 * Module dependencies.
 */

import { FavoritesContext } from './context';
import { ReactNode, useCallback } from 'react';
import { addFavorite } from 'src/api/my-products/favorites/add-favorite';
import { removeCustomization } from 'src/api/my-products/customizations/remove-customization';
import { removeFavorite } from 'src/api/my-products/favorites/remove-favorite';
import { useAuth } from 'src/context/auth/context';
import { useMutation } from 'react-query';
import { useSnackbar } from 'src/context/snackbar/context';
import useCustomizations from 'src/api/my-products/customizations/use-customizations';
import useFavorites from 'src/api/my-products/favorites/use-favorites';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `FavoritesProvider` provider.
 */

const FavoritesProvider = ({ children }: Props) => {
  const { showMessage } = useSnackbar();
  const { token } = useAuth();
  const { translate } = useTranslate();
  const {
    data: favorites,
    isLoading: isLoadingFavorites,
    isSuccess: isSuccessFavorites,
    refetch: refetchFavorites
  } = useFavorites({ token });

  const {
    data: customizations,
    isLoading: isLoadingCustomizations,
    isSuccess: isSuccessCustomizations,
    refetch: refetchCustomizations
  } = useCustomizations({ token });

  const { isLoading: isAddingFavorite, mutateAsync: addFavoriteMutation } =
    useMutation(addFavorite);

  const { isLoading: isRemovingFavorite, mutateAsync: removeFavoriteMutation } =
    useMutation(removeFavorite);

  const {
    isLoading: isRemovingCustomization,
    mutateAsync: removeCustomizationMutation
  } = useMutation(removeCustomization);

  const isLoading = isLoadingFavorites || isLoadingCustomizations;
  const isSuccess = isSuccessFavorites && isSuccessCustomizations;
  const isSubmitting =
    isAddingFavorite || isRemovingFavorite || isRemovingCustomization;

  const handleAddFavorite = useCallback(
    (id: string, hideMessages?: boolean) => {
      return addFavoriteMutation(
        { favorableId: id, token },
        {
          onError: () => {
            if (!hideMessages) {
              showMessage(translate('errors:defaultError'), {
                appearance: 'error'
              });
            }
          },
          onSuccess: () => {
            refetchFavorites();
            showMessage(
              translate('common:favorites.addFavorite.successMessage'),
              {
                appearance: 'success'
              }
            );
          }
        }
      );
    },
    [addFavoriteMutation, refetchFavorites, showMessage, token, translate]
  );

  const handleRemoveFavorite = useCallback(
    (id: string) => {
      return removeFavoriteMutation(
        { id, token },
        {
          onError: () => {
            showMessage(translate('errors:defaultError'), {
              appearance: 'error'
            });
          },
          onSuccess: () => {
            showMessage(
              translate('common:favorites.removeFavorite.successMessage'),
              {
                appearance: 'success'
              }
            );

            refetchFavorites();
          }
        }
      );
    },
    [refetchFavorites, removeFavoriteMutation, showMessage, token, translate]
  );

  const handleRemoveCustomization = useCallback(
    (id: string) => {
      return removeCustomizationMutation(
        { id, token },
        {
          onError: () => {
            showMessage(translate('errors:defaultError'), {
              appearance: 'error'
            });
          },
          onSuccess: () => {
            showMessage(
              translate(
                'common:customizations.removeCustomization.successMessage'
              ),
              {
                appearance: 'success'
              }
            );

            refetchCustomizations();
          }
        }
      );
    },
    [
      refetchCustomizations,
      removeCustomizationMutation,
      showMessage,
      token,
      translate
    ]
  );

  return (
    <FavoritesContext.Provider
      value={{
        customizations,
        favorites,
        isLoading,
        isSubmitting,
        isSuccess,
        onAddFavorite: handleAddFavorite,
        onRemoveCustomization: handleRemoveCustomization,
        onRemoveFavorite: handleRemoveFavorite
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

/**
 * Export `FavoritesProvider` provider.
 */

export default FavoritesProvider;
