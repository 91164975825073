/**
 * Module dependencies.
 */

import { useCallback } from 'react';
import { useRouter } from 'next/router';
import get from 'lodash/get';

/**
 * `Data` type.
 */

type Data = {
  [key: string]: any;
};

/**
 * `useSearchTranslations` hook.
 */

function useSearchTranslations() {
  const { locale } = useRouter();
  const getTranslations = useCallback(
    (data: Data) => {
      return get(data, locale);
    },
    [locale]
  );

  const getTranslation = useCallback(
    (data: Data, name: string) => {
      const key = `${name}_${locale}`;

      return get(data, key);
    },
    [locale]
  );

  return {
    getTranslation,
    getTranslations
  };
}

/**
 * Export `useSearchTranslations` hook.
 */

export default useSearchTranslations;
