/**
 * Module dependencies.
 */

/**
 * `SvgPause` component.
 */

const SvgPause: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M18 12a2 2 0 0 1 2 2v20a2 2 0 1 1-4 0V14a2 2 0 0 1 2-2Zm12 0a2 2 0 0 1 2 2v20a2 2 0 1 1-4 0V14a2 2 0 0 1 2-2Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgPause` component.
 */

export default SvgPause;
