/**
 * Module dependencies.
 */

/**
 * `SvgChevronRight` component.
 */

const SvgChevronRight: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M18.586 37.414a2 2 0 0 1 0-2.828L29.172 24 18.586 13.414a2 2 0 1 1 2.828-2.828l12 12a2 2 0 0 1 0 2.828l-12 12a2 2 0 0 1-2.828 0Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgChevronRight` component.
 */

export default SvgChevronRight;
