/**
 * Module dependencies.
 */

import {
  LocomotiveScrollProvider,
  useLocomotiveScroll
} from 'react-locomotive-scroll';

import {
  PropsWithChildren,
  ReactElement,
  forwardRef,
  useEffect,
  useRef
} from 'react';

import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';
import { useRouter } from 'next/router';
import CookiesBar from 'src/components/cookies-bar';
import Footer from 'src/components/footer';
import Navbar from 'src/components/navbar';
import PageTransition from 'src/components/page-transition';
import ScrollDownIndicator from 'src/components/navigation/scroll-down-indicator';
import SectionsNavigation from 'src/components/navigation/sections-navigation';
import SectionsNavigationProvider from 'src/context/sections-navigation/provider';
import locomotiveScrollStyles from 'locomotive-scroll/dist/locomotive-scroll.css';

/**
 * Export `Props` type.
 */

export type LayoutProps = {
  hasDoubleLogo?: boolean;
  hasFooter?: boolean;
  hasMobileScrollDownIndicator?: boolean;
  hasNavbar?: boolean;
  hasScrollDownIndicator?: boolean;
  hasSectionsNavigation?: boolean;
};

/**
 * `GlobalStyle` styled component.
 */

const GlobalStyle = createGlobalStyle`
  ${locomotiveScrollStyles}

  .has-scroll-init {
    [data-scroll='fade-in-up'] {
      animation-duration: 0.6s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.37, 0.16, 0.42, 0.8);
      opacity: 0;
      will-change: opacity, transform;

      &.is-inview {
        animation-name: ${theme('keyframes.fadeInUp')};
      }
    }

    [data-scroll='fade-in-up-small'] {
      animation-duration: 0.6s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.37, 0.16, 0.42, 0.8);
      opacity: 0;
      will-change: opacity, transform;

      &.is-inview {
        animation-name: ${theme('keyframes.fadeInUpSmall')};
      }
    }

    [data-scroll-animation-delay='150'] {
      animation-delay: 150ms;
    }

    [data-scroll-animation-delay='300'] {
      animation-delay: 300ms;
    }

    [data-scroll-animation-delay='450'] {
      animation-delay: 450ms;
    }
  }
`;

/**
 * `Main` component.
 */

const Main = forwardRef<any, any>(({ children }, ref): ReactElement => {
  const locomotiveScroll = useLocomotiveScroll();

  useEffect(() => {
    if (locomotiveScroll?.scroll) {
      locomotiveScroll.scroll.scrollTo('top', { duration: 0 });
    }
  }, [children, locomotiveScroll.scroll]);

  return (
    <main
      data-scroll-container
      ref={ref}
    >
      {children}
    </main>
  );
});

/**
 * `Main` display name.
 */

Main.displayName = 'Main';

/**
 * `Layout` component.
 */

const Layout = (props: PropsWithChildren<LayoutProps>): ReactElement => {
  const {
    children,
    hasDoubleLogo,
    hasFooter = true,
    hasMobileScrollDownIndicator = null,
    hasNavbar = true,
    hasScrollDownIndicator = false,
    hasSectionsNavigation = false
  } = props;

  const { asPath } = useRouter();
  const containerRef = useRef<HTMLElement>(null);

  return (
    <LocomotiveScrollProvider
      containerRef={containerRef}
      location={asPath}
      options={{
        reloadOnContextChange: true,
        smartphone: {
          smooth: false
        },
        smooth: true,
        tablet: {
          smooth: true
        }
      }}
      watch={[asPath]}
    >
      <SectionsNavigationProvider>
        <GlobalStyle />

        {hasNavbar && <Navbar hasDoubleLogo={hasDoubleLogo} />}

        <PageTransition>
          <Main ref={containerRef}>
            {children}

            {hasFooter && <Footer />}
          </Main>
        </PageTransition>

        {hasScrollDownIndicator && (
          <ScrollDownIndicator showInMobile={hasMobileScrollDownIndicator} />
        )}

        {hasSectionsNavigation && <SectionsNavigation />}

        <CookiesBar />
      </SectionsNavigationProvider>
    </LocomotiveScrollProvider>
  );
};

/**
 * Export `Layout` component.
 */

export default Layout;
