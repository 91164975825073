/**
 * Module dependencies.
 */

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { Type } from '../typography';
import { color, units } from '@untile/react-components';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `ToggleProps` interface.
 */

export interface ToggleProps {
  className?: string;
  onChange: Dispatch<SetStateAction<boolean>>;
  toggled: boolean;
  toggledLabel: string;
  unToggledLabel: string;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('backgrounds.sage02')};
  border-radius: ${units(5)};
  cursor: pointer;
  height: ${units(5)};
  padding: ${units(0.5)};
  position: relative;
  width: max-content;
`;

/**
 * `LabelsWrapper` styled component.
 */

const LabelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Paragraph)<{ colorTheme: 'dark' | 'light' }>`
  font-weight: 700;
  padding: ${units(0.5)} ${units(1)};
  text-align: center;
  transition: color 0.3s ease;

  ${switchProp('colorTheme', {
    dark: css`
      color: ${color('secondary')};
    `,
    light: css`
      color: ${color('white')};
    `
  })}
`;

/**
 * `ToggleBackground` styled component.
 */

const ToggleBackground = styled.div<{
  isToggled: boolean;
  width: number;
}>`
  background-color: ${color('grey800')};
  border-radius: ${units(5)};
  height: ${units(4)};
  left: ${units(0.5)};
  position: absolute;
  top: ${units(0.5)};
  transition: transform 0.3s ease;
  width: ${prop('width')}px;

  ${ifProp(
    'isToggled',
    css`
      transform: translateX(0);
    `,
    css`
      transform: translateX(${prop('width')}px);
    `
  )}
`;

/**
 * `Toggle` component.
 */

const Toggle = (props: ToggleProps): ReactElement => {
  const { className, onChange, toggled, toggledLabel, unToggledLabel } = props;
  const labelsRef = useRef<HTMLDivElement>();
  const [backgroundWidth, setBackgroundWidth] = useState<number>();
  const handleToggle = useCallback(() => {
    onChange(current => !current);
  }, [onChange]);

  useEffect(() => {
    if (labelsRef && labelsRef?.current) {
      setBackgroundWidth(labelsRef?.current.getBoundingClientRect().width / 2);
    }
  }, []);

  return (
    <Wrapper
      className={className}
      onClick={handleToggle}
    >
      <ToggleBackground
        isToggled={toggled}
        width={backgroundWidth}
      />

      <LabelsWrapper ref={labelsRef}>
        <Label colorTheme={toggled ? 'light' : 'dark'}>{toggledLabel}</Label>

        <Label colorTheme={toggled ? 'dark' : 'light'}>{unToggledLabel}</Label>
      </LabelsWrapper>
    </Wrapper>
  );
};

/**
 * Export `Toggle` component.
 */

export default Toggle;
