/**
 * Module dependencies.
 */

/**
 * `SvgChevronUp` component.
 */

const SvgChevronUp: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M22.586 14.586a2 2 0 0 1 2.828 0l12 12a2 2 0 1 1-2.828 2.828L24 18.828 13.414 29.414a2 2 0 1 1-2.828-2.828l12-12Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgChevronUp` component.
 */

export default SvgChevronUp;
