/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  favorableId: string;
  token: string;
};

/**
 * Export `addFavorite`.
 */

export async function addFavorite({ favorableId, token }: Query) {
  const endpoint = getApiEndpoint('favorites');

  try {
    const { data } = await request.post(
      endpoint,
      { favorableId },
      {
        headers: authorizationHeader(token)
      }
    );

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
