/**
 * Module dependencies.
 */

import { Fill, color, media, units } from '@untile/react-components';
import { ReactElement, useEffect, useState } from 'react';
import { ifProp, theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Container from 'src/components/core/layout/container';
import FooterMenu from 'src/components/navbar/menu/footer-menu';
import LanguageSwitcher from './language-switcher';
import Menu from 'src/components/navbar/menu';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  isOpen: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{
  closeImmediately: boolean;
  visible: boolean;
}>`
  min-height: 100vh;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  transition: visibility 0s 0.85s;

  ${ifProp(
    'visible',
    css`
      transition-delay: 0s;
      visibility: visible;
    `,
    css`
      pointer-events: none;
      visibility: hidden;
    `
  )}

  ${ifProp(
    'closeImmediately',
    css`
      opacity: 0;
      pointer-events: none;
    `
  )}
`;

/**
 * `Background` styled component.
 */

const Background = styled(Fill)<{ visible: boolean }>`
  background-color: ${color('backgrounds.sage01')};
  transform: scaleY(0);
  transform-origin: center top;
  transition: transform ${theme('animations.easeOutQuadTransition')};

  &::before {
    background-color: ${color('backgrounds.sage02')};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleY(0);
    transform-origin: center top;
    transition: transform ${theme('animations.easeOutQuadTransition')};
  }

  ${ifProp(
    'visible',
    css`
      transform: scaleY(1);
      transition-delay: 0.15s;

      &::before {
        transform: scaleY(1);
        transition-delay: 0.25s;
      }
    `
  )}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container).attrs({ fluid: true })`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0 calc((100% - 1440px) / 2);
  position: relative;
  scroll-snap-points-y: repeat(${units(10)});
  scroll-snap-type: y mandatory;
  z-index: ${theme('zIndex.sidebar')};

  ${media.min('md')`
    padding-bottom: ${units(8)};
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    '. .      .'
    '. menu   .'
    '. footer .';
  grid-template-columns: ${theme('grid.gutterMobile')}px 1fr ${theme(
      'grid.gutterMobile'
    )}px;
  grid-template-rows:
    calc(${theme('dimensions.navbarHeightMobile')}px + ${units(4)})
    1fr max-content;
  margin-bottom: ${units(10)};

  ${media.min('md')`
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-rows: calc(${theme('dimensions.navbarHeight')}px + ${units(
    8
  )}) 1fr max-content;
    margin-bottom: 0;
  `}

  ${media.min('lg')`
    grid-template-columns: 2fr 8fr 2fr;
  `}
`;

/**
 * `MenuWrapper` styled component.
 */

const MenuWrapper = styled.div`
  display: grid;
  grid-area: menu;
  margin-bottom: ${units(6)};

  ${media.min('md')`
    margin-bottom: ${units(8.5)};
  `}
`;

/**
 * `FooterWrapper` styled component.
 */

const FooterWrapper = styled.div<{ visible?: boolean }>`
  align-items: start;
  display: flex;
  flex-direction: column;
  grid-area: footer;
  opacity: ${ifProp('visible', 1, 0)};
  pointer-events: ${ifProp('visible', 'auto', 'none')};
  transform: translateY(${ifProp('visible', '0', '-100%')});
  transition: ${theme('animations.fastTransition')};
  transition-property: opacity, transform;

  ${ifProp(
    'visible',
    css`
      transition-delay: 0.8s;
    `
  )}

  ${media.min('md')`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

/**
 * `StyledLanguageSwitcher` styled component.
 */

const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  ${media.max('md')`
    margin-bottom: ${units(2)};
  `}
`;

/**
 * `Sidebar` component.
 */

const Sidebar = ({ isOpen }: Props): ReactElement => {
  const router = useRouter();
  const [closeImmediately, setCloseImmediately] = useState<boolean>(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setTimeout(() => {
        setCloseImmediately(true);
      }, 400);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (isOpen) {
      setCloseImmediately(false);
    }
  }, [isOpen]);

  return (
    <Wrapper
      closeImmediately={closeImmediately}
      visible={isOpen}
    >
      <Background visible={isOpen} />

      <StyledContainer>
        <Grid>
          <MenuWrapper>
            <Menu visible={isOpen} />
          </MenuWrapper>

          <FooterWrapper visible={isOpen}>
            <StyledLanguageSwitcher />

            <FooterMenu />
          </FooterWrapper>
        </Grid>
      </StyledContainer>
    </Wrapper>
  );
};

/**
 * Export `Sidebar` component.
 */

export default Sidebar;
