/**
 * Module dependencies.
 */

import {
  Token,
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  params: {
    limit: number;
    page: number;
  };
  token: Token;
};

/**
 * Export `paginationLimit` constant.
 */

export const paginationLimit = 10;

/**
 * Export `fetchCustomizations`.
 */

export async function fetchCustomizations(query: Query) {
  const { params, token } = query;
  const endpoint = getApiEndpoint('customizations');

  if (!params.page) {
    return;
  }

  try {
    const currentPage = params?.page ?? 1;
    const { data } = await request.get(endpoint, {
      headers: authorizationHeader(token),
      params
    });

    return {
      data: data?.data,
      nextPage: +currentPage + 1,
      total: data?.total
    };
  } catch (error) {
    handleRequestError(error);
  }
}
