/**
 * Module dependencies.
 */

import { DropdownListItem, HelpText, Input, icons } from 'src/components/core';
import { Fragment, ReactElement, useCallback, useMemo, useState } from 'react';
import { Index } from 'src/types/search';
import { color, media, units } from '@untile/react-components';
import { connectAutoComplete, connectHighlight } from 'react-instantsearch-dom';
import Autosuggest from 'react-autosuggest';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Hits` type.
 */

type Hits = {
  hits: Array<{
    [key: string]: any;
  }>;
  index: Index;
};

/**
 * `Props` type.
 */

type Props = {
  currentRefinement: string;
  hits: Hits[];
  onClose: () => void;
  onSuggestionClear: () => void;
  onSuggestionSelected: (value: string) => void;
  refine: (value?: string) => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(5)};
  position: relative;

  ${media.min('md')`
    margin-bottom: ${units(6)};
  `}
`;

/**
 * `StyledInput` styled component.
 */

const StyledInput = styled(Input)`
  margin-bottom: ${units(0.5)};
`;

/**
 * `StyledDropdownItem` styled component.
 */

const StyledDropdownItem = styled(DropdownListItem)<{ position: number }>`
  padding: ${units(1)} ${units(4)};

  span {
    font-weight: 700;
  }
`;

/**
 * `Dropdown` styled component.
 */

const Dropdown = styled.div`
  background-color: ${color('white')};
  box-shadow: 0 10px 24px ${color.transparentize('black', 0.1)};
  left: 0;
  max-height: ${units(39.5)};
  overflow: auto;
  position: absolute;
  right: 0;
  top: ${units(5)};
  z-index: 10;

  ul {
    li {
      &:first-child {
        ${StyledDropdownItem} {
          padding-top: ${units(2.5)};
        }
      }

      &:last-child {
        ${StyledDropdownItem} {
          padding-bottom: ${units(2.5)};
        }
      }
    }
  }
`;

/**
 * `SuggestionsContainer` component.
 */

const SuggestionsContainer = ({ children, containerProps }): ReactElement => (
  <Dropdown {...containerProps}>{children}</Dropdown>
);

/**
 * `Highlight` component.
 */

const Highlight = connectHighlight(
  ({ attribute, highlight, hit }): ReactElement | null => {
    const parsedHit = highlight({
      attribute,
      highlightProperty: '_highlightResult',
      hit
    });

    if (isEmpty(parsedHit)) {
      return null;
    }

    return (
      <StyledDropdownItem>
        {parsedHit.map((part, index) => (
          <Fragment key={index}>
            {part.isHighlighted ? part.value : <span>{part.value}</span>}
          </Fragment>
        ))}
      </StyledDropdownItem>
    );
  }
);

/**
 * `Suggestions` component.
 */

const Suggestions = ({ locale, suggestion }): ReactElement => {
  const normalizedSuggestion = useMemo(() => {
    if (suggestion?.title) {
      return suggestion;
    }

    return {
      ...suggestion,
      title: suggestion[locale]?.title
    };
  }, [locale, suggestion]);

  return (<Highlight
    attribute={'title'}
    hit={normalizedSuggestion}
  />);
};

/**
 * `SearchForm` component.
 */

const SearchForm = (props: Props): ReactElement => {
  const {
    currentRefinement,
    hits,
    onSuggestionClear,
    onSuggestionSelected,
    refine
  } = props;

  const { locale, translate } = useTranslate();
  const [value, setValue] = useState(currentRefinement);
  const handleSuggestionsClear = useCallback(() => {
    refine();
  }, [refine]);

  const handleSuggestionsFetch = useCallback(
    ({ value }) => {
      refine(value);
    },
    [refine]
  );

  const handleChange = useCallback(
    (event, { newValue }) => {
      if (!newValue) {
        onSuggestionClear();
      }

      setValue(newValue);
      onSuggestionSelected(newValue);
    },
    [onSuggestionClear, onSuggestionSelected]
  );

  return (
    <Wrapper>
      <Autosuggest
        getSectionSuggestions={section => section.hits}
        getSuggestionValue={hit => hit.title}
        inputProps={{
          onChange: handleChange,
          placeholder: translate('forms:placeholders.typeSomething'),
          value
        }}
        multiSection
        onSuggestionsClearRequested={handleSuggestionsClear}
        onSuggestionsFetchRequested={handleSuggestionsFetch}
        renderInputComponent={inputProps => (
          <>
            <StyledInput
              icon={icons.search}
              {...inputProps}
            />

            {isEmpty(value) && (
              <HelpText>{translate('common:search.form.helpText')}</HelpText>
            )}
          </>
        )}
        renderSectionTitle={() => null}
        renderSuggestion={suggestion => (
          <Suggestions
            locale={locale}
            suggestion={suggestion}
          />
        )}
        renderSuggestionsContainer={SuggestionsContainer}
        suggestions={hits}
      />
    </Wrapper>
  );
};

/**
 * Export `SearchForm` component.
 */

export default connectAutoComplete(SearchForm);
