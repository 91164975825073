/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  formData: {
    email: string;
    recaptchaToken: string;
  };
  locale: string;
};

/**
 * Export `subscribeNewsletter`.
 */

export async function subscribeNewsletter({ formData, locale }: Query) {
  const endpoint = getApiEndpoint('newsletter');

  try {
    const { data } = await request.post(endpoint, formData, {
      headers: acceptLanguageHeader(locale)
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
