/**
 * Module dependencies.
 */

import { RawHtml, color, units } from '@untile/react-components';
import { ReactElement, ReactNode } from 'react';
import { theme } from 'styled-tools';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  ${theme('typography.styles.p')}

  color: ${color('grey800')};

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color('textColor')};
    margin: 0 0 ${units(3)};
  }

  h2 {
    ${theme('typography.styles.h2')}
  }

  h3 {
    ${theme('typography.styles.h3')}

    padding-top: ${units(5)};
  }

  h4 {
    ${theme('typography.styles.h4')}
  }

  h5 {
    ${theme('typography.styles.h5')}
  }

  h6 {
    ${theme('typography.styles.h6')}
  }

  p {
    margin: 0 0 ${units(3)};
  }

  ul {
    margin: 0 0 ${units(3)};
    padding-top: ${units(2)};

    li {
      list-style-type: initial;
      margin-bottom: 0;
      margin-left: ${units(2)};

      &::marker {
        color: ${color('grey100')};
      }
    }
  }

  p + ul {
    margin-top: -${units(1)};
    padding-top: 0;
  }

  a {
    color: inherit;
    transition: opacity ${theme('animations.defaultTransition')};

    &:focus,
    &:hover {
      opacity: 0.8;
    }
  }

  blockquote {
    ${theme('typography.otherStyles.subtitle2')}

    margin: 0 0 ${units(1)};
    padding-top: ${units(5)};
  }

  blockquote + p {
    ${theme('typography.styles.small')}

    color: ${color('grey400')};
    margin: 0 0 ${units(5)};
  }
`;

/**
 * `Richtext` component.
 */

const Richtext = ({ children }: Props): ReactElement => (
  <RawHtml element={Wrapper}>{children}</RawHtml>
);

/**
 * Export `Richtext` component.
 */

export default Richtext;
