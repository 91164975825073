/**
 * Module dependencies.
 */

import {
  QueryObserverBaseResult,
  QueryObserverOptions,
  useQuery
} from 'react-query';

import { User } from 'src/types/user';
import { fetchUser } from './fetch-user';

/**
 * `Result` type.
 */

type Result = QueryObserverBaseResult<User, unknown>;

/**
 * `Options` type.
 */

type Options = QueryObserverOptions<User, unknown, User, unknown>;

/**
 * `useUser` hook.
 */

function useUser(token: string, options?: Options): Result {
  const result = useQuery(
    ['auth-me', { token }],
    () => {
      return fetchUser({ token });
    },
    options
  );

  return result;
}

/**
 * Export `useUser` hook.
 */

export default useUser;
