/**
 * Module dependencies.
 */

import {
  authorizationHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  id: string;
  token: string;
};

/**
 * Export `removeCustomization`.
 */

export async function removeCustomization({ id, token }: Query) {
  const endpoint = getApiEndpoint('customization', { id });

  try {
    const { data } = await request.delete(endpoint, {
      headers: authorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    throw handleRequestError(error);
  }
}
