/**
 * Module dependencies.
 */

import algoliasearch from 'algoliasearch';

/**
 * Export `client` instance.
 */

export const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
);

/**
 * Export `indexes`.
 */

export const indexes = {
  downloads: 'downloads',
  modelRanges: 'model_ranges',
  news: 'news'
} as const;

/**
 * Export `orderedIndexes`.
 */

export const orderedIndexes = [
  indexes.modelRanges,
  indexes.news,
  indexes.downloads
];

/**
 * Export `hitsPerPage`.
 */

export const hitsPerPage = 2;
