/**
 * Module dependencies.
 */

import { ReactElement } from 'react';
import { color, media, units } from '@untile/react-components';
import { icons } from '../icons';
import { ifProp, theme } from 'styled-tools';
import RouterLink from '../links/router-link';
import Svg from '../svg';
import map from 'lodash/map';
import styled, { css } from 'styled-components';

/**
 * Export `BreadcrumbsProps` interface .
 */

export interface BreadcrumbsProps {
  className?: string;
  items: Array<{
    href?: string | null;
    label: string;
  }>;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Link` styled component.
 */

const Link = styled.a.attrs(({ href }) => ({
  as: (href && RouterLink) || 'a'
}))`
  ${theme('typography.styles.small')}

  color: ${color('grey400')};
  cursor: pointer;
  margin: 0;
  text-transform: unset;

  ${ifProp(
    'href',
    css`
      transition: color ${theme('animations.defaultTransition')};

      &:focus,
      &:hover {
        color: ${color('grey800')};
      }
    `,
    css`
      cursor: default;
    `
  )}

  &,
  &:active,
  &:focus,
  &:hover,
  &:visited {
    outline: none;
    text-decoration: none;
  }

  &:last-child {
    color: ${color('grey800')};
  }

  ${media.min('ms')`
    ${theme('typography.styles.p')}
  `}
`;

/**
 * `Arrow` styled component.
 */

const Arrow = styled(Svg).attrs({
  icon: icons.chevronLeft,
  size: units(1.5)
})`
  color: ${color('grey400')};
  margin: 0 ${units(0.5)};

  ${media.min('ms')`
    margin: 0 ${units(1)};
  `}
`;

/**
 * `Breadcrumbs` component.
 */

const Breadcrumbs = (props: BreadcrumbsProps): ReactElement => {
  const { className, items } = props;

  return (
    <Wrapper className={className}>
      {map(items, ({ href, label }, index) => (
        <>
          {index > 0 && <Arrow />}

          <Link
            {...(href && { href })}
            key={index}
          >
            {label}
          </Link>
        </>
      ))}
    </Wrapper>
  );
};

/**
 * `Breadcrumbs` display name.
 */

Breadcrumbs.displayName = 'Breadcrumbs';

/**
 * Export `Breadcrumbs` component.
 */

export default Breadcrumbs;
