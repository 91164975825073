/**
 * Module dependencies.
 */

/**
 * `SvgCheck` component.
 */

const SvgCheck: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      fill={'none'}
      viewBox={'0 0 48 48'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <path
        clipRule={'evenodd'}
        d={
          'M41.329 10.505a2 2 0 0 1 .166 2.824l-21.333 24a2 2 0 0 1-2.99 0l-10.667-12a2 2 0 0 1 2.99-2.658l9.172 10.319L38.505 10.67a2 2 0 0 1 2.824-.166Z'
        }
        fill={'currentColor'}
        fillRule={'evenodd'}
      />
    </svg>
  );
};

/**
 * Export `SvgCheck` component.
 */

export default SvgCheck;
